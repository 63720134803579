import { useEffect, useState,useContext } from "react";
import { Button, Modal, Row, Form, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import Select from 'react-select';
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import bimg from "../../src/imgs/default.jpg";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import CropModal from "../components/Shared/CropModal";
import { setCookies } from '../utils/Helper';
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

const PujaFromPanditJi =  () =>{
  document.title = 'PujaFromPanditJi';
  
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
    const  {refreshToken} = useContext(UserContext);
    const navigate = useNavigate();
    const headers = { Authorization: `Bearer ${token}` };
    let astroUserId = cookies.get("astroUserId");
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(6);
    const [pageCount, setPageCount] = useState(0);
    const [len, setLen] = useState();
    const [loading, setLoading] = useState();
    const [ title ,setTitle] = useState("")
    const [ experience ,setExperience] = useState("")
    const [ languages , setLanguages] = useState([])
    const [ distance , setDistance] = useState("")
    const [imageType, setImageType] = useState("hindi");
    const [allData , setAllData] = useState([])
    const [ contentType , setContentType] =  useState("")
    const [ file , setFile] = useState ("");
    const [ image , setImage] = useState("");
    const [ imgShow , setImgShow] = useState("");
    const [show, setShow] = useState(false);
    const [showup, setShowUp] = useState(false);
    const [showdt, setShowDt] = useState(false);
    const [data, setData] = useState();
    const [errors, setErrors] = useState({});
    const [selectedId, setSelectedId] = useState()
    const[loader,setLoader]=useState(false)
    const [allDataCount , setAllDataCount] = useState()

    const [showcrop, setShowCrop] = useState(false);
    const [cropimg, setCropImg] = useState("");
    const [caartifileimage,setFileCaartiimage]=useState()
  
    const languageListEn = [
  { value: "english", label: "English" },
  { value: "hindi", label: "Hindi" },
  { value: "panjabi", label: "Panjabi" },
  { value: "marathi", label: "Marathi" }
];


const languageListHi = [
  { value: "अंग्रेज़ी", label: "अंग्रेज़ी" },
  { value: "हिंदी", label: "हिंदी" },
  { value: "पंजाबी", label: "पंजाबी" },
  { value: "मराठी", label: "मराठी" }
];

const logout =() =>{
  refreshToken(null)
  setCookies("accessToken", null);
  navigate("/");
}

 function handleSelect(data) {
    setLanguages(data);
  }
    const onImageChange = (event) => {
      setImage(event);
       setFile(event);
    
      };
    
      const handleClose = () =>{ 
        setShow(false)
        setErrors("")
        setContentType("")
        setLanguages([])
      }
      const handleCloseDt = () => setShowDt(false);
      
      const handleShowDt = (id) => {
        setSelectedId(id);
        setShowDt(true);
      };

       const handleShow = async (item) => {
        setShow(true);
        setImage("")
        setFile("")
        setSelectedId(item?._id);
        setTitle(item?.title)
        setLanguages(item?.languages?.map(lg =>
           { return { value: lg, label:lg.charAt(0).toUpperCase()+lg.slice(1) } })??[])
        setExperience(item?.experience)
        setDistance(item?.distance)
        setContentType(item?.contentEn === true ?"english" : item?.contentHi === true ? "hindi" :"")
        setImgShow(item?.file);
      };
    
      
      const fetchData = async () => {
         setLoading(true);
       
      let response = await APIServices.get(`pujaFromPanditJi/getAll/${imageType}/${offset}/${perPage}`, headers);
    let data = response?.response?.data
     
      if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            setData(apiData?.Listdata);
         
            setAllDataCount(apiData.totalCount)
            setLoading(false);
          }
        }else{
          if(data?.status == 4){
            logout()
           }
        }
    
      };

      async function updateFile(showToast) {
        let res = await APIServices.get(`pujaFromPanditJi/updateFile`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          if (apiData?.status) {
            if (showToast) {
              toast.success(apiData?.message);
              
            }
            fetchData();
          }
        }
      }
      const handlePageClick = (e) => {
        const selectedPage = e.selected;
        console.log(selectedPage * perPage);
        const newOffset = (selectedPage * perPage);
        setOffset(newOffset);
      };
      const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
      
          if (!title ||title === "") 
          newErrors.title = "Please enter title.";

          if (file === "" && (!imgShow || imgShow === "")) 
          newErrors.file = "Please add image.";

          if (!contentType ||contentType === "") 
          newErrors.contentType = "Please enter content type."; 
    
          if (!languages ||languages.length === 0) 
          newErrors.languages = "Please select languages.";
    
          if (!experience ||experience === "") 
          newErrors.experience = "Please enter experience.";
    
          if (!distance ||distance === "") 
          newErrors.distance = "Please enter distance.";
    
          return newErrors;
      }; 


       async function handleEdit() {
        const formData = new FormData();
        if (file) {
          formData.append("file", file);
        }
         formData.append("title" , title)
         const lang = []
         languages?.filter((it)=> lang.push(it.label))
        formData.append("languages" , lang)
         formData.append("experience" , experience)
         formData.append("distance", distance)
        if (contentType === "english") {
          formData.append("contentEn", true);
          formData.append("contentHi", false);
    
        } else if (contentType === "hindi") {
          formData.append("contentHi", true);
          formData.append("contentEn", false);
        }
        formData.append("userId", astroUserId);
    
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setLoader(false)
        }else {
          let response = await APIServices.put(
            `pujaFromPanditJi/editOne/${selectedId}`,
            formData,
            headers
            );
            if (response?.status === 200) {
              let apiData = response?.data;
              if (apiData?.status === true) {
                setShow(false);
                toast.success(apiData?.message);
                setCropImg();
                updateFile();
                setLoader(false)
                setFile("");
            }else{
              toast.error(apiData?.message)
              setLoader(false)
            }
          }
          setLoader(false)
        }
      }
      
      async function handleCreate() {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title" , title)
        const lang = []
        languages?.filter((it)=> lang.push(it.label))
        formData.append("languages" , lang)
        formData.append("experience" , experience)
        formData.append("distance", distance)
        if (contentType === "english") {
          formData.append("contentEn", true);
    
        } else if (contentType === "hindi") {
          formData.append("contentHi", true);
        }
        formData.append("userId", astroUserId);
    
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };

        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setLoader(false)
        }else {
          let response = await APIServices.post(
            `pujaFromPanditJi/upload`,
            formData,
            headers
            );
            if (response?.status === 200) {
              let apiData = response?.data;
              if (apiData?.status === true) {
              setShow(false);
              toast.success(apiData?.message);
              setCropImg();
              updateFile();
              setLoader(false)
            }else{
              toast.error(apiData?.message)
              setLoader(false)
            }
          }
          setLoader(false)
        }
      };
      
      const handledelete = async () => {
        let res = await APIServices.delete(`pujaFromPanditJi/findByIdAndremove/${selectedId}`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          setShowDt(false);
          if (apiData?.status) {
            toast.success(apiData?.message);
            setOffset(0)
            updateFile();
          }
        }
      };
      function handleChange(e) {
        let key, value;
        key = e?.target?.name;
        if (!!errors[key]){
          setErrors({
            ...errors,
            [key]: null,
            });
        }
        if (e === "languages") {
          if (!!errors[e]){
          setErrors({
            ...errors,
            [e]: null,
            });
        }}
      }

      useEffect(() => {
        fetchData();
      }, [offset ,imageType]);

      const renderFormModal = () => {
        return(
          <Modal show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg()}}>
          <Modal.Header closeButton>
          <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Detail`}</Modal.Title>

          </Modal.Header>
          <Modal.Body>
            <Form>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Title
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Title..."
                    name='title'
                    defaultValue={title}
                    isInvalid={!!errors?.title}
                    className="form-control "
                    onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
                  />
                   <Form.Control.Feedback type="invalid">
                {errors?.title}
                </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Image
                </Form.Label>
                <Col sm="9">
                <label id="labimg">
              
              {cropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={cropimg}
              onClick={()=>setCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={image ? image:imgShow?imgShow : bimg}
              width="100%" alt="" />}

               <Form.Control
                // type="file"
                className="form-control "
                isInvalid={!!errors?.file}
                name="file"
                onClick={(e)=>{setShowCrop(true);handleChange(e)}}
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
               <Form.Control.Feedback type="invalid">
               {errors?.file}
               </Form.Control.Feedback>
                </label>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Content Type
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    aria-label="Default select example"
                    required
                    className="hd-cr"
                    name ="contentType"
                    defaultValue={contentType}
                    isInvalid={!!errors?.contentType}
                    onChange={(e) =>{ setContentType(e.target.value);handleChange(e)}}

                  >
                    <option value="">Choose</option>
                    <option value="english">English</option>
                    <option value="hindi">Hindi</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                {errors?.contentType}
              </Form.Control.Feedback>
                </Col>
              </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
           <Form.Label className="required" column sm="3">Language</Form.Label>
                  <Col sm="9">
                  < Select
                    className="hd-cr"
                    isMulti
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    placeholder="Language..."
                    value={languages}
                    onChange={ (e) => {handleSelect(e); handleChange("languages")}}
                    name="lang"
                    options={contentType ==="english"?languageListEn : languageListHi}
                  />
                   <span  className="errorVal">{errors?.languages}</span>
              </Col>
           </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Experience
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Experience..."
                    defaultValue={experience}
                    isInvalid={!!errors?.experience}
                    name="experience"
                    className="form-control "
                    onChange={(e) => {setExperience(e.target.value);handleChange(e)}}
                  />
                   <Form.Control.Feedback type="invalid">
                {errors?.experience}
              </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Distance
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Distance..."
                    defaultValue={distance}
                    isInvalid={!!errors?.distance}
                    name="distance"
                    className="form-control "
                    onChange={(e) => {setDistance(e.target.value);handleChange(e)}}
                  />
                     <Form.Control.Feedback type="invalid">
                {errors?.distance}
              </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          {loader? <Button
            variant="primary"
            className="as_btn"
            
          >
          {`${selectedId ? 'Processing...' : 'Processing...'}`}
          </Button>: <Button
          variant="primary"
          className="as_btn"
          onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}
        >
          {`${selectedId ? 'Edit' : 'Add'}`}
        </Button>}
            <Button variant="secondary" className="as_btn" onClick={()=>{handleClose();setCropImg()}}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        )
      }

     useEffect(()=>{
       if(caartifileimage){
         onImageChange(caartifileimage)
       }
     },[cropimg,caartifileimage])
    return(
<>
        <div className="main aarti_section">
        <div className="gita_quote_heading">
          <div className="container d-flex align-items-center justify-content-between">
            <h4 className="hd-list-f left-q">Puja From Pandit Ji <small>({allDataCount})</small></h4>
            <div className="_btn">
              <Button
                variant="primary"
                className="by-btn as_btn"
                onClick={()=>updateFile(true)}
              >
                Update File
              </Button>
            
              <Button
                variant="primary"
                onClick={handleShow}
                className="by-btn as_btn me-0"
              >
                Add detail 
              </Button>
            </div>
          </div>
        </div>
        <div className="aarti_inner pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <Sidebar />
              </div>
              <div className="col-md-8 col-lg-9">
                {loading ? (
                  <div className="center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                             value={imageType}
                             onChange={(e) => {setImageType(e.target.value); setOffset(0)}}
                          >
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row mt-3">
                        {data?.map((item)=>
                         <div className="col-md-6 col-lg-4">
                    <div className="card mb-4" key={item?._id}>
                      <div class="card-header">
                        <img
                          
                          src={(item?.file) ?`${item?.file}` : bimg}
                          className="card-img-top"
                          alt="img"
                        />
                        <div className="icon">
                           <FaEdit
                               className="me-2 hd-cr"
                               size={20}
                               onClick={(e) => {
                                 handleShow(item);
                               }}
                             />
                          <FaTrash
                            className="dt-btn hd-cr"
                            size={20}
                            onClick={(e) => {
                              handleShowDt(item?._id);
                            }}
                          />
                        </div>
                      </div>
                      <div className="card-body " style={{minHeight:"90px"}}>
                            <h1 className="card-title heading_18 mb-2">{item.title}
                            </h1>
                            <dev className="card-text description">
                                <p style={{color:"orange"}}>{item.experience}</p>
                                <p className="">{item?.languages?.join(",")}</p>
    
                            </dev>
                          </div>
                    </div>
                  </div>)}
                
                  </div> 
                  </>
                )}
               <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderFormModal()}
    
      <Modal
      centered
      scrollable
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="success_btn"
            onClick={handledelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
      </>
    )
}


export default PujaFromPanditJi ;