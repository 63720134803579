import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Row, Col, Tab, Nav, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import defaultImage from '../../imgs/default.jpg';
import { FaEdit, FaTrash, FaMinus, FaPlus } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { PujaImgGalleyModel } from '../../components/Shared/pujaImgGalleryModal';
import Cookies from 'universal-cookie';
import upimg from '../../imgs/default.jpg';
import APIServicenew from '../../utils/APIGeneralService';
import { ToastContainer, toast } from 'react-toastify';


const TempleAddEdit = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [inputFields, setInputFields] = useState({
    title: '',
    slug: '',
    description: '',
    state: '',
    city: '',
    address: '',
    sortOrder: '',
    // isFeatured: '',
    mobileAppimage: '',
    webListImage: '',
    webDetailImage: '',
    bannerImage: '',
  });

  const [inputFieldsHi, setInputFieldsHi] = useState({
    titleHi: '',
    // slugHi: '',
    descriptionHi: '',
    stateHi: '',
    cityHi: '',
    addressHi: '',
    // isFeaturedHi: '',
    mobileAppimageHi: '',
    webListImageHi: '',
    webDetailImageHi: '',
    bannerImageHi: '',
  });

  const [selectedId, setSelectedId] = useState('');

  const initialInputFieldSecState = [{ samagri: '', price: '', globalPrice: '',}];

  const handleReset = () => {
    setInputFieldSec(initialInputFieldSecState);
    setInputFields({
      title: '',
      slug: '',
      description: '',
      state: '',
      city: '',
      address: '',
      sortOrder: '',
      // isFeatured: '',
      mobileAppimage: '',
      webListImage: '',
      webDetailImage: '',
      bannerImage: '',
    });
    setInputFieldsHi({
      titleHi: '',
      // slugHi: '',
      descriptionHi: '',
      stateHi: '',
      cityHi: '',
      addressHi: '',
      sortOrderHi: '',
      // isFeaturedHi: '',
      mobileAppimageHi: '',
      webListImageHi: '',
      webDetailImageHi: '',
      bannerImageHi: '',
    });
    setErrors({});
    setErrorsHi({});
  };

  const [errors, setErrors] = useState({});
  const [errorsHi, setErrorsHi] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;

    setInputFields(prev => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleChangeHi = e => {
    const { name, value } = e.target;

    setInputFieldsHi(prev => ({ ...prev, [name]: value }));

    if (!!errorsHi[name]) {
      setErrorsHi(prev => ({ ...prev, [name]: null }));
    }
  };

  // Image gallery
  const [modalFieldKey, setModalFieldKey] = useState(null);
  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [clearSel, setClearSel] = useState(false);
  const [rindex, setRindex] = useState();

  const [appImageEn, setAppImageEn] = useState('');
  const [webListImageEn, setWebListImageEn] = useState('');
  const [webDetailImageEn, setWebDetailImageEn] = useState('');
  const [bannerImageEn, setBannerImageEn] = useState('');
  const [appImageHi, setAppImageHi] = useState('');
  const [webListImageHi, setWebListImageHi] = useState('');
  const [webDetailImageHi, setWebDetailImageHi] = useState('');
  const [bannerImageHi, setBannerImageHi] = useState('');
  const [ loading, setLoading ] = useState(false)

  const handleOpenModal = (fieldKey, index) => {
    setClearSel(true);
    setModalFieldKey(fieldKey);
    setRindex(index);
    setShowImageGalleyModel(true);
  };

  const extractPaths = url => {
    const extractPath = singleUrl => {
      const parts = singleUrl.split('cloudfront.net/');
      if (parts.length > 1) {
        return parts[1];
      }
      return singleUrl;
    };

    if (Array.isArray(url)) {
      return url.map(singleUrl => extractPath(singleUrl));
    } else if (typeof url === 'string') {
      return extractPath(url);
    }
    return url;
  };

  const handleSetImageUrl = (urls, fieldKey) => {
    let extractedPath = extractPaths(urls);
    setClearSel(false);
    if (fieldKey === 'appImageEn') {
      setInputFields(prev => ({ ...prev, mobileAppimage: urls }));
      setAppImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'webListImageEn') {
      setInputFields(prev => ({ ...prev, webListImage: urls }));
      setWebListImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'webDetailImageEn') {
      setInputFields(prev => ({ ...prev, webDetailImage: urls }));
      setWebDetailImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'bannerImageEn') {
      setInputFields(prev => ({ ...prev, bannerImage: urls }));
      setBannerImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    //hindi
    if (fieldKey === 'appImageHi') {
      setInputFieldsHi(prev => ({ ...prev, mobileAppimageHi: urls }));
      setAppImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }

    if (fieldKey === 'webListImageHi') {
      setInputFieldsHi(prev => ({ ...prev, webListImageHi: urls }));
      setWebListImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'webDetailImageHi') {
      setInputFieldsHi(prev => ({ ...prev, webDetailImageHi: urls }));
      setWebDetailImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'bannerImageHi') {
      setInputFieldsHi(prev => ({ ...prev, bannerImageHi: urls }));
      setBannerImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }
  };

  // inputFieldSec

  const [inputFieldSec, setInputFieldSec] = useState([{ samagri: '', price: '', globalPrice: ''}]);

  const addInputField = () => {
    setInputFieldSec([...inputFieldSec, { samagri: '', price: '', globalPrice: ''}]);
  };

  const removeInputField = index => {
    const values = [...inputFieldSec];
    values.splice(index, 1);
    setInputFieldSec(values);
  };

    const handleChangeSec = (index, evnt) => {
    const { name, value } = evnt.target;

    let key
    key = evnt?.target?.name;
    if (!!errors?.ChadhawaSelection) {
      errors?.ChadhawaSelection?.forEach((field, index) => {
          if (errors.ChadhawaSelection[index][key]!=null || errors.ChadhawaSelection[index][key]!="") {
            errors.ChadhawaSelection[index][key] = null
          }
      })
    }
    const list = [...inputFieldSec];
    list[index][name] = value;
    setInputFieldSec(list);
  };

  const validateInputs = () => {
    const newErrors = {};
    let isLocalCantentValid=false;

    const inputFieldsErrorsSec = [];
    inputFieldSec?.forEach((field, index) => {
      let errorMsgs =  {
       samagri:'',
       price:'',
       globalPrice:''
      };

      Object.keys(field).forEach((key) => {
        if (field[key] === "" || field[key] === 'undefined') {
        isLocalCantentValid=true;
        let errMsg = `Please add  ${key === "samagri" ? "samagri" : key === "price"? "price":key === "globalPrice"? "globalPrice" : key}.`;
          errorMsgs[key]=(errMsg);
        }
      });
      inputFieldsErrorsSec.push(errorMsgs);
    });

    if (isLocalCantentValid && inputFieldsErrorsSec.length > 0) 
    newErrors.ChadhawaSelection= inputFieldsErrorsSec
  

    if (!inputFields.title || inputFields.title.trim() === '') {
      newErrors.title = 'Please enter Title.';
    }

    if (!inputFields.slug || inputFields.slug.trim() === '') {
      newErrors.slug = 'Please enter Slug.';
    }

    if (!inputFields.description || inputFields.description.trim() === '') {
      newErrors.description = 'Please enter Description.';
    }

    if (!inputFields.state || inputFields.state.trim() === '' || inputFields.state === 'Select') {
      newErrors.state = 'Please enter State.';
    }

    if (!inputFields.city || inputFields.city.trim() === '' || inputFields.city === 'Select') {
      newErrors.city = 'Please enter City.';
    }

    if (!inputFields.address || inputFields.address.trim() === '') {
      newErrors.address = 'Please enter Address.';
    }
    if (selectedId) {
      if (!inputFields?.sortOrder) {
        newErrors.sortOrder = 'Please enter Sort Order.';
      }
    }

    // if (!inputFields.isFeatured || inputFields.isFeatured.trim() === '') {
    //   newErrors.isFeatured = 'Please enter isFeatured.';
    // }

    if (!inputFields.mobileAppimage) {
      newErrors.mobileAppimage = 'Please add an Mobile App Image.';
    }

    if (!inputFields.webListImage) {
      newErrors.webListImage = 'Please add an Web List Image.';
    }

    if (!inputFields.webDetailImage) {
      newErrors.webDetailImage = 'Please add an Web Detail Image.';
    }

    if (!inputFields.bannerImage) {
      newErrors.bannerImage = 'Please add an Banner Image.';
    }

    return newErrors;
  };

  const validateInputsHi = () => {
    const newErrors = {};

    if (!inputFieldsHi.titleHi || inputFieldsHi.titleHi.trim() === '') {
      newErrors.titleHi = 'Please enter Title.';
    }

    // if (!inputFieldsHi.slugHi || inputFieldsHi.slugHi.trim() === '') {
    //   newErrors.slugHi = 'Please enter Slug.';
    // }

    if (!inputFieldsHi.descriptionHi || inputFieldsHi.descriptionHi.trim() === '') {
      newErrors.descriptionHi = 'Please enter Description.';
    }

    if (!inputFieldsHi.stateHi || inputFieldsHi.stateHi.trim() === '' || inputFieldsHi.stateHi === 'Select') {
      newErrors.stateHi = 'Please enter State.';
    }

    if (!inputFieldsHi.cityHi || inputFieldsHi.cityHi.trim() === '' || inputFieldsHi.cityHi === 'Select') {
      newErrors.cityHi = 'Please enter City.';
    }

    if (!inputFieldsHi.addressHi || inputFieldsHi.addressHi.trim() === '') {
      newErrors.addressHi = 'Please enter Address.';
    }

    // if (!inputFieldsHi.isFeaturedHi || inputFieldsHi.isFeaturedHi.trim() === '') {
    //   newErrors.isFeaturedHi = 'Please enter isFeatured.';
    // }

    if (!inputFieldsHi.mobileAppimageHi) {
      newErrors.mobileAppimageHi = 'Please add an Mobile App Image.';
    }

    if (!inputFieldsHi.webListImageHi) {
      newErrors.webListImageHi = 'Please add an Web List Image.';
    }

    if (!inputFieldsHi.webDetailImageHi) {
      newErrors.webDetailImageHi = 'Please add an Web Detail Image.';
    }

    if (!inputFieldsHi.bannerImageHi) {
      newErrors.bannerImageHi = 'Please add an Banner Image.';
    }

    return newErrors;
  };


  useEffect(()=>{
    if(editDataId){
      showEditData()
    }
  },[])

  const showEditData =(item) => {
   
      setSelectedId(item?._id)

      setInputFields({
        title: item?.title,
        slug: item?.slug,
        description: item?.description,
        state: item?.state,
        city: item?.city,
        address: item?.address,
        sortOrder: item?.sortOrder,
        mobileAppimage: item?.mobileAppImage,
        webListImage: item?.webListImage,
        webDetailImage: item?.webDetailImage,
        bannerImage: item?.bannerImage,
      });

      setInputFieldsHi({
        titleHi: item?.titleHi,
        descriptionHi: item?.descriptionHi,
        stateHi: item?.stateHi,
        cityHi: item?.cityHi,
        addressHi: item?.addressHi,
        mobileAppimageHi: item?.mobileAppImageHi,
        webListImageHi: item?.webListImageHi,
        webDetailImageHi: item?.webDetailImageHi,
        bannerImageHi: item?.bannerImageHi,
      });
  
      setInputFieldSec(item?.chadhawaSamgriItems??[
        { samagri: '',
          price: '',
          globalPrice: '',
        }
      ]);

  }

  useEffect(()=>{
    getEditData()
  },[editDataId])

  const getEditData =async()=>{
    if(editDataId){
      let chadhawaDetailsRes = await APIServices.get(`templeList/getDetailById/${editDataId}`, headers);
      if (chadhawaDetailsRes?.status === 200) {
        let apiData = chadhawaDetailsRes?.data;
        if (apiData?.status) {
          showEditData(apiData?.data[0])
        }
      }
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();

    const newErrors = validateInputs();
    const newErrorsHi = validateInputsHi();
    // const secondaryValid = validateSecondaryInputs();

    if (Object.keys(newErrors).length > 0 || Object.keys(newErrorsHi).length > 0) {
      setErrors(newErrors);
      setErrorsHi(newErrorsHi);
      setLoading(false)
      return
    } 

   setLoading(true)

    const formData = new FormData();
    formData.append('title', inputFields.title);
    formData.append('slug', inputFields.slug);
    formData.append('description', inputFields.description);
    formData.append('state', inputFields.state);
    formData.append('city', inputFields.city);
    formData.append('address', inputFields.address);
    formData.append('mobileAppImage', appImageEn);
    formData.append('webListImage', webListImageEn);
    formData.append('webDetailImage', webDetailImageEn);
    formData.append('bannerImage', bannerImageEn);

    formData.append('titleHi', inputFieldsHi.titleHi);
    formData.append('descriptionHi', inputFieldsHi.descriptionHi);
    formData.append('stateHi', inputFieldsHi.stateHi);
    formData.append('cityHi', inputFieldsHi.cityHi);
    formData.append('addressHi', inputFieldsHi.addressHi);
    formData.append('mobileAppImageHi', appImageHi);
    formData.append('webListImageHi', webListImageHi);
    formData.append('webDetailImageHi', webDetailImageHi);
    formData.append('bannerImageHi', bannerImageHi);

   formData.append("chadhawaSamgriItems", JSON.stringify(inputFieldSec))

    try{
      const response = await APIServices.post('templeList/add', formData);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false)
          handleReset()
      } 
      else {
        toast.error(response?.data?.message);
      }

    }
    catch (error) {
      // toast.error('Something went wrong!');
    }
     finally {
      setLoading(false); 
    }
  };

  const handleUpdate = async(e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('title', inputFields.title);
    formData.append('slug', inputFields.slug);
    formData.append('description', inputFields.description);
    formData.append('state', inputFields.state);
    formData.append('city', inputFields.city);
    formData.append('address', inputFields.address);
    formData.append('sortOrder', inputFields.sortOrder);
    formData.append('mobileAppImage', appImageEn);
    formData.append('webListImage', webListImageEn);
    formData.append('webDetailImage', webDetailImageEn);
    formData.append('bannerImage', bannerImageEn);

    formData.append('titleHi', inputFieldsHi.titleHi);
    // formData.append('slugHi', inputFieldsHi.slugHi);
    formData.append('descriptionHi', inputFieldsHi.descriptionHi);
    formData.append('stateHi', inputFieldsHi.stateHi);
    formData.append('cityHi', inputFieldsHi.cityHi);
    formData.append('addressHi', inputFieldsHi.addressHi);
    // formData.append('sortOrderHi', inputFieldsHi.sortOrderHi);
    formData.append('mobileAppImageHi', appImageHi);
    formData.append('webListImageHi', webListImageHi);
    formData.append('webDetailImageHi', webDetailImageHi);
    formData.append('bannerImageHi', bannerImageHi);

    formData.append("chadhawaSamgriItems", JSON.stringify(inputFieldSec))

    const newErrors = validateInputs();
    const newErrorsHi = validateInputsHi();
    // const secondaryValid = validateSecondaryInputs();

    if (Object.keys(newErrors).length > 0 || Object.keys(newErrorsHi).length > 0 ) {
      setErrors(newErrors);
      setErrorsHi(newErrorsHi);
    } else {
      // alert('Submission successful!');
      const response = await APIServices.put(`templeList/editOne/${editDataId}`, formData);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        // setLoading(false)
        navigate("/templeList")
        
      } else {
        toast.error(response?.data?.message);
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log('console 2222222', key, value);
      // }
      // handleReset()
    }
   
  };


  return (
    <div className='container mt-5'>
      <Form className='add_blog_form box-shadow br-6 p-20 my-3' onSubmit={(e)=> `${selectedId ? handleUpdate(e) : handleSubmit(e)}`}>
        <Row>
          <Col sm={6}>
            <h5 className='text-center mb-4'>English Content</h5>
            <Form.Group as={Row} className='mb-3' controlId='formBasicTitle'>
              <Form.Label className='required' column sm='3'>
                Title
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  isInvalid={!!errors.title}
                  value={inputFields.title}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBasicSlug'>
              <Form.Label className='required' column sm='3'>
                Slug
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Slug...'
                  name='slug'
                  isInvalid={!!errors.slug}
                  value={inputFields.slug}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.slug}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBasicDescription'>
              <Form.Label className='required' column sm='3'>
                Description
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  as='textarea'
                  // rows={6}
                  style={{ height: 100 }}
                  placeholder='Enter Description...'
                  name='description'
                  isInvalid={!!errors?.description}
                  value={inputFields?.description}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors?.description}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='3'>
                State
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='text-capitalize st-in-bdr-clr'
                  name='state'
                  isInvalid={!!errors.state}
                  onChange={handleChange}
                  value={inputFields.state}
                >
                  <option value='Select'>Select State</option>
                  <option value='Rajasthan'>Rajasthan</option>
                  <option value='Madhya Pradesh'>Madhya Pradesh</option>
                  <option value='Gujarat'>Gujarat</option>
                  <option value='Uttar Pradesh'>Uttar Pradesh</option>
                  <option value='Maharashtra'>Maharashtra</option>
                  <option value='Kerala'>Kerala</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.state}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBasicCity'>
              <Form.Label className='required' column sm='3'>
                City
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='text-capitalize st-in-bdr-clr'
                  name='city'
                  isInvalid={!!errors.city}
                  onChange={handleChange}
                  value={inputFields.city}
                >
                  <option value='Select'>Select City</option>
                  <option value='Indore'>Indore</option>
                  <option value='Bhopal'>Bhopal</option>
                  <option value='Harda'>Harda</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.city}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBasicSortAddress'>
              <Form.Label className='required' column sm='3'>
                Address
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Address...'
                  name='address'
                  isInvalid={!!errors.address}
                  value={inputFields.address}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>{errors.address}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            {selectedId ? (
              <Form.Group as={Row} className='mb-3' controlId='formBasicSortOrder'>
                <Form.Label className='required' column sm='3'>
                  Sort Order
                </Form.Label>
                <Col sm='9'>
                  <Form.Control
                    type='text'
                    placeholder='Sort Order...'
                    name='sortOrder'
                    isInvalid={!!errors.sortOrder}
                    value={inputFields.sortOrder}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.sortOrder}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            ) : (
              ''
            )}

            <Form.Group as={Row} className='mb-3' controlId='formMobileImage'>
              <Form.Label className='required' column sm='3'>
                {' '}
                Mobile App Image{' '}
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  id='upload-photo-1'
                  name='mobileAppimage'
                  className='d-none'
                  isInvalid={!!errors?.mobileAppimage}
                  onClick={e => {
                    handleOpenModal('appImageEn');
                    handleChange(e);
                  }}
                />

                <img
                  name='mobileAppimage'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFields?.mobileAppimage ? inputFields?.mobileAppimage : upimg}
                  onClick={e => {
                    handleOpenModal('appImageEn');
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.mobileAppimage}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formWebImage'>
              <Form.Label className='required' column sm='3'>
                Web List Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  // id='upload-photo-1'
                  style={{ maxWidth: '200px' }}
                  className='d-none'
                  name='webListImage'
                  isInvalid={!!errors.webListImage}
                  onClick={e => {
                    handleOpenModal('webListImageEn');
                    handleChange(e);
                  }}
                  // onChange={handleWebImageChange}
                />
                <img
                  name='webListImage'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFields?.webListImage ? inputFields?.webListImage : upimg}
                  onClick={e => {
                    handleOpenModal('webListImageEn');
                    handleChange(e);
                  }}
                />

                <Form.Control.Feedback type='invalid'>{errors.webListImage}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formDetailImage'>
              <Form.Label className='required' column sm='3'>
                Web Detail Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='file'
                  style={{ maxWidth: '200px' }}
                  className='d-none'
                  name='webDetailImage'
                  isInvalid={!!errors.webDetailImage}
                  onClick={e => {
                    handleOpenModal('webDetailImageEn');
                    handleChange(e);
                  }}
                  // onChange={handleWebDetailImageChange}
                />
                <img
                  name='webDetailImage'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFields?.webDetailImage ? inputFields?.webDetailImage : upimg}
                  onClick={e => {
                    handleOpenModal('webDetailImageEn');
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.webDetailImage}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBannerImage'>
              <Form.Label className='required' column sm='3'>
                Banner Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='file'
                  style={{ maxWidth: '200px' }}
                  className='d-none'
                  name='bannerImage'
                  isInvalid={!!errors?.bannerImage}
                  onClick={e => {
                    handleOpenModal('bannerImageEn');
                    handleChange(e);
                  }}
                  // onChange={handleBannerImageChange}
                />
                <img
                  name='bannerImage'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFields?.bannerImage ? inputFields?.bannerImage : upimg}
                  onClick={e => {
                    handleOpenModal('bannerImageEn');
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.bannerImage}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <h5 className='text-center mb-4'>Hindi Content</h5>
            <Form.Group as={Row} className='mb-3' controlId='formBasicTitleHi'>
              <Form.Label className='required' column sm='3'>
                Title
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='titleHi'
                  isInvalid={!!errorsHi.titleHi}
                  value={inputFieldsHi.titleHi}
                  onChange={handleChangeHi}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi.titleHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formSlugHi'>
              <Form.Label className='required' column sm='3'>
                Slug
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Slug...'
                  name='slug'
                  isInvalid={!!errors.slug}
                  value={inputFields.slug}
                  onChange={handleChange}
                  readOnly
                />
                <Form.Control.Feedback type='invalid'>{errors.slug}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBasicDescriptionHi'>
              <Form.Label className='required' column sm='3'>
                Description
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  as='textarea'
                  // rows={6}
                  style={{ height: 100 }}
                  placeholder='Enter Description...'
                  name='descriptionHi'
                  isInvalid={!!errorsHi?.descriptionHi}
                  value={inputFieldsHi?.descriptionHi}
                  onChange={handleChangeHi}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi?.descriptionHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='stateHi'>
              <Form.Label className='required' column sm='3'>
                State
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='text-capitalize st-in-bdr-clr'
                  name='stateHi'
                  isInvalid={!!errorsHi.stateHi}
                  onChange={handleChangeHi}
                  value={inputFieldsHi.stateHi}
                >
                  <option value='Select'>Select State</option>
                  <option value='Rajasthan'>Rajasthan</option>
                  <option value='Madhya Pradesh'>Madhya Pradesh</option>
                  <option value='Gujarat'>Gujarat</option>
                  <option value='Uttar Pradesh'>Uttar Pradesh</option>
                  <option value='Maharashtra'>Maharashtra</option>
                  <option value='Kerala'>Kerala</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errorsHi.stateHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='cityHi'>
              <Form.Label className='required' column sm='3'>
                City
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='text-capitalize st-in-bdr-clr'
                  name='cityHi'
                  isInvalid={!!errorsHi.cityHi}
                  onChange={handleChangeHi}
                  value={inputFieldsHi.cityHi}
                >
                  <option value='Select'>Select City</option>
                  <option value='Indore'>Indore</option>
                  <option value='Bhopal'>Bhopal</option>
                  <option value='Harda'>Harda</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errorsHi.cityHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='addressHi'>
              <Form.Label className='required' column sm='3'>
                Address
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Address...'
                  name='addressHi'
                  isInvalid={!!errorsHi.addressHi}
                  value={inputFieldsHi.addressHi}
                  onChange={handleChangeHi}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi.addressHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formMobileImageHi'>
              <Form.Label className='required' column sm='3'>
                {' '}
                Mobile App Image{' '}
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  id='upload-photo-1'
                  name='mobileAppimageHi'
                  className='d-none'
                  isInvalid={!!errorsHi?.mobileAppimageHi}
                  onClick={e => {
                    handleOpenModal('appImageHi');
                    handleChange(e);
                  }}
                />

                <img
                  name='mobileAppimageHi'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFieldsHi?.mobileAppimageHi ? inputFieldsHi?.mobileAppimageHi : upimg}
                  onClick={e => {
                    handleOpenModal('appImageHi');
                    handleChangeHi(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi?.mobileAppimageHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formWebImageHi'>
              <Form.Label className='required' column sm='3'>
                Web List Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  // id='upload-photo-1'
                  style={{ maxWidth: '200px' }}
                  className='d-none'
                  name='webListImageHi'
                  isInvalid={!!errorsHi.webListImageHi}
                  onClick={e => {
                    handleOpenModal('webListImageHi');
                    handleChangeHi(e);
                  }}
                  // onChange={handleWebImageChange}
                />
                <img
                  name='webListImageHi'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFieldsHi?.webListImageHi ? inputFieldsHi?.webListImageHi : upimg}
                  onClick={e => {
                    handleOpenModal('webListImageHi');
                    handleChangeHi(e);
                  }}
                />

                <Form.Control.Feedback type='invalid'>{errorsHi.webListImageHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formDetailImageHi'>
              <Form.Label className='required' column sm='3'>
                Web Detail Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='file'
                  style={{ maxWidth: '200px' }}
                  className='d-none'
                  name='webDetailImageHi'
                  isInvalid={!!errorsHi.webDetailImageHi}
                  onClick={e => {
                    handleOpenModal('webDetailImageHi');
                    handleChangeHi(e);
                  }}
                  // onChange={handleWebDetailImageChange}
                />
                <img
                  name='webDetailImageHi'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFieldsHi?.webDetailImageHi ? inputFieldsHi?.webDetailImageHi : upimg}
                  onClick={e => {
                    handleOpenModal('webDetailImageHi');
                    handleChangeHi(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi?.webDetailImageHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBannerImageHi'>
              <Form.Label className='required' column sm='3'>
                Banner Image
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='file'
                  style={{ maxWidth: '200px' }}
                  className='d-none'
                  name='bannerImageHi'
                  isInvalid={!!errorsHi?.bannerImageHi}
                  onClick={e => {
                    handleOpenModal('bannerImageHi');
                    handleChange(e);
                  }}
                  // onChange={handleBannerImageChange}
                />
                <img
                  name='bannerImageHi'
                  style={{ width: '180px', height: '160px' }}
                  alt='img'
                  src={inputFieldsHi?.bannerImageHi ? inputFieldsHi?.bannerImageHi : upimg}
                  onClick={e => {
                    handleOpenModal('bannerImageHi');
                    handleChangeHi(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errorsHi?.bannerImageHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <div className=''>
          <h5 className='mt-4'>Chadhawa Samgri Items : </h5>
          <div
            onClick={addInputField}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              color: 'green',
            }}
          >
            <div className='Et-btn add-btn1 btn-success1'>
              Add
              <FaPlus className='hd-cr' title='Add Section' />
            </div>
          </div>
        </div>

        {inputFieldSec.map((field, index) => {
         const { samagri, price, globalPrice } = field;
          return(
            <>
                <div key={index} className=''>
                  <Row>
                    <Col sm={4}>
                      <Form.Group as={Row} className='mb-3'
                      //  controlId={`formState-${index}`}
                       >
                        <Form.Label column sm='12'>
                          Samagri *
                        </Form.Label>
                        <Col sm='12'>
                          <Form.Select
                            name='samagri'
                            value={samagri}
                            // onChange={e => handleSecFieldChange(index, e)}
                            // isInvalid={!!field.errors.samagri}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            isInvalid={!!errors?.ChadhawaSelection?.[index]?.samagri}
                          >
                            <option value='Select'>Select Samagri</option>
                            <option value='Phool'>Phool</option>
                            <option value='Coin'>Coin</option>
                            <option value='Laddu'>Laddu</option>
                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors?.ChadhawaSelection?.[index]?.samagri}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group as={Row} controlId={`formPrice-${index}`}>
                        <Form.Label column sm='12'>
                          Price *
                        </Form.Label>
                        <Col sm='12'>
                          <Form.Control
                            type='number'
                            name='price'
                            placeholder='Price...'
                            value={price}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            isInvalid={!!errors?.ChadhawaSelection?.[index]?.price}
                            // onChange={e => handleSecFieldChange(index, e)}
                            onKeyPress={evnt => {
                              // Allow only numeric characters, backspace, and the decimal point
                              if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                                evnt.preventDefault();
                              }
                            }}
                            step='0.01'
                            min='0'
                            // isInvalid={!!field.errors.price}
                          />
                          <Form.Control.Feedback type='invalid'>{errors?.ChadhawaSelection?.[index]?.price}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group as={Row} controlId={`formGlobalPrice-${index}`}>
                        <Form.Label column sm='12'>
                          Global Price *
                        </Form.Label>
                        <Col sm='12'>
                          <Form.Control
                            type='number'
                            placeholder='Global Price...'
                            name='globalPrice'
                            value={globalPrice}
                            // onChange={e => handleSecFieldChange(index, e)}
                            onChange={(evnt) => handleChangeSec(index, evnt)}
                            isInvalid={!!errors?.ChadhawaSelection?.[index]?.globalPrice}
                            onKeyPress={evnt => {
                              if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                                evnt.preventDefault();
                              }
                            }}
                            step='0.01'
                            min='0'
                            // isInvalid={!!field?.errors?.globalPrice}
                          />
                          <Form.Control.Feedback type='invalid'>{errors?.ChadhawaSelection?.[index]?.globalPrice}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {inputFieldSec.length !== 1 ? (
                      <div
                        onClick={() => removeInputField(index)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                          color: 'red',
                          cursor: 'pointer',
                        }}
                      >
                        <button className='Dt-btn mt-2 add-btn1'>
                          Remove
                          <FaMinus className='hd-cr fw-bold' title='Remove Section' />
                        </button>
                      </div>
                    ) : (
                      ''
                    )}

                  </Row>
                </div>
            </>
          )
                  })}

        <div className='text-center'>
          
          <Button variant='primary' type='submit' className='as_btn'>
          {loading ? "Loading..." : `${ selectedId ? "Edit" : "Add"}`}
                {/* { selectedId ? "Edit" : "Add"} */}
          </Button>
          <Button variant='primary' className='as_btn' onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button variant='primary' className='as_btn' onClick={handleReset}>
            Reset
          </Button>
        </div>
      </Form>
      <PujaImgGalleyModel
        id='ImgGalleyModel'
        show={showImageGalleyModel}
        onHide={() => setShowImageGalleyModel(false)}
        modalHeading={'ImgGalley'}
        size='lg'
        handleSetImageUrl={handleSetImageUrl}
        fieldKey={modalFieldKey}
        setClearSel={setClearSel}
        clearSel={clearSel}
      />
      <ToastContainer />
    </div>
  );
};

export default TempleAddEdit;
