import { useState, useCallback, useMemo ,useEffect,useContext} from 'react';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import { capitalizeFirstLetter,setCookies } from '../utils/Helper';
import { HindiToEnglishDayMaping } from '../utils/Constants';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row, Container } from 'react-bootstrap';
import APIServicenew from '../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import CropModal from '../components/Shared/CropModal';
import upimg from "../../src/imgs/default.jpg";
import moment from 'moment';
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

function AstroNotification() {
  document.title = 'Notificaton';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const permissioncontext =usePermissionData("astroDailyNotification")
  console.log("🚀 ~ AstroNotification ~ permissioncontext:", permissioncontext)

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [typeOfContent ,setTypeOfContent ] = useState("temple") ;
  const [type ,setType] = useState("");
  const [ dataType,setDataType] = useState("")
  const [ contentType , setContentType] =  useState("")
  const [image, setimage] = useState("");
  const [ title ,setTitle] = useState();
  const [ message ,setMessage] = useState();
  const [ godName ,setGodName] = useState();
  const [ day ,setDay] = useState();
  const [ time ,setTime] = useState();
  const [ list ,setList] = useState();
  const [language ,setLanguage] = useState("english");
  const[loader,setLoader]=useState(false)
  const [errors, setErrors] = useState({});
  
  const [showQuoteModal, setShowQuoteModal] = useState();
  const [allDataCount , setAllDataCount] = useState()
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()

  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const APIServicesNoti = new APIServicenew(process.env.REACT_APP_BULKNOT_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  // const condition = ["temple","bhajan_chalisa","puja" ,"bhajan","watch_live"]
  const condition = ["temple"]
  const columns = useMemo(
    () => [
      {
        Header: 'NO.',
        Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
      },
      {
        Header: 'Message',
        Cell: (e) => {
			if(e.row.original.title){
				return <><img
				src={`${e.row.original.file}`}
				alt=''
				className='quotes_table_preview_image'
			/>
		      <span style={{marginLeft:"4px"}}>{capitalizeFirstLetter(e.row.original.message)}</span>
          </>
			}
		}
      },
     
      {
        Header: 'God ',
        Cell: (e) => {
          if(e.row.original.godName===""||e.row.original.godName==="null"||e.row.original.godName==="undefined"){
        return <><span>-</span></>
          }else{
            return <><span>{capitalizeFirstLetter(e.row.original.godName)}</span></>
          }
        }
      },

      {
        Header: 'Day',
        Cell: e => <span>{capitalizeFirstLetter(e.row.original.day)}</span>,
      },
      {
        Header: 'Time',
        Cell: e => <span>{e.row.original.time}</span>,
      },

      {
        Header: 'Edit',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.edit?'Et-btn edit-btn1 disabled-btn ':'Et-btn edit-btn1'}
                  value='Edit'
                  onClick={() =>{
                    if(permissioncontext.edit){
                      handleShowQuoteModal(
                        e.row.original._id,
                        e.row.original.title,
                        e.row.original.message,
                        e.row.original.godName,
                        e.row.original.day,
                        e.row.original.time,
                        e.row.original.typeofcontent,
                        e.row.original.contentEn === true?"english":e.row.original?.contentHi === true ? "hindi" :"",
                        e.row.original.type,
                        e.row.original.file,
                      )
                    }
                  }
                   
                  }
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Delete',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.delete?"Dt-btn disabled-btn ":'Dt-btn'}
                  value='Delete'
                 onClick={() => {

                   if(permissioncontext.delete)handleShowDeleteModal(e.row.original._id)}
                 }
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Date',
        Cell: e => {
          return <span>{moment(e.row.original.updatedAt).format("DD MMM, YYYY hh:mm A")}</span>;
        },
      },
    ],
    [permissioncontext]
  );

  const getGodNameAndDay = async() => {
    const res = await APIServices.get(`master/getGodList/${contentType}`,headers);
      if (res && res.status === 200) {
        let resData = res.data;
        if (resData && resData.status && resData.Listdata && resData.Listdata.length) {
       setList(resData.Listdata)
       
      
        }
      }
  }

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

  useEffect(()=>{
    getGodNameAndDay()
  },[contentType])

  const fetchProfilesByPagination = async (skip, limit, index) => {
      try {
        const response = await APIServices.get(`astroDailyNotification/getAll/${typeOfContent}/${skip}/${limit}/${language}`, headers);
        let data = response?.response?.data

        if (response.data?.status === 1) {
          setAllDataCount(response.data?.total_count)
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setTableData(response.data?.list);
          window.scrollTo({ top: 0 });
        } else{

      if(data.status == 4){
      logout()
     }
    }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    }
    

  const handleQuotesModalClose = () => {
    setShowQuoteModal(false);
    setSelectedId(null);
    setTitle(null)
    setMessage(null)
    setGodName(null)
    setDay(null)
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedId(null);
  };

  const handleShowQuoteModal = async (id, title, message ,godName, day,time,typeOfContent ,contentType,type, file) => {
    setTitle(title);
    setMessage(message)
    setGodName(godName)
    setDay(day)
    setTime(time)
    setDataType(typeOfContent)
    setContentType(contentType)
    setType(type)
    setimage(`${file}`);
    
    setSelectedId(id);
    setShowQuoteModal(true);
  };

  const handleShowDeleteModal = id => {
    setShowDeleteModal(true);
    setSelectedId(id);
  };

  const handleDelete = async () => {
    await APIServices.delete(`astroDailyNotification/findByIdAndremove/${selectedId}`, headers)
      .then(res => {
        if (res.data.status === true) {
          fetchProfilesByPagination(skip, limit, pageIndex);
          setShowDeleteModal(false);
          setTimeout(() => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setSelectedId(null);
        setimage(null);
  
      
      });
  };
 
  const reset =() =>{
    setSelectedId(null);
    setTitle(null)
    setMessage(null)
    setGodName(null)
    setDay(null)
    setTime(null)
    setType(null)
    setContentType(null);
    setDataType(null)
    setimage(null);
    setCropImg()

  }
  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  

      if (!dataType ||dataType === "") 
      newErrors.dataType = "Please select type Of Content.";

      if (!title ||title === "") 
      newErrors.title = "Please enter title.";

      if (!message ||message === "") 
      newErrors.message = "Please enter message.";

      if (!contentType ||contentType === "") 
      newErrors.contentType = "Please select content type."; 

      if ((condition.includes(dataType) &&!godName) ||(godName === "")) 
      newErrors.godName = "Please select god Name."; 

      if (!day ||day === "") 
      newErrors.day = "Please select day.";

      if (!time ||time === "") 
      newErrors.time = "Please select time.";

      if (!type || type === "") 
      newErrors.type = "Please select type.";

      if (!image || image === '') 
      newErrors.file = "Please add image.";

      return newErrors;
  }; 
  const handleQuoteButtonClick = async e => {
    e.preventDefault();
    let val = list?.find((x)=>{
      if(x?.godName === godName){
        return x;
      }
     })?._id
    const formData = new FormData();
    formData.append('title', title);
    formData.append('message', message);
    formData.append('godName', condition.includes(dataType) ? godName : "");
    formData.append('godId', condition.includes(dataType) ? val : "");
    formData.append('day', day);
    formData.append('time', time);
    formData.append("type", type)
    formData.append('typeofcontent', dataType);
    if ( image && typeof image === 'object') {
      formData.append('file', image);
    }
    if (contentType === "english") {
        formData.append("contentEn", true);
        formData.append("contentHi", false);
  
      } else if (contentType === "hindi") {
        formData.append("contentHi", true);
        formData.append("contentEn", false);
      }
    formData.append('id', "");
	 const newHeaders = {
     ...headers,
	 "Content-Type": "multipart/form-data"
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else {
      try {
        if (selectedId) {
        let  response = await APIServices.put(`astroDailyNotification/editOne/${selectedId}`, formData, newHeaders); 
        if (response?.status === 200) {
          var apiData = response?.data;
            var resdata = apiData?.status 
      }
      setLoader(false)
        } else {
        let response = await APIServices.post(`astroDailyNotification/upload`, formData, newHeaders)
          if (response?.status === 200) {
            var apiData = response?.data;
              var resdata = apiData?.status
         }
         setLoader(false)
        }
        if (resdata === true) {
          setLoader(false)
          setShowQuoteModal(false);
          fetchProfilesByPagination(skip, limit, pageIndex);
          setTimeout(() => {
            toast.success(apiData.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
          reset();
        }else{
          toast.error(apiData?.message);
          setLoader(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const showEnDays =(value) =>{
  let  val =   HindiToEnglishDayMaping[Object.keys(HindiToEnglishDayMaping).find(key => key.includes(value)??key)]
  return val;
}
   

    function handleChange(e) {
      let key, value;
      key = e?.target?.name;
      if (!!errors[key]){
        setErrors({
          ...errors,
          [key]: null,
          });
      }
    }
    useEffect(()=>{
      if(cropimg && caartifileimage){
        setimage(caartifileimage);
      } 
    },[cropimg,caartifileimage])


  const renderQuoteFormModal = () => {
    return (
      <Modal show={showQuoteModal} backdrop='static' onHide={()=>{handleQuotesModalClose(); setCropImg()}} scrollable={true} centered>
        <Modal.Header closeButton>
          <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Notification`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className="required" column sm="3">
                Type 
              </Form.Label>
              <Col sm="9">
              <Form.Select
              aria-label="categories"
              className=" astroWatch-select-hover"
              //  value={dataType}
              name='dataType'
              defaultValue={dataType}
              isInvalid={!!errors?.dataType}
              onChange={(e) =>{ setDataType(e.target.value);handleChange(e)}}
            >
             <option value="">Choose</option>
              <option value="temple">Temple</option>
              {/* <option value="bhajan_chalisa">Bhajan & Chalisa</option> */}
              {/* <option value="morning_quotes">Morning Quotes</option> */}
              {/* <option value="daily_horoscope">Daily Horoscope</option> */}
              {/* <option value="chankya_quotes">Chankya quotes</option> */}
              {/* <option value="motivational_quotes">Motivational Quotes</option> */}
              {/* <option value="live_katha">Live katha</option> */}
              <option value="puja">Puja</option>
              <option value="chadhawa">Chadhawa</option>
              {/* <option value="bhajan">Bhajan</option> */}
              {/* <option value="watch_live">Watch Live</option> */}
              <option value="talk_to_astrologer">Astrologer</option>

            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors?.dataType}
              </Form.Control.Feedback>
              </Col>
            </Form.Group>
          <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className="required" column sm="3">
                Title
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  defaultValue={title}
                  isInvalid={!!errors?.title}
                  className="form-control "
                  onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label  className="required" column sm="3">
                Message
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as='textarea'
                  type='text'
                  placeholder='Message...'
                  name='message'
                  style={{ height: 100 }}
                  defaultValue={message}
                  isInvalid={!!errors?.message}
                  className="form-control "
                  onChange={(e) => {setMessage(e.target.value);handleChange(e)}}
                />
                <Form.Control.Feedback type="invalid">
                {errors?.message}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Content Type
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  name ="contentType"
                  defaultValue={contentType}
                  isInvalid={!!errors?.contentType}
                  onChange={(e) =>{ setContentType(e.target.value);handleChange(e)}}
                >
                  <option value="">Select</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors?.contentType}
              </Form.Control.Feedback>
              </Col>
            </Form.Group>
            { 
            //  dataType === "temple"
            condition.includes(dataType) 
            &&
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className="" column sm="3">
                God Name
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  value={godName}
                  name ="godName"
                  isInvalid={!!errors?.godName}
                  onChange={(e) =>{ setGodName(e.target.value);handleChange(e)}}
                >
                  <option value="">select</option>
                  {list?.map((item)=>
                  <option value={item?.godName}>{capitalizeFirstLetter(item.godName)}</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors?.godName}
              </Form.Control.Feedback>
              </Col>
            </Form.Group>}
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className="required" column sm="3">
                Day
              </Form.Label>
              <Col sm="9">
                  <Form.Select
                  aria-label="Default select example"
                  required
                  value={day}
                  isInvalid={!!errors?.day}
                  name='day'
                  className="form-control hd-cr "
                  onChange={(e) => {setDay(e.target.value);handleChange(e)}}
                >
                  <option value="">All Day</option>
                {/*                   
                  {list?.map((item)=>{
               if (item.day!=="null"){
                return<option value={contentType === "hindi"? showEnDays(item.day):item.day}>{capitalizeFirstLetter(item.day)}</option>
               }
              })} */}
             <option value="sunday">Sunday</option>
             <option value="monday">Monday</option>
             <option value="tuesday">Tuesday</option>
             <option value="wednesday">Wednesday</option>
             <option value="thusday">Thusday</option>
             <option value="friday">Friday</option>
             <option value="saturday">Saturday</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors?.day}
              </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className="required" column sm="3">
                Schedule Time
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type='time'
                  placeholder='Time...'
                  name='time'
                  defaultValue={time}
                  isInvalid={!!errors?.time}
                  onChange={(e) => {setTime(e.target.value);handleChange(e)}}
                />
                 <Form.Control.Feedback type="invalid">
                {errors?.time}
              </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}  className='mb-3' controlId='formTitleInput'>
                    <Form.Label className="required" column sm="3">
                           Customer Type</Form.Label>
                     <Col sm="9">
                        <Form.Select name="type" required value={type}
                  isInvalid={!!errors?.type}
                  onChange={(e) => {setType(e.target.value);handleChange(e)}}
                  >
                            <option value=''>Select Customer</option>
                            {/* <option value="all_user_and_astrologers" disabled>All_user_and_astrologers</option> */}
                            <option value="all_users">All Customer</option>
                            {/* <option value="all_astrologers" disabled>All_astrologers</option> */}
                            <option value="android_users">Android Customer</option>
                            {/* <option value="android_astrologers" disabled>Android_astrologers</option> */}
                            {/* <option value="female_users" disabled>Female_users</option> */}
                            <option value="ios_users">IOS Customer</option>
                            {/* <option value="ios_astrologers" disabled>Ios_astrologers</option> */}
                            {/* <option value="male_users" disabled>Male_users</option> */}
                            {/* <option value="transgender_users" disabled>Transgender_users</option> */}
                            {/* <option value="web_users" disabled>Web_users</option> */}
                            {/* <option value="web_astrologers" disabled>Web_astrologers</option> */}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                {errors?.type}
              </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
           
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className="required" column sm="3">
                Notification Image
              </Form.Label>
              <Col sm="9">
              
                <label id="labimg">
                
              {cropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={cropimg}
              onClick={()=>setCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={image ? image : upimg}
              width="100%" alt="" />}

                 <Form.Control
                // type="file"
                className="form-control "
                isInvalid={!!errors?.file}
                onClick={(e)=>{setShowCrop(true);handleChange(e)}}
                name='file'
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
                <Form.Control.Feedback type="invalid">
                {errors?.file}
              </Form.Control.Feedback>
                </label>
              
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        {loader?<Button variant='primary' className='as_btn' >
        {`${selectedId ? 'Processing...' : 'Processing...'}`}
      </Button>:<Button variant='primary' className='as_btn' onClick={e => {handleQuoteButtonClick(e); }}>
      {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
         
          <Button variant='secondary' className='as_btn reset-btn' onClick={()=>{handleQuotesModalClose(); setCropImg()}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  useEffect(() => {
    fetchProfilesByPagination(skip,limit,pageIndex);
  }, [typeOfContent ,language]);

 

  return (
    <>
    {
      permissioncontext.view?
      <div className='App'>
      <div className='gita_quote_heading as_noti_head'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q '>Astro Daily Notification <small>({allDataCount})</small></h4>
            <div className='_btn d-flex w-50 justify-content-end align-items-center' >
              <Button variant='primary' className='add-btn as_btn me-2' disabled={!permissioncontext.add} onClick={() => {setShowQuoteModal(true); setCropImg(); setimage();}}>
                Add Notification
              </Button>
             
            </div>
          </div>
        </div>
      </div>

      <div className="container my-2">
         <div className="d-flex align-items-center" style={{justifyContent:"flex-end"}}>
         <Form.Select
              aria-label="categories"
              className=" astroWatch-select-hover select_width "
              style={{margin:"10px"}}
               value={typeOfContent}
               onChange={(e) => setTypeOfContent(e.target.value)}
            >
             <option value="temple">Temple</option>
              {/* <option value="bhajan_chalisa">Bhajan & Chalisa</option> */}
              {/* <option value="morning_quotes">Morning Quotes</option> */}
              {/* <option value="daily_horoscope">Daily Horoscope</option> */}
              {/* <option value="chankya_quotes">Chankya quotes</option> */}
              {/* <option value="motivational_quotes">Motivational Quotes</option> */}
              {/* <option value="live_katha">Live katha</option> */}
              <option value="puja">Puja</option>
              <option value="chadhawa">Chadhawa</option>
              {/* <option value="bhajan">Bhajan</option> */}
              {/* <option value="watch_live">Watch Live</option> */}
              <option value="talk_to_astrologer"> Astrologer</option>

            </Form.Select>
            <Form.Select
               aria-label="categories"
               className="astroWatch-select-hover language_select"
              style={{margin:"10px 0px 10px 0px"}}
                value={language}
                onChange={(e) =>setLanguage(e.target.value)}
             >
               <option value="english">English</option>
               <option value="hindi">Hindi</option>
              </Form.Select>
          </div>
          </div>
      {renderQuoteFormModal()}
      <Modal
      centered
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <CropModal 
            showcrop={showcrop}
            setShowCrop={setShowCrop}
            setCropImg={setCropImg}
            cropimg={cropimg}
            caartifileimage={caartifileimage}
            setFileCaartiimage={setFileCaartiimage}/>

      <ToastContainer />
      <DynamicDataTableWithCount
        columns={columns}
        data={tableData}
        // permissioncontext={permissioncontext}
        // canDelete={canDelete}
        shouldUpdate={true}
        notShowPage ={true}
        // totalCount={pageCount}
        // initialState={{
        //   pageIndex: pageIndex,
        //   pageSize: limit,
        // }}
        fetchData={fetchProfilesByPagination}
      />
    </div>:
     <Notauthorized />
    }
   
    </>
  );
}

export default AstroNotification;
