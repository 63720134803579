import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, Form, Col, Row, In } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { ImgGalleyModel } from '../../components/Shared/ImgGalleryModel';
import { useNavigate } from 'react-router-dom';

import usePermissionData from '../../components/Customhook/custompermission';
import Notauthorized from '../../components/Notauthorized';

const Whatsupbroadcasting = () => {
  document.title = 'whatsApp broadcasting ';

  const permissioncontext =usePermissionData("whatsupbroadcasting")
  const cookies = new Cookies();
  const Navigate = useNavigate();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    // formState: { errors },
  } = useForm();
  const [inputState, setInputState] = useState({
    startdate: '',
    enddate: '',
  });
  const [inputLimit, setInputLimit] = useState(1000);
  // const [customNumber, setCustomNumber] = useState([]);
  const [compaign, setCompaign] = useState([]);
  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [activeInputField, setActiveInputField] = useState(null);

  const handleOpenModal = itemId => {
    setActiveInputField(itemId);
    setShowImageGalleyModel(true);
  };

  const handleSetImageUrl = url => {
    handleUrlTextChange({ target: { name: 'image_link', value: url } }, activeInputField);
    setShowImageGalleyModel(false);
  };

  const handleInputChange = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  };

  const handleLimit = event => {
    const value = event.target.value;
    setInputLimit(value);
  };

  const handleCustomNumber = event => {
    const value = event.target.value;
    const arrayValue = value.split(',').map(num => num.trim());
    // setCustomNumber(arrayValue);
  };

  const handleInputChangeEnd = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  };

  useEffect(() => {
    getCampaignList();
    fetchedAllTemplate();
    fetchedLabels();
    fetchedResponder();
  }, []);

  const getCampaignList = async () => {
    const responce = await APIServices.get(`campaign/getCampaign`, headers);
    if (responce?.status === 200) {
      let apiData = responce?.data;
      if (apiData?.status) {
        setCompaign(apiData?.data);
      }
    } else {
      setCompaign([]);
    }
  };

  // react-select for compaign
  const [selectedOption, setSelectedOption] = useState(null);
  const options = compaign.map(item => ({
    value: item?._id,
    label: item?.name,
  }));

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
  };

  // get whatsup template data
  const [alltemplate, setAllTemplate] = useState([]);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [selectedMessagedata, setSelectedMessagedata] = useState([]);
  const [messagedata, setMessagedata] = useState([]);
  const [inputValues, setInputValues] = useState({
    image_links: {},
    body_text: {},
  });
  const [errors, setErrors] = useState({
    image_links: {},
    body_text: {},
  });

  const handleBodyTextChange = (e, index, id) => {
    const { value } = e.target;

    setInputValues(prevInputValues => {
      const updatedBodyText = {
        ...prevInputValues.body_text,
        [id]: [...(prevInputValues.body_text[id] || [])],
      };
      updatedBodyText[id][index] = value;
      return {
        ...prevInputValues,
        body_text: updatedBodyText,
      };
    });
  };

  const handleUrlTextChange = (e, id) => {
    const { value } = e.target;

    setInputValues(prevInputValues => ({
      ...prevInputValues,
      image_links: {
        ...prevInputValues.image_links,
        [id]: value,
      },
    }));
  };

  const fetchedAllTemplate = async () => {
    const res = await APIServices.get(`/whatsAppApi/getTemplate`, headers);
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData.data];

        setAllTemplate(newData);
      }
    }
  };

  const compareBodyTextIdWithItemId = (text, itemId) => {
    return text.hasOwnProperty(itemId);
  };

  const toggleSelection = item => {
    if (selectedMessageIds.includes(item.id)) {
      setSelectedMessageIds([]);
      setSelectedMessagedata([]);
      setMessagedata([]);
      setInputValues({
        image_links: {},
        body_text: {},
      });
    } else if (selectedMessageIds.length === 0) {
      setSelectedMessageIds(prevSelected =>
        prevSelected.includes(item?.id)
          ? prevSelected.filter(selectedId => selectedId !== item?.id)
          : [...prevSelected, item?.id]
      );

      setMessagedata(prevSelected =>
        prevSelected.some(selected => selected.id === item.id)
          ? prevSelected.filter(selected => selected.id !== item.id)
          : [
              ...prevSelected,
              {
                ...item,
              },
            ]
      );
    } else {
      toast.warn('You can select only one template', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleWTMessage = async e => {
    if (messagedata?.length === 0) {
      toast.warn('Please select templete ', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let validationPassed = true;
      if (messagedata[0].components) {
        messagedata[0].components.forEach(component => {
          if (component.type === 'HEADER' && component.format === 'IMAGE') {
            if (
              !compareBodyTextIdWithItemId(inputValues.image_links, messagedata[0]?.id) ||
              !inputValues.image_links[messagedata[0]?.id]
            ) {
              toast.error('Image URL is required.', {
                position: toast.POSITION.TOP_RIGHT,
              });

              validationPassed = false;
            }
          }
          if (component.type === 'BODY' && component.example?.body_text?.[0]?.length > 0) {
            if (
              !compareBodyTextIdWithItemId(inputValues.body_text, messagedata[0]?.id) ||
              !inputValues.body_text[messagedata[0]?.id]
            ) {
              toast.error('Body text is required.', {
                position: toast.POSITION.TOP_RIGHT,
              });
              validationPassed = false;
            }
          }
        });
      }
      if (!validationPassed) {
        return;
      }

      // if (!selectedOption?.value || !inputLimit || !inputState?.startdate || !inputState?.enddate || !selectedLabelId || !selectedResponderId) {
      if (!selectedOption?.value || !inputLimit) {
        toast.error('Please fill out all required fields', {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const imageLink = inputValues.image_links[messagedata[0].id] || '';
      const bodyText = inputValues.body_text[messagedata[0].id] || [];

      const payload = {
        campaignId: selectedOption?.value,
        limit: inputLimit,
        startDate: inputState?.startdate || '',
        endDate: inputState?.enddate || '',
        // customNumbers: customNumber,
        filterId: selectedLabelId || '',
        responderId: selectedResponderId || '',
        // tempArr: selectedMessagedata
        tempArr: [
          ...selectedMessagedata,
          {
            ...messagedata[0],
            body_text: bodyText,
            image_link: imageLink,
          },
        ],
      };
      const res = await APIServices.post(`whatsAppApi/sendBroadcasting`, payload, headers);
      if (res && res.status === 200) {
        let resData = res.data;
        if (resData && resData.status) {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

          handleClearFiled();
        } else if (resData.status == false) {
          toast.error(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const [labels, setLabels] = useState([]);
  const [selectedLabelId, setSelectedLabelId] = useState('');
  const [responder, setResponder] = useState([]);
  const [selectedResponderId, setSelectedResponderId] = useState('');

  //api for all labels data
  const fetchedLabels = async () => {
    const res = await APIServices.get('chat/getChatFilters', headers);
    if (res && res.status === 200) {
      const labelData = res?.data?.data?.filter(item => item?.status === 1);
      setLabels(labelData);
    }
  };

  const handleLabelSelectChange = event => {
    setSelectedLabelId(event.target.value);
  };

  // api for all responder data
  const fetchedResponder = async () => {
    const res = await APIServices.get('responders/list', headers);
    if (res && res.status === 200) {
      setResponder(res?.data?.data);
    }
  };

  const handleClearFiled = () => {
    setInputState({
      startdate: '',
      enddate: '',
    });
    setInputLimit(1000);
    // setCustomNumber([]);
    setSelectedOption('');
    setSelectedMessageIds([]);
    setSelectedMessagedata([]);
    setMessagedata([]);
    setInputValues({
      image_links: {},
      body_text: {},
    });
    setSelectedResponderId('');
    setSelectedLabelId('');
  };
  const handleResponderSelectChange = event => {
    setSelectedResponderId(event.target.value);
  };

 

  return (
    <>
    {
      permissioncontext.view?
<div className=''>
      <div className='gita_quote_heading'>
        <div className='container d-flex align-items-center justify-content-between'>
          <h4 className='hd-list-f left-q'>WhatsApp Broadcasting</h4>
          <Button
            disabled={!permissioncontext.view}
            className='by-btn as_btn'
            variant='primary'
            onClick={() => {
              Navigate(`/broadCastingHistory`);
            }}
          >
            Broadcasting History
          </Button>
        </div>
      </div>
      <div className='container'>
        <div className='notifiction_form box-shadow br-6 p-20 my-3'>
          <form onSubmit={handleSubmit(handleWTMessage)}>
            <Row>
              <Col md={6}>
                <Form.Label className='required'>Select Campaign</Form.Label>
                <Select    classNamePrefix="react-select" autoComplete='new-password' options={options} onChange={handleChange} value={selectedOption} />
              </Col>

              <Col md={3}>
                <Form.Label>Start Date</Form.Label>

                <DatePicker
                  placeholderText={'Select a starting date'}
                  className='form-control form-ch fil-date-pick'
                  dateFormat='yyyy-MM-dd'
                  selected={inputState?.startdate ? new Date(inputState?.startdate) : null}
                  // dateFormat='yyyy-MM-dd'
                  onChange={date => {
                    handleInputChange(date);
                  }}
                />
              </Col>

              <Col md={3}>
                <Form.Label>End Date</Form.Label>

                <DatePicker
                  placeholderText={'Select a ending date'}
                  className='form-control form-ch fil-date-pick '
                  name='endDate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleInputChangeEnd(date);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>

              <Col md={3} className='mt-2'>
                <Form.Label className='required '>Limit</Form.Label>
                <Form.Control
                  className='p-1 form-control form-ch '
                  placeholder='Enter limit'
                  id='limitedInput'
                  type='number'
                  value={inputLimit}
                  onChange={handleLimit}
                />
              </Col>

              {/* <Col md={3}>
              <Form.Label className='mt-3 required'>Custom Numbers</Form.Label>
              <Form.Control
                className='p-1 form-control form-ch '
                id='limitedInput'
                type='text'
                placeholder='Enter number with coma sepereate'
                value={customNumber.join(',')}
                onChange={handleCustomNumber}
              />
            </Col> */}
              {/* <div class='d-flex align-items-center justify-content-between'> */}
              <Col md={3} className='mt-2'>
                <Form.Label>Labels</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu me-1 text-capitalize w-50'
                  // onChange={handleLabelSelectChange}
                  // value={selectedLableValue.current}
                  onChange={handleLabelSelectChange}
                  value={selectedLabelId}
                >
                  <option value='all' className='fw-bold'>
                    All Labels
                  </option>
                  {labels.map(label => {
                    return (
                      <>
                        <option
                          className='text-capitalize'
                          value={label._id}
                          style={{ color: label?.colourCode === '' ? 'black' : label.colourCode }}
                        >
                          {label.name}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col>

              <Col md={3} className='mt-2'>
                <Form.Label>Responder</Form.Label>
                <Form.Select
                  aria-label='label-filter-menu'
                  className='fw-bold label-selected-menu w-100 ms-1 text-capitalize'
                  // onChange={handleResponderChange}
                  // value={selectedResponder.current}
                  onChange={handleResponderSelectChange}
                  value={selectedResponderId}
                >
                  <option value='all' className='fw-bold'>
                    Responder
                  </option>
                  {responder.map(respon => {
                    return (
                      <>
                        <option className='text-capitalize' value={respon._id}>
                          {respon.name}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col>

              {/* </div> */}
              <Col md={12} className='mt-2'>
                <Form.Label className='required'>Select Template</Form.Label>
                <div className='row mt-1'>
                  {alltemplate?.map(item => (
                    <div className='col-md-8 col-lg-4' key={item?.id}>
                      <div
                        className='card mb-4'
                        style={{
                          // width: '21rem',
                          height: '14rem',
                          position: 'relative',
                          border: selectedMessageIds.includes(item?.id) ? '2px solid #9d102a' : '1px solid #ddd',
                        }}
                      >
                        <div className='card-body' style={{ overflow: 'auto' }}>
                          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <input
                              type='checkbox'
                              checked={selectedMessageIds.includes(item?.id)}
                              readOnly
                              className='form-check-input mt1'
                              onClick={() => toggleSelection(item)}
                            />
                            <h6 style={{ marginLeft: '10px', textTransform: 'capitalize' }}>
                              {item?.name.replace(/_/g, ' ')}
                            </h6>
                          </div>
                          {item?.components?.some(component => component.type === 'HEADER') && (
                            <>
                              <Form.Control
                                className='form-ch mb-1 required'
                                name='image_link'
                                type='url'
                                placeholder='Add image url'
                                value={inputValues.image_links[item.id] || ''}
                                onChange={e => handleUrlTextChange(e, item.id)}
                              />
                              <Button
                                variant='secondary'
                                className='btn-link mb-1'
                                disabled={!permissioncontext.edit}
                                onClick={() => {
                                  handleOpenModal(item.id);
                                }}
                              >
                                Select Image Url
                              </Button>
                            </>
                          )}

                          <p style={{ fontSize: '14px' }}>
                            {item?.components?.find(component => component.type === 'BODY')?.text}
                          </p>

                          {Array.from({
                            length:
                              item?.components?.find(component => component.type === 'BODY')?.example?.body_text[0]
                                ?.length || 0,
                          }).map((_, index) => (
                            <div key={index} className='mb-3'>
                              <Form.Control
                                className={`body-text-input form-ch mb-1 ${
                                  errors.body_text[item.id] && errors.body_text[item.id][index] && 'is-invalid'
                                }`}
                                name={`body_text`}
                                type='text'
                                placeholder={`Enter value for body_text ${index + 1}`}
                                value={(inputValues.body_text[item.id] && inputValues.body_text[item.id][index]) || ''}
                                onChange={e => handleBodyTextChange(e, index, item.id)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>

              <Button variant='primary' disabled={!permissioncontext.add} className='as_btn mt-4' type='submit'>
                Send
              </Button>
              <Button
                variant='primary'
                className='as_btn mt-4 reset-btn'
                onClick={() => {
                  handleClearFiled();
                }}
              >
                Reset
              </Button>
            </Row>
          </form>
          <ToastContainer />
        </div>

        <ImgGalleyModel
          id='ImgGalleyModel'
          show={showImageGalleyModel}
          onHide={() => setShowImageGalleyModel(false)}
          modalHeading={'ImgGalley'}
          size='lg'
          handleSetImageUrl={handleSetImageUrl}
        />
      </div>
    </div>:
    <Notauthorized />
    }
    
    </>
  );
};

export default Whatsupbroadcasting;
