import React, { useEffect, useState, useRef,useContext} from "react";
import { Card, Form, Button, Row, Col, Tab, Nav , Modal,OverlayTrigger,Tooltip } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { useToasts } from "react-toast-notifications";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import APIServicenew from "../../utils/APIGeneralService";
import { PencilSquare, XOctagon } from "react-bootstrap-icons";
import { FaCopy } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formateDate,SlugifyStringWithDash ,setCookies } from "../../utils/Helper";
import FestivalWish from "./festivalWishes";
import FestivalCrop from "./festivalCrop";
import moment from 'moment';
import TinymceEditor from "../../components/Shared/TinymceEditor";
import BannerCrop from "../aspoojas/bannerCrop";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { PujaImgGalleyModel } from "../../components/Shared/pujaImgGalleryModal";
import upimg from '../../imgs/default.jpg';

import usePermissionData from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Notauthorized";

const Festivals = () => {
  document.title = 'Festivals';
  const { addToast } = useToasts();
  const cookies = new Cookies();
  const permissioncontext =usePermissionData("festivals")
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [status , setStatus] = useState("all")
  const [len, setLen] = useState();
  const [festivals, setFestivals] = useState([])
  const [loading, setLoading] = useState()
  const[loader,setLoader]=useState(false)
  const [date, setDate] = useState(new Date())
  const [tabChange, setTabChange] = useState('all')
  const [mode, setMode] = useState({
    status: 'Submit',
    id: null
  })
  const [inputFields, setInputFields] = useState({
    heading: '',
    subHeading: '',
    slug:'',
    content: '',
    // imageType:'',
    bannerImage:"",
    image: ''
  })
  const [allDataCount , setAllDataCount] = useState()
  const [allDataCountadd , setAllDataCountadd] = useState()
  const [errors, setErrors] = useState({});
  const [_id, set_Id] = useState();
  const [showdt, setShowDt] = useState(false);
  const [contentType, setContentType] = useState("english");
  const [allData , setAllData] = useState([])
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
  const accessToken = cookies.get('accessToken')
  const headers = {
    "Content-Type": "multipart/form-data",
    "Authorization": "Bearer " + accessToken
  }
  const headers1 = { Authorization: `Bearer ${accessToken}` };
  let astroUserId = cookies.get("astroUserId");
  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const today =new Date()


    // Image gallery
    const [modalFieldKey, setModalFieldKey] = useState(null);
    const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
    const [clearSel, setClearSel] = useState(false);
    const [rindex, setRindex] = useState();
    const [bannerImage, setBannerImage] = useState('');
    const [bannerImagePreview, setBannerImagePreview] = useState();
    const [festivalImage, setFestivalImage] = useState('');
    const [blogImagePreview, setBlogImagePreview] = useState('');
  
    const handleOpenModal = (fieldKey, index) => {
      setClearSel(true);
      setModalFieldKey(fieldKey);
      setRindex(index);
      setShowImageGalleyModel(true);
    };
  
    const extractPaths = url => {
      const extractPath = singleUrl => {
        const parts = singleUrl.split('cloudfront.net/');
        if (parts.length > 1) {
          return parts[1];
        }
        return singleUrl; 
      };
  
      if (Array.isArray(url)) {
        return url.map(singleUrl => extractPath(singleUrl));
      } else if (typeof url === 'string') {
        return extractPath(url);
      }
  
      return url;
    };
  
    const handleSetImageUrl = (urls, fieldKey) => {
      let extractedPath = extractPaths(urls);
      setClearSel(false);
      if (fieldKey === 'bannerImageFestival') {
        // setBannerImagePreview(urls);
        setInputFields(prev => ({ ...prev, bannerImage: urls }));
        setBannerImage(extractedPath);
        setShowImageGalleyModel(false);
      }
      if (fieldKey === 'blogImageFestival') {
        setInputFields(prev => ({ ...prev, image: urls }));
        setFestivalImage(extractedPath);
        setBlogImagePreview(urls);
      }
    };
    
  const fetchData = async () => {
      setLoading(true);
      const response = await APIServices.get(`festival/festivalList/${contentType}/${offset}/${perPage}?status=${status}`, headers)
      let data = response?.response?.data
      if (response.data?.status) {
      const data = response.data.resData.data
      setFestivals(data)
      setAllDataCount(response.data.resData.total_count)

    } else{
      if(data?.status == 4){
      logout()
     }
    }
      setLoading(false);
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage);
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset);
  };

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!inputFields.heading ||inputFields.heading === "") 
      newErrors.heading = "Please enter heading.";

      // if (!inputFields.subHeading ||inputFields.subHeading === "") 
      // newErrors.subHeading = "Please enter sub heading.";
      
      if (!inputFields.slug ||inputFields.slug === "") 
      newErrors.slug = "Please enter slug.";

      if (!inputFields.content ||inputFields.content === "") 
      newErrors.content = "Please enter content.";
   
      if (!inputFields.image || inputFields.image === "" ) 
      newErrors.file ="Please add image.";

      // if (!inputFields.imageType ||inputFields.imageType === "") 
      // newErrors.imageType = "Please enter image type."; 

      return newErrors;
  }; 


  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('heading', inputFields.heading);
    if(inputFields.subHeading){
      formData.append('subHeading', inputFields.subHeading);
    }
    formData.append('slug', inputFields.slug);
    formData.append('content', inputFields.content);
    formData.append('festivalDate', date? date : new Date());
    // formData.append('bannerImage', inputFields.bannerImage);
    // formData.append('festivalImage', inputFields.image);
    formData.append('bannerImage', bannerImage);
    formData.append('festivalImage', festivalImage);
    // if (inputFields.imageType === "english") {
    //   formData.append("contentEn", true);

    // } else if (inputFields.imageType === "hindi") {
    //   formData.append("contentHi", true);
    // }

    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
    const response = await APIServices.post('festival/addFestival', formData, headers)
    if (response.data.status === true) {
      reset()
      toast.success(response?.data.message);
      setCropImg()
      setBannerCropImg()
      setLoader(false)
      setTabChange("all")
      fetchData()
    } else {
      toast.error(response?.data.message);
      setLoader(false)
    }
    setLoader(false)
  }
  }

  const reset = () => {
    setInputFields({
      heading: '',
      subHeading: '',
      slug:'',
      content: '',
      // imageType:'',
      bannerImage:'',
      image: ''
    })
    setDate('')
    setCropImg()
    setLoader(false)
    // imageRef.current.value = null
    setMode({ status: 'Submit', id: null })
  }
  const handleCloseDt = () => setShowDt(false);
  const handleShowDt = (id) => {
    set_Id(id);
    setShowDt(true);
  };
  const handleDelete = async () => {
    // e.preventDefault()
    const response = await APIServices.delete(`festival/deleteFestival/${_id}`, headers)
    if (response.data.status) {
      let newfestivals = festivals.filter(item => item._id !== _id)
      setFestivals(newfestivals)
      setOffset(0)
      fetchData()
      toast.success(response?.data.message);
      setShowDt(false)
    } else {
      toast.error(response?.data.message);
    }
  }

  const changeToEdit = async (e, id) => {
    e.preventDefault()
    setLoading(true)
    const response = await APIServices.get(`festival/getFestivalById/${id}`, headers)
    if (Array.isArray(response.data) && response.data.length) {
      const {contentEn , contentHi, festivalImage,bannerImage, festivalDate, content,slug, subHeading, heading } = response.data[0]
      // const imagefile = new File([""], festivalImage);
      // const bannerImageFile = new File([""],bannerImage);
      setInputFields({
        heading,
        subHeading,
        slug,
        content,
        // imageType: contentEn === true? "english": contentHi === true ? "hindi" :'',
        image: festivalImage,
        bannerImage:bannerImage
      })
      const date = formateDate(festivalDate)
      setDate(date)
      setMode({ status: 'Update', id })
      } else {
      addToast('Something went Wrong', {
        appearance: 'error',
        autoDismiss: true
      })
     }
     setTabChange('add')
     setLoading(false)
  }

  const handleUpdate = async (e) => {
      e.preventDefault()
      const formData = new FormData();
      formData.append('heading', inputFields.heading);
      formData.append('subHeading', inputFields.subHeading);
      // formData.append('slug', inputFields.slug);
      formData.append('content', inputFields.content);
      formData.append('festivalDate', date ? date : new Date());
  
      // if (inputFields?.image?.type !== '') {
      //   formData.append('festivalImage', inputFields.image);
      // }
      // if (inputFields?.bannerImage?.type !== '') {
      //   formData.append('bannerImage', inputFields.bannerImage);
       
      // }
      if (festivalImage !== '') {
        formData.append('festivalImage', festivalImage);
      }
      if (bannerImage !== '') {
        formData.append('bannerImage', bannerImage);
       
      }

      // if (inputFields.imageType === "english") {
      //   formData.append("contentEn", true);
      //   formData.append("contentHi", false);
  
      // } else if (inputFields.imageType === "hindi") {
      //   formData.append("contentHi", true);
      //   formData.append("contentEn", false);
      // }
      const newErrors = validateInputs();
      setLoader(true)
      if(Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        setLoader(false)
      } else {
      const response = await APIServices.put(`festival/updateFestival/${mode.id}`, formData, headers)
      if (response?.data.status === true) {
        reset()
        toast.success(response?.data.message);
        setCropImg()
        setBannerCropImg()
        setLoader(false)
        setTabChange("all")
        fetchData()
      } else {
        toast.error(response?.data.message);
        setLoader(false)
      }
        setLoader(false)
    }
  }

  const handleclick = async (status, id) => {
    var datas = status === 1 ? 2 : 1;
    const datts = {"status" : datas}
    await APIServices.put(`festival/updateStatus/${id}`, datts, headers1)
      .then((res) => {
        fetchData()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloneHindi = async (id) => {
    const res = await APIServices.post(`festival/hi/cloneById/${id}`, {userId: astroUserId}, headers)
    if(!res || (res && res.status !== 200)){
      toast.error("Something went wrong. Try Again!!", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return;
    }
    const data = res?.data
    if(data && data.status === false){
      toast.warn(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if(data && data.status && data.message){
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const tabChanged = (selectedKey) => {
    setTabChange(selectedKey)
    fetchData()
  }

  useEffect(() => {
    fetchData()
    setLoader(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset ,contentType ,status])

  const[showcropbanner,setShowCropbanner]=useState(false)
  const[bannercropimg,setBannerCropImg]=useState()
  const[cbannerfileimage,setFileCbannerimage]=useState()

  useEffect(()=>{
      setInputFields((prev) => ({ ...prev, bannerImage: cbannerfileimage}))
  },[bannercropimg,cbannerfileimage])


  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()


  useEffect(()=>{
    if(cropimg){ 
    setInputFields((prev) => ({ ...prev, image: cfileimage}))
    }
  },[cropimg,cfileimage])
  
  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if( e === "content") key = e
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  } 

  function onChange(event, name) {
    setInputFields((prev) => ({ ...prev, content: event }))
  
  }


  
  return (
    <>{
      permissioncontext.view?
      <div className="blog_area festival_area">
       <div className="gita_quote_heading">
         <div className="container">
           {tabChange==="all-fwl"?
            <h4 className="hd-list-f left-q" >Festival Wishes <small>({allDataCountadd})</small></h4>
            :
            <h4 className="hd-list-f left-q" >All Festivals <small>({allDataCount})</small></h4>
            }
         </div>
        </div>
      <div className="container">
    <Tab.Container id="FestivalTab"
      activeKey={tabChange}
      onSelect={(selectedKey) => tabChanged(selectedKey)}
    >
      <Row className="pt-5 m-0">
        <Col md={4} lg={3}>
          <Nav
            variant="pills"
            className="flex-column"
          >
            <Nav.Item>
              <Nav.Link className='tabs-color-class' eventKey="all" onClick={()=>{setCropImg();setBannerCropImg(); setErrors("")}}>All Festivals</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link disabled={!permissioncontext.add} className='tabs-color-class' eventKey="add" onClick={()=>{reset(); setCropImg(); setBannerCropImg(); setErrors("")}}>Add Festival</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link disabled={!permissioncontext.view} className='tabs-color-class' eventKey="all-fwl" onClick={()=>{ setCropImg();  setErrors("")} }> Festival Wishes</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col md={8} lg={9} >
          <Tab.Content className="m-0 pb-3">
            <Tab.Pane eventKey="all" className="verticalScoll p-0 m-0 common-Scroll">
              {loading ? <div style={{ marginTop: "15%" }}><Spinner animation="border" /></div> :
                <section className="festival_inner">
                   <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center" style={{justifyContent:"flex-end"}}>
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr right-q"
                            value={status}
                            onChange={(e) => {setStatus(e.target.value);setOffset(0)}}
                          >
                            <option value="all">All</option>
                            <option value="active" >Active</option>
                            <option value= "inActive">InActive</option>

                          </Form.Select>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                             value={contentType}
                             onChange={(e) => {setContentType(e.target.value); setOffset(0)}}
                          >
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <br />
                   <div className="row">
              {festivals?.length ? festivals.map(data => {
                    let Date = formateDate(data.festivalDate);
                    return (
                      <div class="col-md-6 col-lg-6 col-xl-4">
                      <Card key={data._id} className="shadow blog-cards">
                        <div className="position-relative card-header p-0">
                          <Card.Img variant="top" src={data.festivalImage ? data.festivalImage : '/images/default.jpg'} />
                               <div className="icon card-icons">
                               <PencilSquare className={!permissioncontext.edit?"me-2 text-success h5 event-pointer mb-0 disabled-btn":'me-2 text-success h5 event-pointer mb-0'} 
                               onClick={e => { if(permissioncontext.edit){changeToEdit(e, data._id); setBannerImage(""); setFestivalImage("")}}} />
                               {data.status === 1 ? (
                                      <input
                                        type="button"
                                        disabled={!permissioncontext.edit}
                                        className={!permissioncontext.edit?"_active me-1 disabled-btn":'_active me-1'}
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(data.status, data._id)
                                        }
                                      />
                               ) : (
                                      <input
                                        type="button"
                                        className={!permissioncontext.edit?"_inactive me-1 disabled-btn":'_inactive me-1 '}
                                        disabled={!permissioncontext.edit}
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(data.status, data._id)
                                        }
                                      />
                               )}
                               {contentType === "hindi" ? "" :
                                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                                      <FaCopy
                                        className={permissioncontext.add?'me-1 hd-cr':'me-1 hd-cr disabled-btn'}
                                        title="Hindi Clone"
                                        style={{color:"red"}}
                                        size={20}
                                        onClick={(e) => {
                                            if(permissioncontext.add)
                                          handleCloneHindi(data?._id);
                                        }}
                                      />
                                     </OverlayTrigger>}
                               <XOctagon className={permissioncontext.delete?'text-danger h5 event-pointer mb-0':'text-danger h5 event-pointer mb-0 disabled-btn'}
                                // onClick={e => handleDelete(e, data._id)} 
                                onClick={(e) => {
                                    if(permissioncontext.delete)
                                  handleShowDt(data._id);
                                }}
                                />
                                 
                                </div>
                            </div>
                         <Link to={'/festivals/post'} state={{ data, Date }} className="default-link-style">
                          <Card.Body>
                            <h6 className="description font-500 mb-2 text-start">{moment(Date).format('DD MMM, YYYY hh:mm A')}</h6>
                            <h5 className="card-title heading_18 mb-2 text-start">{data.heading}</h5>
                            {/* <p className="card-text-blog  description" dangerouslySetInnerHTML={{ __html: data.content}}></p> */}
                          </Card.Body>  
                        </Link>
                      </Card>
                      </div>
                    )
                  })
                    : <article className="w-100 h-100 d-flex justify-content-center align-items-center h4">
                      No record to show
                    </article>
                  }
                  </div>
                </section>
              }
               <div className="pagination me-0">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination me-0"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}

              />
            </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="add">
              <Form className="add_blog_form">
                <h3 className="mb-3 mx-2"> {`${mode.status === "Update" ? `Edit Festival /${contentType === "english" ?"English":"Hindi"}`: "Add Festival / English"} ` }</h3>
               
                <Form.Group className="mb-3  mx-2" controlId="formBasicEmail">
                  <Form.Label className="required">Heading</Form.Label>
                  <Form.Control type="text" placeholder="Heading..."  name='heading' isInvalid={!!errors?.heading} value={inputFields.heading} onChange={(e) => {setInputFields((prev) => ({ ...prev, heading: e.target.value })); handleChange(e)}} />
                  <Form.Control.Feedback type="invalid">
                  {errors?.heading}
             </Form.Control.Feedback>
                  </Form.Group>

                <Form.Group className="mb-3 mx-2" controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="">Sub Heading</Form.Label>
                  <Form.Control type="text" placeholder="Sub Heading..." name='subHeading' value={inputFields.subHeading} onChange={(e) => {setInputFields((prev) => ({ ...prev, subHeading: e.target.value })); handleChange(e)}} />
                  {/* <Form.Control.Feedback type="invalid">
                  {errors?.subHeading}
             </Form.Control.Feedback> */}
                  </Form.Group>
                
               {mode.status === "Update"? 
                 <Form.Group className="mb-3  mx-2" controlId="formBasicEmail">
                 <Form.Label className="">Slug </Form.Label>
                 <Form.Control type="text" placeholder="slug..." name='slug' value={inputFields.slug} readOnly
               />
            </Form.Group>
              :
                  <Form.Group className="mb-3  mx-2" controlId="formBasicEmail">
                  <Form.Label className="required">Slug</Form.Label>
                  <Form.Control type="text" placeholder="slug..." name='slug' isInvalid={!!errors?.slug} value={inputFields.slug} 
                  onChange={(e) => {setInputFields((prev) => ({ ...prev, slug: e.target.value })); handleChange(e)}}
                  onBlur={(e) => {setInputFields((prev) => ({ ...prev, slug:SlugifyStringWithDash(e.target.value) })); handleChange(e)}}
                   />
              <Form.Control.Feedback type="invalid">
                {errors?.slug}
             </Form.Control.Feedback>
                </Form.Group>
}
                <Form.Group className="mb-3 mx-2" controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="required">Content</Form.Label>
                  {/* <Form.Control as="textarea" className="textAreaHeight" placeholder="Festival Content..." rows={4}  name='content' isInvalid={!!errors?.content} value={inputFields.content} onChange={(e) => {setInputFields((prev) => ({ ...prev, content: e.target.value }));handleChange(e)}} />
                  <Form.Control.Feedback type="invalid">
                  {errors?.content}
                  </Form.Control.Feedback> */}
                  <TinymceEditor 
                  content={inputFields.content}
                  onChange={onChange}
                  handleChange={handleChange}
                  name={"content"}
                 />
                 <span  className="errorVal">{errors?.content}</span>

                </Form.Group>
                <Form.Group className="mb-3 mx-2">
                  <Form.Label>Festival Date</Form.Label>
                  <Form.Control
                   type="date"
                   name="festival_duedate"
                   placeholder="Due date"
                   style={{cursor:"pointer"}}
                   min={formateDate(today)}
                   value={date?date:formateDate(today)}
                   onChange={(e) => setDate(e.target.value)}
                  />
                </Form.Group>
               
                {/* <Form.Group className="mb-3 mx-2" controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="required" column>
                    ImageType
                  </Form.Label>
                  
                    <Form.Select
                      aria-label="Default select example"
                      rows={5}
                      required
                      className="hd-cr"
                      name='imageType' isInvalid={!!errors?.imageType}
                      value={inputFields.imageType} 
                      onChange={(e) => {setInputFields((prev) => ({ ...prev, imageType: e.target.value })); handleChange(e)}}
                    >
                      <option value="">Choose</option>
                      <option value="english">English</option>
                      <option value="hindi">Hindi</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    {errors?.imageType}
                  </Form.Control.Feedback>
                  </Form.Group> */}
                <Form.Group className="mb-3 mx-2">
                  <Form.Label className="">Banner Image</Form.Label>
                  {/* {bannercropimg?"":inputFields?.bannerImage?.name?"":<div>
                    <label htmlFor='upload-festival-image-1'>
                      <img src="/images/imageUpload.jpg" alt="animage" width={50} className="pointerEvent rounded" />
                    </label> */}
                    <Form.Control 
                          id="upload-festival-image-1" name="img" 
                          //  accept="image/png, image/gif, image/jpeg"
                           className="d-none" onClick={(e)=>{
                            handleOpenModal('bannerImageFestival');
                           handleChange(e)}}
                    />
                  {/* </div>} */}
                  
                  {/* <div>{bannercropimg?<img src ={bannercropimg} onClick={()=>setShowCropbanner(true)} style={{width:"100px"}}/>:inputFields.bannerImage?<img src={inputFields.bannerImage.name} onClick={()=>setShowCropbanner(true)} style={{width:"100px"}}/>:<FontAwesomeIcon  className="mx-2" />}</div> */}
                     <img
                        style={{ width: '100px', height: '100px',marginLeft:"5%" }}
                        onClick={e => {
                          handleOpenModal('bannerImageFestival');
                           handleChange(e)
                        }}
                        src={inputFields.bannerImage ? inputFields.bannerImage : upimg}
                        width='100%'
                        alt=''
                      />
                </Form.Group>
                <Form.Group className="mb-3 mx-2">
                  <Form.Label className="required">Festival Image</Form.Label>
                  {/* {cropimg?"":inputFields.image?"":<div>
                    <label htmlFor='upload-festival-image'>
                    <img src="/images/imageUpload.jpg" alt="animage" width={50} className="pointerEvent rounded" />
                    </label> */}
                  <Form.Control id="upload-festival-image" name="file" 
                        // accept="image/png, image/gif, image/jpeg"
                        className="d-none"  isInvalid={!!errors?.file}
                          onClick={(e)=>{
                          handleOpenModal('blogImageFestival');
                           handleChange(e)}}
                    />
                          <img
                            // className="imgs"
                            name="file"
                            style={{ width: '100px', height: '100px', marginLeft:"3%" }}
                            onClick={e => {
                              handleOpenModal('blogImageFestival');
                              handleChange(e);
                            }}
                            src={inputFields.image ? inputFields.image : upimg}
                            width='100%'
                            alt=''
                          />
                  <Form.Control.Feedback type="invalid">
                    {errors?.file}
                </Form.Control.Feedback>
                {/* </div>} */}
                  
                  
               {/* <div>{cropimg?<img src ={cropimg} onClick={()=>setShowCrop(true)} style={{width:"100px"}}/>:inputFields.image?<img src={inputFields.image.name} onClick={()=>setShowCrop(true)} style={{width:"100px"}}/>:<FontAwesomeIcon className="mx-2" />}</div> */}
                  
                         

                </Form.Group>

                <div className="text-center pb-2">
                {loader?<Button variant="primary" type="submit" className=" as_btn" >
                {
                  mode.status === "Update" ? "Processing...": "Processing..."
                }
               </Button>:<Button variant="primary" type="submit" className=" as_btn" onClick={(e) => {mode.status === 'Update' ? handleUpdate(e) : handleSubmit(e); }}>
                {
                  mode.status === "Update" ? "Edit": "Add"
                }
               </Button>}
               {mode.status === "Update" ? "":<Button variant="primary" type="submit" className=" as_btn mx-2 reset-btn" onClick={(e) => {
                    e.preventDefault()
                    reset()
                  }} >
                    Reset
                  </Button>}
                  
                </div>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="all-fwl" className="verticalScoll p-0 m-0 common-Scroll">
            <FestivalWish setAllDataCountadd={setAllDataCountadd} 
                canAddWishes={permissioncontext.add}
                canEditWishes={permissioncontext.edit}
                canDeleteWishes={permissioncontext.delete}
                canActionWishes={permissioncontext.edit}
            />
              </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container >

       <FestivalCrop 
       showcrop={showcrop}
       setShowCrop={setShowCrop}
       setCropImg={setCropImg}
       cropimg={cropimg}
       
       setFileCimage={setFileCimage}/>

       <PujaImgGalleyModel
          id='ImgGalleyModel'
          show={showImageGalleyModel}
          onHide={() => setShowImageGalleyModel(false)}
          modalHeading={'ImgGalley'}
          size='lg'
          handleSetImageUrl={handleSetImageUrl}
          fieldKey={modalFieldKey}
          setClearSel={setClearSel}
          clearSel={clearSel}
        />
   
     <BannerCrop 
       showcropbanner={showcropbanner}
       setShowCropbanner={setShowCropbanner}
       setBannerCropImg={setBannerCropImg}
       bannercropimg={bannercropimg}
       cbannerfileimage={cbannerfileimage}
       setFileCbannerimage={setFileCbannerimage}/> 
   
   
       <Modal
       centered
           show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="success_btn"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    {/* <ToastContainer /> */}
    </div>
    </div>:
   <Notauthorized />
    }
    
    </>
  );
}


export default Festivals;
