import React, { useContext ,useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Header from "./components/Shared/Header";
import { UserContext } from "./context/UserContextProvider";


import Homepage from './pages/homepage'
import Login from "./pages/login";
import { AddUsers } from "./pages/userManagment/addusers";
import UserList from "./pages/userManagment/userlist";
import FacebookAnalytics from "./pages/analytics/facebookAnalytics";
import InstagramAnalytics from "./pages/analytics/instagramAnalytics";
import TwitterAnalytics from "./pages/analytics/twitterAnalytics";
import YoutubeAnaytics from "./pages/analytics/youtubeAnalytics";
import DailyHoroscope from "./pages/horoscope/dailyHoroscope";
import WeeklyHoroscope from "./pages/horoscope/weeklyHoroscope";
import MonthlyHoroscope from "./pages/horoscope/monthlyHoroscope";
import YearlyHoroscope from "./pages/horoscope/yearlyHoroscope";
import Blogs from "./pages/blogs";
import BlogPost from "./pages/blogs/post";
import Feeds from "./pages/feeds";
import AstroWatch from "./pages/astrowatch";
// import Watch from "./pages/watch";
import Watch from "./pages/astrowatch/watch"
import Books from "./pages/books";
import Festivals from "./pages/festivals";
import FestivalPost from "./pages/festivals/post";
import FestivalWishes from "./pages/festivals/festivalWishes"
import Chat from "./pages/chat";
import InstaPosts from "./pages/InstaPosts";
import YoutubePosts from "./pages/YoutubePosts";
import Calender from "./pages/calender";
import CalendarEvent from "./pages/CalendarEvent";
import CalendarContent from "./pages/CalendarContent";
import { AddQuestion } from "./pages/addQuestion";
import GitaQuotes from "./pages/gitaQuotes";
import SpritualQuotes from "./pages/spritualQuotes";
import ChanakyasQuotes from "./pages/chanakyasQuotes";
import Aarti from "./pages/aarti";
import Reels from "./pages/reels";
import Chalisa from "./pages/chalisa";
import Mantra from "./pages/mantra";
import Bhajan from "./pages/bhajan";
import Panchang from "./pages/panchang";
import Questions from "./pages/questions";
import AstroTemple from "./pages/astrotemple";
import AstroPooja from "./pages/aspoojas/astroPooja";
import AstroGuru from "./pages/astroGuru";
import BulkNotificaton from "./pages/notification/bulkNotification";
import BulkNotificatonList from "./pages/notification/notificationList";
import Whatsupbroadcasting from "./pages/notification/whatsupbroadcasting";
import Campaign from "./pages/campaign";
import AddResponders from "./pages/responders";
import Chatv2 from "./pages/chatv2";
import BlockedUser from "./pages/BlockedUsers";
import Label from "./pages/label";
import Banner from "./pages/banner";
import TodaysThought from "./pages/todaysThought";
import InspiringQuotes from "./pages/inspiringQuotes";
import MorningQuotes from "./pages/morningQuotes";
import ManagePuja from "./pages/aspoojas/managePuja";
import MeditationYogaAndExercise from "./pages/meditationYogaAndExercise";
import DevLok from "./pages/devLok";
import SiddhaPurush from "./pages/siddhaPurush";
import AstrologyAdvice from "./pages/astrologyAdvice";
import PujaFromPanditJi from "./pages/pujaFromPandit";
import ExportUsers from "./pages/userManagment/ExportUsers";
import AstroDailyNotification from "./pages/astroDailyNotification";
import MasterGod from "./pages/god/mastergod";
import GodImages from "./pages/god/godImages";
import MandirAllTabs from "./pages/mandir_all_tabs";
import MediaGallery from "./pages/mediaGallery";
import Diwaliuserlist from "./pages/diwaliuserlist";
import Mankibatlist from "./pages/mankibatlist";
import SidhaPurushGallery from "./pages/sidhaPurushGallery";
import PujaTestimonial from "./pages/pujaTestimonial";
import PujaAllBooking from "./pages/pujaAllBooking";
import Callhistory from "./pages/callhistory";
import Whatsapptemplate from "./pages/whatsapptemplate";
import Whatsapptemplatechange from "./pages/whatsapptemplatechange";
import Whatsappinteractivereply from "./pages/whatsappinteractivereply";
import PujaFeedback from "./pages/pujaFeedback"
import PujaSuggestionList from "./pages/pujaSuggestionList";
import ConnectAstroUser from "./pages/connectAstroUserCall";
import AddEditPoojaForm from "./pages/aspoojas/addEditPoojaForm";
import PujaPlanSelectionImgMaster from "./pages/pujaPlanSelectionImgMaster";
import ContactInquiries from "./pages/contactInquires/contactInquires";
import ContactDetails from "./pages/contactInquires/contactDetails";
import UserListNew from "./pages/userListNew";
import PujaFeedbackQuest from "./pages/pujaFeedbackQuest";
import PujaMethodMaster from "./pages/pujaMethodMaster";
import ResponderCallhistory from "./pages/respondercallhistrory";
import CompletePujaList from "./pages/completePujaList";
import WhatsAppAnalytics from "./pages/notification/whatsAppAnalytics";
import BroadCastingHistory from "./pages/notification/broadCastingHistory";
import ChadhawaMethod from "./pages/chadhawaMethod";
import PujaPlanProcess from "./pages/pujaPlanProcess";
import PujaPandit from "./pages/aspoojas/pujaPandit";
import AddEditPandit from "./pages/aspoojas/addEditPandit";
import ChadhawaAddEdit from "./pages/chadhawa/chadhawaAddEdit";
import ChadhawaList from "./pages/chadhawa/chadhawaList";
import TempleList from "./pages/chadhawa/templeList";
import TempleAddEdit from "./pages/chadhawa/templeAddEdit";
import AddEditCategory from "./pages/blogs/addEditCategory";
import CategoryList from "./pages/blogs/categoryList";
import ChadhwaList from "./pages/chadhawa/chadhwalist";
import ManokamnaList from "./pages/chadhawa/manokamnaList";
import ReportsListing from "./pages/manageReports/reportsListing";
import ReportsAddEdit from "./pages/manageReports/reportsAddEdit";
import ReportsInquiry from "./pages/reportsInquiry";
import PujaExpensesList from "./pages/pujaExpensesList";
import PanditOfPujaDetails from "./pages/aspoojas/panditOfPujaDetails";
import AreaOfProblem from "./pages/areaOfProblem";
import AddEditAreaOfProblem from "./pages/addEditAreaOfProblem";
import ChadhawaDetails from "./pages/chadhawa/chadhawaDetails";
import ManokamnaDetails from "./pages/chadhawa/manokamnaDetails";
import PujaPlanSelectionMaster from "./pages/pujaPlanSelectionMaster";
import CareerInquiries from "./pages/careerInquiries/careerInquiries";
import CareerDetails from "./pages/careerInquiries/careerDetails";
import Dashboard from "./pages/dashboard";
import ReportsProcess from "./pages/manageReports/reportsProcess";

const RequireAuth = ({ children }) => {
  const { token } = useContext(UserContext);
  if (!token) {
    return <Navigate to={`/login?redirect=${window.location.pathname}`} />;
  }
  return <Outlet />;
};

const AppRouter = () => {

  useEffect(() => {
    document.title = "Login"
  }, [window.location.pathname === "/login"]);

  return (
    <>
      <Header></Header>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<Homepage />}></Route>
        <Route path="/" element={<RequireAuth />}>
          <Route path="/addusers" element={<AddUsers />}></Route>
          <Route path="/userlist" element={<UserList />}></Route>
          <Route path="/analytics/facebook" element={<FacebookAnalytics />}></Route>
          <Route path="/mandir_all_tabs" element={<MandirAllTabs/>}></Route>
          <Route path="/analytics/instagram" element={<InstagramAnalytics />}></Route>
          <Route path="/analytics/twitter" element={<TwitterAnalytics />}></Route>
          <Route path="/analytics/youtube" element={<YoutubeAnaytics />}></Route>
          <Route path="/horoscope/daily" element={<DailyHoroscope />}></Route>
          <Route path="/horoscope/weekly" element={<WeeklyHoroscope />}></Route>
          <Route path="/horoscope/monthly" element={<MonthlyHoroscope />}></Route>
          <Route path="/horoscope/yearly" element={<YearlyHoroscope />}></Route>
         
            <Route path="/blogs" element={<Outlet />}>
              <Route index element={<Blogs />} />
              <Route path="post" element={<BlogPost />} />
            </Route>
         
         
            <Route path="/reels" element={<Reels />}></Route>
        
            <Route path="/mediaGallery" element={<MediaGallery/>}></Route>
      
        
            <Route path="/Feeds" element={<Feeds />}></Route>
        
          <Route path="/books" element={<Books />}></Route>
        
            <Route path="/astrowatch" element={<Outlet />}>
              <Route index element={<AstroWatch />} />
              <Route path="watch" element={<Watch />} />
          </Route>
        
            <Route path="/festivals" element={<Outlet />}>
              <Route index element={<Festivals />} />
              <Route path="post" element={<FestivalPost />} />
              <Route path="festival-wishes" element={<FestivalWishes />} />
            </Route>
        
          {/* <Route path="/chat" element={<Chat />}></Route> */}
          <Route path="/whatsAppLeads" element={<Chatv2 />}></Route>
          <Route path="/insta_posts" element={<InstaPosts />}></Route>
          <Route path="/youtube_posts" element={<YoutubePosts />}></Route>
          <Route path="/calender-event" element={<Outlet />}>
            <Route index element={<Calender />} />
            <Route path="event" element={<CalendarEvent />} />
            <Route path="content" element={<CalendarContent />} />
          </Route>
          <Route path="/addquestion" element={<AddQuestion />}></Route>
         
            <Route path="/gitaquotes" element={<GitaQuotes />}></Route>
        
            <Route path="/spiritualquotes" element={<SpritualQuotes />}></Route>
         
            <Route path="/chanakyasquotes" element={<ChanakyasQuotes />}></Route>
        
            <Route path="/todaysThought" element={<TodaysThought/>}></Route>
         
            <Route path="/inspiringQuotes" element={<InspiringQuotes/>}></Route>
         
            <Route path="/morningQuotes" element={<MorningQuotes/>}></Route>
        
          <Route path="/aarti" element={<Aarti />}></Route>
          <Route path="/chalisa" element={<Chalisa />}></Route>
          <Route path="/mantra" element={<Mantra />}></Route>
          <Route path="/bhajan" element={<Bhajan />}></Route>
          <Route path="/panchang" element={<Panchang />}></Route>
          <Route path="/questions" element={<Questions />}></Route>
          <Route path="/mysteriousTemple" element={<AstroTemple />}></Route>
          <Route path="/puja" element={<AstroPooja/>}></Route>
          <Route path="/shared-puja" element={<ManagePuja/>}></Route>
          <Route path="/astrogurus" element={<AstroGuru/>}></Route>
          <Route path="/bulknotificaton" element={<BulkNotificaton/>}></Route>
          <Route path="/bulknotificatonlist" element={<BulkNotificatonList/>}></Route>
          <Route path="/whatsupbroadcasting" element={<Whatsupbroadcasting/>}></Route>
          <Route path="/broadCastingHistory" element={<BroadCastingHistory/>}></Route>
          <Route path="/whatsAppAnalytics" element={<WhatsAppAnalytics/>}></Route>
          <Route path="/campaign" element={<Campaign/>}></Route>
          <Route path="/responders" element={<AddResponders/>}></Route>
          <Route path="/label" element={<Label/>}></Route>
          <Route path="/blockedusers" element={<BlockedUser/>}></Route>
          <Route path="/banner" element={<Banner/>}></Route>
          <Route path="/meditationYogaAndExercise" element={<MeditationYogaAndExercise/>}></Route>
          <Route path="/devLok" element={<DevLok/>}></Route>
          <Route path="/siddhaPurush" element={<SiddhaPurush/>}></Route>
          <Route path="/astrologyAdvice" element={<AstrologyAdvice/>}></Route>
          <Route path="/pujaFromPanditJi" element={<PujaFromPanditJi/>}></Route>
          <Route path="/export-users" element={<ExportUsers/>}></Route>
          <Route path="/astroDailyNotification" element={<AstroDailyNotification/>}></Route>
          <Route path="/mastergod" element={<MasterGod/>}></Route>
          <Route path="/godImages" element={<GodImages/>}></Route>
          <Route path="/diwaliuserslist" element={<Diwaliuserlist/>}></Route>
          <Route path="/mankibatlist" element={<Mankibatlist/>}></Route>
          <Route path="/sidhaPurushGallery/:id" element={<SidhaPurushGallery/>}></Route>
          <Route path="/pujaTestimonial" element={<PujaTestimonial/>}></Route>
          <Route path="/pujaallbooking" element={<PujaAllBooking/>}></Route>
          <Route path="/pujaPandit" element={<PujaPandit/>}></Route>
          <Route path="/completePujaList" element={<CompletePujaList/>}></Route>
          <Route path="/chadhawaAddEdit" element={<ChadhawaAddEdit/>}></Route>
          <Route path="/chadhawaList" element={<ChadhawaList/>}></Route>
          <Route path="/chadhawaDetails" element={<ChadhawaDetails/>}></Route>
          <Route path="/manokamnaDetails" element={<ManokamnaDetails/>}></Route>
          <Route path="/careerInquiries" element={<CareerInquiries/>}></Route>
          <Route path="/careerDetails/:id" element={<CareerDetails/>}></Route>
          <Route path="/dashboard" element={<Dashboard/>}></Route>
          <Route path="/reportsProcess" element={<ReportsProcess/>}></Route>
          
          
          <Route path="/chadhwaList" element={<ChadhwaList/>}></Route>
          <Route path="/manokamanaList" element={<ManokamnaList/>}></Route>
          <Route path="/reportsListing" element={<ReportsListing/>}></Route>
          <Route path="/reportsAddEdit" element={<ReportsAddEdit/>}></Route>
          <Route path="/reportsInquiry" element={<ReportsInquiry/>}></Route>
          <Route path="/areaOfProblem" element={<AreaOfProblem/>}></Route>
          <Route path="/addEditAreaOfProblem" element={<AddEditAreaOfProblem/>}></Route>
          <Route path="/pujaExpensesList" element={<PujaExpensesList/>}></Route>
          <Route path="/panditOfPujaDetails" element={<PanditOfPujaDetails/>}></Route>
          

          <Route path="/templeList" element={<TempleList/>}></Route>
          <Route path="/templeAddEdit" element={<TempleAddEdit/>}></Route>
          <Route path="/chadhawaMethod" element={<ChadhawaMethod/>}></Route>
          <Route path="/pujaPlanProcess" element={<PujaPlanProcess/>}></Route>
          <Route path="/callhistory" element={<Callhistory/>}></Route>
          <Route path="/whatsapptemplate" element={<Whatsapptemplate/>}></Route>
          <Route path="/whatsapptemplatechange" element={<Whatsapptemplatechange/>}></Route>
          <Route path="/whatsappinteractivereply" element={<Whatsappinteractivereply/>}></Route>
          <Route path="/pujaFeedback" element={<PujaFeedback/>}></Route>
          <Route path="/pujaSuggestionList" element={<PujaSuggestionList/>}></Route>
          <Route path="/connectAstroUser" element={<ConnectAstroUser/>}></Route>
          <Route path="/addEditPoojaForm" element={<AddEditPoojaForm/>}></Route>
          <Route path="/addEditPandit" element={<AddEditPandit/>}></Route>
          <Route path="/pujaPlanSelectionImgMaster" element={<PujaPlanSelectionImgMaster/>}></Route>
          <Route path="/contactInquiries" element={<ContactInquiries/>}></Route>
          <Route path="/contactDetails/:id" element={<ContactDetails/>}></Route>
          <Route path="/userListNew" element={<UserListNew/>}></Route>
          <Route path="/pujaFeedbackQuest" element={<PujaFeedbackQuest/>}></Route>
          <Route path="/pujaMethodMaster" element={<PujaMethodMaster/>}></Route>
          <Route path="/responderCallhistory" element={<ResponderCallhistory/>}></Route>
          <Route path="/addEditCategory" element={<AddEditCategory/>}></Route>
          <Route path="/categoryList" element={<CategoryList/>}></Route>
          <Route path="/PujaPlanSelectionMaster" element={<PujaPlanSelectionMaster/>}></Route>

        </Route>

      </Routes>
    </>
  );
};

export default AppRouter;