import React, { useEffect, useState } from "react";
import APIServicenew from "../utils/APIGeneralService";
import { getCookies ,setCookies } from "../utils/Helper";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
const UserContext = React.createContext();

const UserContextProvider = (props) => {
  const [token, setToken] = useState(getCookies("accessToken"));
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [permissioncontext,setPermissionscontext]=useState([])
  const [menuItem, setMenuItem] = useState([]);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)
  const APIServicesastro = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);

  useEffect(() => {
    (async () => {
      const userId = getCookies("astroUserId")
      if (userId && token && token != "null") {
        const userRes = await APIServices.get(`admin/adminDetail/${userId}`, {
          'x-access-token': token,
        });
        if (userRes && userRes.status === 200) {
          let userInfo = userRes?.data?.data
          if (!userInfo?.marketingrole) {
            // If marketingrole is not present, log out the user
            refreshToken(null);
            setCookies("accessToken", null);
            navigate("/");
          } else {
            setUser(userInfo);
            getMenuItem();
          }
        }else if(userRes.response.data.status === 4){
        refreshToken(null);
        setCookies("accessToken", null);
        navigate("/");

        }
      } else if (token === "null") {
        refreshToken(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  /* 
    If user logoff or login, update token from child component
  */
  const refreshToken = (newToken) => {
    setToken(newToken);
  };
  const getMenuItem = async () => {
    let res = await APIServicesastro.get(`admin/getmarketingDashboardMenu`, { Authorization: `Bearer ${token}` });

    try {
      if (res?.status === 200) {
        if (res.data?.status === true) {
          setPermissionscontext(res.data.data);
          setMenuItem(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      } else {
        toast.error(res.data.message);
        console.error('Error: ', res.data.message);
      }
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  };


  return (
    <UserContext.Provider value={{ user, token, refreshToken ,setPermissionscontext,permissioncontext,menuItem}}>
      {props.children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };

