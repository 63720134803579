import React, { useEffect, useState ,useContext} from "react";
import { Card, Button, Modal, Form ,Col,Row,} from 'react-bootstrap';
import moment from "moment";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../components/Shared/Sidebar";
import bimg from "../../src/imgs/banner_background.png";
import upimg from "../../src/imgs/default.jpg"
import Cookies from 'universal-cookie';
import APIServicenew from "../utils/APIGeneralService";
import FestivalCrop from "./festivals/festivalCrop";
import { getBaseURL ,setCookies} from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

const Books = () => {
  document.title = 'Books';
  const permissioncontext =usePermissionData("mysteriousTemple")

  const [book, setBook] = useState([])
  const [allData , setAllData] = useState([])
  const [loading, setLoading] = useState()
  const [file, setFile] = useState()
  const [file1, setFile1] = useState("")
  const [selectedId, setSelectedId] = useState()
  const [show, setShow] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [ type , setType] = useState("english")
  const [ imageType , setImageType] = useState ("")
  const [title, setTitle] = useState()
  const [ content , setContent] = useState()
  const [contentUrl, setContentUrl] = useState()
  const [category, setCategory] = useState("")
  const [tags, setTags] = useState("")
  const [page , setPage ] = useState()
  const [newData, setNewData] = useState()
  const [img, setImg] = useState("")
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [progress ,setProgress] = useState(0) 
  const [errors, setErrors] = useState({});
  const [loader,setLoader]=useState(false)
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const [allDataCount , setAllDataCount] = useState()
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const onImageChange = (event) => {
   
    setFile(event);
      setFile1(event)
    //  i have no idea so i am not removing this
    // if (event.target.files && event.target.files[0]) {
    //   setFile(URL.createObjectURL(event.target.files[0]));
    //   setFile1(event.target.files[0])
    // }
  }

  const handleClose = () =>{ 
    setShow(false)
    setErrors("")
    setImageType("")
    setProgress(0)
  }
  const handleCloseDt = () => setShowDt(false)

  const handleShow = async(item) => {
    setShow(true)
    setFile('')
    setFile1('')
    setSelectedId(item?._id)
    setTitle(item?.title)
    setContent(item?.content)
    setContentUrl(item?.contentUrl)
    setCategory(item?.category??"")
    setTags(item?.tags??"")
    setImg(item?.file)
    if (item?.contentEn === true) {
      setImageType("english")
      }else if( item?.contentHi === true){
      setImageType("hindi")
      }
    setPage(item?.pages)  
    setProgress(0)
  }

 
  const handleShowDt = (id) => {
    setSelectedId(id)
    setShowDt(true)
  }

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!title ||title === "") 
      newErrors.title = "Please enter title.";

      if (!content ||content === "") 
      newErrors.content = "Please enter content.";

      if (file1 === "" && (!img || img === "")) 
      newErrors.file = "Please add image.";

      if (!imageType ||imageType === "") 
      newErrors.imageType = "Please enter content type."; 

     if (!page ||page === "") 
      newErrors.page = "Please enter pages.";

      return newErrors;
  }; 

  
  async function handleEdit() {
    const formData = new FormData();
    if (file1) {
      formData.append("file", file1)
    }
    if(typeof contentUrl === "object"){
    formData.append("contentUrl", contentUrl)
    }
    formData.append("title", title)
    formData.append("content", content)
    formData.append("category", category)
    formData.append("tags", tags)
    formData.append("pages", page)
    if (imageType === "english") {
      formData.append("contentEn", true);
      formData.append("contentHi", false);

    } else if (imageType === "hindi") {
      formData.append("contentHi", true);
      formData.append("contentEn", false);
    }
    formData.append("userId", astroUserId)

    const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else {
    let response = await APIServices.put(`astroBook/editOne/${selectedId}`, formData, headers,(progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted)
  })
    if (response?.status === 200) {
      console.log(response?.data)
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShow(false);
        setLoader(false)
        toast.success(apiData?.message);
        setCropImg();
        updateFile();
        setFile1(null)
      }else{
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }

  const fetchData = async () => {
    setLoading(true)
    let response = await APIServices.get(`astroBook/getAll/${type}/${offset}/${perPage}`, headers)
    let data = response?.response?.data
   
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setBook(apiData?.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false)
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  }




  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage)
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset)
  }

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroBook/updateFile`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData()
      }
    }
  }

  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroBook/updateStatus/${id}`, formData, headers)
      .then((res) => {
        updateFile()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handledelete = async () => {
    let res = await APIServices.delete(`astroBook/findByIdAndremove/${selectedId}`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      setShowDt(false)
      setOffset(0)
      if (apiData?.status) {
        toast.success(apiData?.message);
        updateFile()
      }
    }
  }

  async function handleCreate() {

    const formData = new FormData();
    formData.append("title", title)
    formData.append("content", content)

    formData.append("category", category)
    formData.append("tags", tags)
    formData.append("file", file1)
    formData.append("pages" ,page )
    if(typeof contentUrl === "object"){
      formData.append("contentUrl", contentUrl)
    }
    if (imageType === "english") {
      formData.append("contentEn", true);

    } else if (imageType === "hindi") {
      formData.append("contentHi", true);
    }
    formData.append("userId", astroUserId)
    const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else {
    let response = await APIServices.post(`astroBook/upload`, formData, headers,(progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted)
  })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShow(false)
        setLoader(false)
        toast.success(apiData?.message);
        setCropImg();
        updateFile();
      }else{
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }


  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  const renderFormModal = () => {
   return(
    <Modal show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg()}}>
    <Modal.Header closeButton>
      <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Book`}</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <Form >
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

      <Form.Label className="required" column sm="3">

            Title</Form.Label>
            <Col sm="9">
            <Form.Control
            type="text"
            placeholder="Title..."
            name='title'
            defaultValue={title}
            isInvalid={!!errors?.title}
            className="form-control "
            onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
          />
           <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

        <Form.Label className="required" column sm="3">

            Content</Form.Label>
            <Col sm="9">
            <Form.Control
            type="text"
            name='content'
            isInvalid={!!errors?.content}
            defaultValue={content}
            placeholder="Content..."
            className="form-control "
            onChange={(e) => {setContent(e.target.value);handleChange(e)}}
          />
           <Form.Control.Feedback type="invalid">
                {errors?.content}
           </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

        <Form.Label className="" column sm="3">

            Upload Pdf File</Form.Label>
            <Col sm="9">
            <Form.Control
            type="file"
            name='contentUrl'
            // defaultValue={contentUrl}
            // isInvalid={!!errors?.contentUrl}
            accept="application/pdf"
            // placeholder=" Content Url..."
            className="form-control "
            onChange={(e) =>{ setContentUrl(e.target.files[0]);handleChange(e)}}
          />
          {/* <p>{contentUrl}</p> */}
             {/* <Form.Control.Feedback type="invalid">
                {errors?.contentUrl}
           </Form.Control.Feedback> */}
           <small>Maximum File Size 45MB</small>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

        <Form.Label column sm="3" className="">

            Category</Form.Label>
            <Col sm="9">
            <Form.Control
            type="text"
            name='category'
            defaultValue={category}
            // isInvalid={!!errors?.category}
            placeholder="Category..."
            className="form-control "
            onChange={e => {setCategory(e.target.value);}}
          />
           {/* <Form.Control.Feedback type="invalid">
                {errors?.category}
           </Form.Control.Feedback> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

        <Form.Label column sm="3" className="">

            Tags</Form.Label>
            <Col sm="9">
            <Form.Control
            type="text"
            name='tags'
            defaultValue={tags}
            // isInvalid={!!errors?.tags}
            placeholder="Tag..."
            className="form-control "
            onChange={e => {setTags(e.target.value);}}
          />
           {/* <Form.Control.Feedback type="invalid">
                {errors?.tags}
           </Form.Control.Feedback> */}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

        <Form.Label className="required" column sm="3">

            Image</Form.Label>
          <Col sm="9">
          <label id="labimg">
          {cropimg?<img 
          // crossOrigin="anonymous" 
          className="imgs" 
          src={cropimg}
          onClick={()=>setCropImg()}
          width="100%" alt="" />:<img 
          className="imgs" 
          // src={file ? file : upimg}
          src={selectedId ? img:file?file:upimg}

          width="100%" alt="" />}


          <Form.Control
          // type="file"
          className="form-control "
          isInvalid={!!errors?.file}
          name="file"
          onClick={(e)=>{setShowCrop(true);handleChange(e)}}
         
          size="40"
          accept="image/png, image/gif, image/jpeg"
          id="inputds"
        />
          <Form.Control.Feedback type="invalid">
            {errors?.file}
           </Form.Control.Feedback>
          </label>
          </Col>

        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
            Content Type
          </Form.Label>
          <Col sm="9">
            <Form.Select
              aria-label="Default select example"
              required
              className="hd-cr"
              name ="imageType"
              defaultValue={imageType}
              isInvalid={!!errors?.imageType}
              onChange={(e) =>{ setImageType(e.target.value);handleChange(e)}}
            >
              <option value="">Choose</option>
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors?.imageType}
              </Form.Control.Feedback>
          </Col>
          {/* <Col sm="4"></Col> */}
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">

         <Form.Label className="required" column sm="3">
         
               Pages</Form.Label>
               <Col sm="9">
               <Form.Control
               type="text"
               placeholder="Pages..."
               defaultValue={page}
               isInvalid={!!errors?.page}
               name="page"
               className="form-control "
               onChange={(e) => {setPage(e.target.value);handleChange(e)}}
             />
              <Form.Control.Feedback type="invalid">
                {errors?.page}
              </Form.Control.Feedback>
             </Col>
            
           </Form.Group>
      </Form>

    </Modal.Body>
    <Modal.Footer>
    {loader?<Button variant='primary' className='as_btn' >
        {`${selectedId ?progress === 100?`Processing...`:`Uploading(${progress}%)...` : progress === 100?`Processing...`:`Uploading(${progress}%)...`}`}
        
      </Button>:<Button variant='primary' className='as_btn' onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
      {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
      <Button variant="secondary"
       className="as_btn"
      onClick={()=>{handleClose(); setCropImg()}}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
   )
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset ,type])


useEffect(()=>{
  if(cfileimage){
    onImageChange(cfileimage)
  }
},[cropimg,cfileimage])


  return (
    <>
    {
      permissioncontext.view?
<div className="main aarti_section">

        
<div className="gita_quote_heading">
     <div className="container d-flex align-items-center justify-content-between">
     <h4 className="hd-list-f left-q" >Books <small>({allDataCount})</small></h4>
        <div className="_btn">
        <Button
            variant="primary"       
             disabled={ !permissioncontext.add }
            className="by-btn as_btn"
            onClick={()=>updateFile(true)}
          >
            Update File
          </Button>
          <Button
          className="by-btn as_btn me-0"
         disabled={!permissioncontext.add}
            variant="primary"
            onClick={handleShow}
          >
            Add Books</Button>
        </div>
     </div>
  </div>
  <div className="aarti_inner pt-5">
     <div className="container">
      <div className="row">
        <div className="col-md-4 col-lg-3"> 
           <Sidebar />
        </div>
        <div className="col-md-8 col-lg-9"> 
            {loading ? <div className="center"><Spinner animation="border" /></div> :
            <>
             <div className="row">
                <div className="container">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="hd-list-f left-q"></h4>
                    <Form.Select
                      aria-label="categories"
                      className=" w-25 astroWatch-select-hover hd-cr"
                      value={type}
                      onChange={(e) =>{ setType(e.target.value); setOffset(0)}}
                    >
                      <option value="english">English</option>
                      <option value="hindi">Hindi</option>

                    </Form.Select>
                  </div>
                </div>
              </div>
      
              <div className="row mt-3">
                {
                  book?.map((item) => 
                  <div className="col-md-6 col-lg-4">
                    <div className="card mb-4"
                      key={item?._id}
                    >
                      <div className="card-header">
                      <img
                          // crossOrigin="anonymous"
                          src={(item?.file) ? item?.file : bimg}
                          className="card-img-top"
                          alt="img"
                        />
                          <div className="icon" >
                          <FaEdit
                            className={permissioncontext.edit?"me-2 hd-cr":"me-2 hd-cr disabled-btn"}
                            size={20}
                            onClick={(e) => {
                                  if(permissioncontext.edit)
                                      handleShow(item)
                            }}
                          />
                          <FaTrash
                            className={permissioncontext.delete?"dt-btn hd-cr":"dt-btn hd-cr disabled-btn"}
                            size={20}
                            onClick={e => {
                                  if(permissioncontext.delete)
                                      handleShowDt(item._id)
                            }}
                          />
                            {item.status === true ? (
                                <input
                                  type="button"
                                  disabled={!permissioncontext.edit}
               className={!permissioncontext.edit?"_active me-1 disabled-btn":'_active me-1'}
                                  style={{fontSize:"8px", borderRadius:"15px"}}
                                  value="Active"
                                  onClick={() =>
                                    handleclick(item.status, item._id)
                                  }
                                />
                              ) : (
                                <input
                                  type="button"
                                  disabled={!permissioncontext.edit}
               className={!permissioncontext.edit?"_inactive  disabled-btn":'_inactive '}
                                  style={{fontSize:"8px", borderRadius:"15px"}}
                                  value="Inactive"
                                  onClick={() =>
                                    handleclick(item.status, item._id)
                                  }
                                />
                              )}
                        </div>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title heading_18 mb-2">{item.title}
                        <span>
                        <a rel="noopener" target = "_blank" href={item.contentUrl}>
                          PDF
                        </a>
                      </span>
                        </h5>
                        <p className="card-text description">
                        {item?.content}
                       </p>
                       <small className="text-muted description" >
                        pages({item.pages})
                        </small>
                      </div>
                     
                    </div>
                    </div>
                  )
              }
              </div>
              
              </>
            }
            <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  // pageCount={pageCount}
                  pageCount={Math.ceil(allDataCount / perPage)}
                  previousLabel="< Previous"
                  renderOnZeroPageCount={null}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={offset/perPage}

                />
              </div>
         </div>
  </div>
  </div>
  </div>
</div>:
 <Notauthorized />
    }
  
      
      {renderFormModal()}

      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt}>
         <Modal.Header className="modal-header-hd" >
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <FestivalCrop 
      showcrop={showcrop}
      setShowCrop={setShowCrop}
      setCropImg={setCropImg}
      cropimg={cropimg}
      
      setFileCimage={setFileCimage}/>
   
      
    </>
  );
}


export default Books;
