import { useEffect, useState,useRef, useCallback } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import { getBaseURL } from "../../utils/Helper";
import upimg from "../../imgs/default.jpg"
import CropModal from "./CropModal";

export default function ModalComponent({
  id,
  show = false,
  onHide,
  size = "lg",
  modalHeading,
  localData,
  errors,
  setlocalData,
  handleChange,
  onButtonClick,
  handleImage,
  progress,
  handleRt,
  showcrop, 
  setShowCrop,
  cropimg, 
  setCropImg,
  caartifileimage,
  setFileCaartiimage,
  loader
}) {

  const [source, setSource] = useState();
  useEffect(()=>{
    if(localData?.source != ''){
      setSource(localData?.source)
    }else if(localData?.video === ''){
      setSource('')
    }
  },[localData])
  
  const inputRef = useRef();
  const footerButton = {
    buttonName: (localData?._id) ? "Edit" : "Add",
    buttonState: true,
  };
  const footerButtonloader = {
    buttonName: (localData?._id) ? progress === 100?`Processing...`:`Uploading(${progress}%)...` : progress === 100?`Processing...`:`Uploading(${progress}%)...`,
    buttonState: true,
  };

  let footerContent = () => {
    return (<>
      {loader?
        footerButtonloader.buttonState &&
        <Button
          variant="success"
          className="as_btn"
          
        >
          {footerButtonloader.buttonName}
        </Button>: footerButton.buttonState &&
        <Button
          variant="success"
          className="as_btn"
          onClick={() => onButtonClick(footerButton.buttonName)}
        >
          {footerButton.buttonName}
        </Button>}
     
      </>)
  }

  const previewVideo = (event) => {
    const file = event.target.files[0];
    handleChange(event, 'video')
    const url = URL.createObjectURL(file);
    console.log("url",url)
    setSource(url);
  }
  const handleChoose = (event) => {
    inputRef.current.click();
  };


// /////////////////////////////////////////////////////////////////
  

useEffect(()=>{
  if(cropimg && caartifileimage){
    handleImage(caartifileimage)
  } 
},[cropimg,caartifileimage])



// /////////////////////////////////////////////////////////////////


  function renderUpdateAarti() {
    return (
      <Form>

        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label column sm="3" className="required">
            Title
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Title..."
              name="title"
              isInvalid={!!errors?.title}
              value={localData?.title}
              onChange={(e) => handleChange(e, 'text')}
            />
             <Form.Control.Feedback type="invalid">
                    {errors?.title}
               </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
          <Form.Label column sm="3" className="required">
            Content
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              name="content"
              isInvalid={!!errors?.content}
              placeholder="Content..."
              value={localData?.content}
              style={{ height: 250 }}
              onChange={(e) => handleChange(e, 'text')}
            />
             <Form.Control.Feedback type="invalid">
                    {errors?.content}
               </Form.Control.Feedback>
          </Col>
        </Form.Group>
        
            <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
              <Form.Label column sm="3" className="required">
                Image
              </Form.Label>
              <Col sm="9">
              <label id="labimg">
      
            {cropimg?<img src={cropimg} 
              onClick={()=>setCropImg()}
              // crossOrigin="anonymous"
              className="imgs"
              alt=""
              width="100%" />:
              <img
              // crossOrigin="anonymous"
              className="imgs"
              alt=""
              width="100%"
              src={localData?.file ?localData?.file: upimg}
            /> }
                 {cropimg?<input onClick={(e)=> setShowCrop(true) }/>:<>
                <Form.Control
               // type="file"
               className="form-control "
               isInvalid={!!errors?.file}
               name="file"
               // size="40"
               onClick={(e) =>{ setShowCrop(true);handleRt(e)}}
               accept="image/png, image/gif, image/jpeg"
               id="inputds"
             
             />
               <Form.Control.Feedback type="invalid">
                {errors?.file}
               </Form.Control.Feedback>
             </>} 
                </label>
              </Col>
            </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Content Type
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  isInvalid={!!errors?.imageType}
                  name ="imageType"
                  value={localData?.imageType}
                  onChange={(e) => handleChange(e, 'text')}
                >
                  <option value="">Choose</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors?.imageType}
                  </Form.Control.Feedback>
              </Col>
              {/* <Col sm="4"></Col> */}
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
              <Form.Label column sm="3" className="required">
                Audio File
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="file"
                  name="audio"
                  isInvalid={!!errors?.audio}
                  onChange={(e) => handleChange(e, 'audio')}
                  accept="audio/*" placeholder="" className="profile-aud"
                />
                <Form.Control.Feedback type="invalid">
              {errors?.audio}
           </Form.Control.Feedback>
           {typeof localData?.audio === "object" ?""
             :  
             <span className="ms-3" style={{color:"",
             }}>
              <a rel="noopener" target = "_blank" href={localData.audio}>
            {localData.audio==="https://d3i0p1mk3sd6q7.cloudfront.net/undefined"?"": localData.audio?.slice(26)}
               </a>

             </span>}
              </Col>
            </Form.Group>
            {/* {(modalHeading === "Edit Advice" ||modalHeading === "Add Advice" ) ? "" :<>  
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label column sm="3" className="required">
            Song URL
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Song URL..."
              isInvalid={!!errors?.songUrl}
              name="songUrl"
              value={localData?.songUrl}
              onChange={(e) => handleChange(e, 'text')}
            />
             <Form.Control.Feedback type="invalid">
              {errors?.songUrl}
           </Form.Control.Feedback>
          </Col>
         
        </Form.Group>
              
             <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
          <Form.Label column sm="3" className="required">
          Description
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              name="description"
              isInvalid={!!errors?.description}
              placeholder="Description..."
              value={localData?.description}
              onChange={(e) => handleChange(e, 'text')}
            />
              <Form.Control.Feedback type="invalid">
                    {errors?.description}
                  </Form.Control.Feedback>
          </Col>
             </Form.Group></>} */}
      </Form>
    )
  }
  function renderUpdateReel() {
    return (
      <Form>
       <Form.Group as={Row} className="mb-3" controlId="formReelInput">
              <Form.Label column sm="3" className="required">
                Video File
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="file"
                  name="video"
                 isInvalid={!!errors?.video}
                 onChange={previewVideo}
                  accept=".mov,.mp4" placeholder="" className="profile-aud"
                />
                {source && (
                <video
                  className=""
                  width="150" height="120"
                  controls
                  src={source}
                />
              )}
               <Form.Control.Feedback type="invalid">
                    {errors?.video}
               </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3">
            Tags
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="tags"
                placeholder="Add tags saprated by comma Ex. Tag1,Tag2"
                value={localData?.tags}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3">
            Category
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="category"
                placeholder="Category"
                value={localData?.category}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3">
            Hash Tags
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="hash_tags"
                placeholder="Add hast tags saprated by comma Ex. #Tag1,#Tag2"
                value={localData?.hash_tags}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group>
            
                 
             <Form.Group as={Row} className="mb-3" controlId="formReelInput">
          <Form.Label column sm="3" >
          Description
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              name="description"
              placeholder="Description..."
              value={localData?.description}
              style={{ height: 150 }}
              onChange={(e) => handleChange(e, 'text')}
            />
          </Col>
             </Form.Group>
      </Form>
    )
  }

  function renderAddReelURL(){
    return (
      <Form>
        <>
          <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3" >
            Category
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="category"
                placeholder="Category"
                value={localData?.category}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formReelInput">
          <Form.Label column sm="3" className="required" >
          URL's
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              name="urls"
              placeholder="Add url of reels (Comma-separated)..."
              defaultValue={localData?.urls}
              isInvalid={!!errors?.urls}
              style={{ height: 300 }}
              onChange={(e) => handleChange(e, 'text')}
            />
             <Form.Control.Feedback type="invalid">
                    {errors?.urls}
               </Form.Control.Feedback>
          </Col>
         
             </Form.Group></>
      </Form>
    )
  }

  function renderUpdateChalisa() {
    footerContent = () => {
      return (<>
        {loader?
          footerButtonloader.buttonState &&
          <Button
            variant="success"
            className="as_btn"
            
          >
            {footerButtonloader.buttonName}
          </Button>: footerButton.buttonState &&
          <Button
            variant="success"
            className="as_btn"
            onClick={() => onButtonClick(footerButton.buttonName)}
          >
            {footerButton.buttonName}
          </Button>}
       
        </>)
    }
    return (
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label column sm="3" className="required">
            Title
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Title..."
              name="title"
              isInvalid={!!errors?.title}
              value={localData?.title}
              onChange={(e) => handleChange(e, 'text')}
            />
              <Form.Control.Feedback type="invalid">
                    {errors?.title}
               </Form.Control.Feedback>
          </Col>
        </Form.Group>

        {localData?.content?.length && localData?.content?.map((item, i) => {
          return (
            <>

              <Form.Group as={Row} className="mb-3" controlId={`formSubTitle-${i}`}>
                <Form.Label column sm="3" className="required">
                  Sub Title
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Sub Title..."
                    name="subTitle"
                    isInvalid={!!errors?.content?.[i]?.subTitle}
                    value={item?.subTitle}
                    onChange={(e) => handleChange(e, 'text', i)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.content?.[i]?.subTitle}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId={`formSubContent-${i}`}>
                <Form.Label column sm="3" className="required">
                  Sub Content
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="textarea"
                    name="subContent"
                    placeholder="Sub Content..."
                    isInvalid={!!errors?.content?.[i]?.subContent}
                    value={item?.subContent}
                    style={{ height: 150 }}
                    onChange={(e) => handleChange(e, 'text', i)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.content?.[i]?.subContent}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {i != 0 ?
              <FaMinus
                className=" mt-3 hd-cr"
                style={{ marginLeft: "97%" }}
                title="Remove Section"
                onClick={() => onButtonClick("removeSection", i)}
              />:null}
            </>
          )
        }) || null}
        <div>
        <FaPlus
          className=" mt-3 hd-cr"
          style={{ marginLeft: "97%", marginBottom:"6px" }}
          title="Add Section"
          onClick={() => {onButtonClick("addSection")}}
        />
     </div>

         <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
              <Form.Label column sm="3" className="required">
                Image
              </Form.Label>
              <Col sm="9">
              <label id="labimg">

              {cropimg?<img src={cropimg} 
              onClick={()=>setCropImg()}
              // crossOrigin="anonymous"
              className="imgs"
              alt=""
              width="100%" />:<img
              // crossOrigin="anonymous"
              className="imgs"
              alt=""
              width="100%"
              src={  localData?.file ? localData?.file :upimg}
            />
            }  
            
               {cropimg?<input onClick={(e)=> setShowCrop(true) }/>:<>
               
               <Form.Control
              //  type="file"
               className="form-control "
               name="file"
               isInvalid={!!errors?.file}
               // size="40"
               onClick={(e) => {setShowCrop(true);handleRt(e)}}
               accept="image/png, image/gif, image/jpeg"
               id="inputds"
             
               />
                <Form.Control.Feedback type="invalid">
                {errors?.file}
               </Form.Control.Feedback></>
                } 
               
                </label>
              </Col>
            </Form.Group>

        {(modalHeading === "Edit Mantra" ||modalHeading === "Add Mantra" ) ? "" :<>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Content Type
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  isInvalid={!!errors?.imageType}
                  name ="imageType"
                  value={localData?.imageType}
                  onChange={(e) => handleChange(e, 'text')}
                >
                  <option value="">Choose</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors?.imageType}
                  </Form.Control.Feedback>
              </Col>
              {/* <Col sm="4"></Col> */}
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
              <Form.Label column sm="3">
                Audio File
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="file"
                  name="audio"
                  onChange={(e) => handleChange(e, 'audio')}
                  accept="audio/*" placeholder="" className="profile-aud"
                />
                <span style={{color:"gray"}}>{ typeof localData.audio === "object"?localData.audio.name:localData.audio==="https://d3i0p1mk3sd6q7.cloudfront.net/undefined"?"": localData.audio?.slice(26)}</span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label column sm="3" className="required">
                Song URL
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Song URL..."
                  isInvalid={!!errors?.songUrl}
                  name="songUrl"
                  value={localData?.songUrl}
                  onChange={(e) => handleChange(e, 'text')}
                />
                 <Form.Control.Feedback type="invalid">
                    {errors?.songUrl}
                  </Form.Control.Feedback>
              </Col>
          </Form.Group>

             <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
          <Form.Label column sm="3" className="required">
          Description
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              name="description"
              isInvalid={!!errors?.description}
              placeholder="Description..."
              value={localData?.description}
              onChange={(e) => handleChange(e, 'text')}
            />
             <Form.Control.Feedback type="invalid">
                    {errors?.description}
                  </Form.Control.Feedback>
          </Col>
             </Form.Group></>}
      </Form>
    )
  }

  function renderReadMore() {
    footerButton.buttonState = false;
    return (
      <pre style={{ textAlign: "center" }}>
        {localData}
      </pre>
    )
  }

  function renderchalisaReadMore() {
    footerButton.buttonState = false;
    return localData?.length &&
      localData?.map((item, i) => {
        return (
          <pre key={i} style={{ textAlign: "center" }}>
            <p className="subtitle-b">{item?.subTitle}</p>
            <p>{item?.subContent}</p>
          </pre>
        );
      });
  }

  function renderModal() {
    switch (id) {
      case "updateAarti":
        return renderUpdateAarti();
      case "updateReel":
        return renderUpdateReel();
      case "readMore":
        return renderReadMore();
      case "addReelURL":
          return renderAddReelURL();
    
      case "chalisaReadMore":
        return renderchalisaReadMore();

      case "updateChalisa":
        return renderUpdateChalisa();
      default:
        return "you need to give an Id...";
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={()=>onHide()}
        backdrop="static"
        size={size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="marketing_model"
      >
        <Modal.Header closeButton>
          <Modal.Title id={id}>
            {modalHeading}
          </Modal.Title>
        </Modal.Header >

        <Modal.Body>
          {renderModal()}
        </Modal.Body>

        <Modal.Footer>
          {/* {
            footerButton.buttonState &&
            <Button
              variant="success"
              onClick={onButtonClick}
            >
              {footerButton.buttonName}
            </Button>
          } */}
          {footerContent()}
          <Button onClick={()=>onHide()} className="as_btn reset-btn">Close</Button>
        </Modal.Footer>
      </Modal>
      <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
    </>
  )
}

//  ///////////////// remove code after check//////////////////
// <input
// type="file"
// className="form-control "
// name="file"
// size="40"
// onChange={(e) => handleChange(e, 'file')}
// accept="image/png, image/gif, image/jpeg"
// id="inputds"

// />

    // <img
    //             crossOrigin="anonymous"
    //             className="imgs"
    //             alt=""
    //             width="100%"
    //             src={(typeof localData?.file === 'object') ? URL.createObjectURL(localData?.file) : getBaseURL(localData?.file) ?? upimg}
    // />