import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Dropdown, Form, ToggleButton, ButtonGroup, Spinner, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import APIServicenew from "../utils/APIGeneralService";
import { formateDate, getCookies } from "../utils/Helper";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Papa from 'papaparse';


const Diwaliuserlist = () => {
  document.title = 'Diwali Users';
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
  const [users,setUsers] = useState([])
  const [skip, setSkip] = useState(0)
  const [totalChats, setTotalChats] = useState('');
  const token = getCookies("accessToken")
  const searchKeyword = useRef(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [diwaliuser, setDiwaliuser] = useState([]);
  const headers = {
    "Authorization":`Bearer ${token}`
  }
  const [inputState, setInputState] = useState({
    startdate: "",
    enddate:"" 
  });

  const handleInputChange = e => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    setInputState(prevState => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const handleReset=()=>{
    setInputState({
       startdate: "",
       enddate:"" 
     });
     
     }
     useEffect(()=>{
      fetchedAllUsers({skips:0,searchKey:searchKeyword.current?searchKeyword.current:""})
     },[inputState])

     const handleDownload = () => {
      let csv = Papa.unparse(diwaliuser, { skipEmptyLines: true });
      let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'UserList.csv');
      else {
        let a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
        a.download = 'diwaliuser.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
  
 
    
  

  const columns = [{
   
    dataField: 'index',
    text: 'No',
    classes: 'blocked-column-width',
    formatter: (cell, row, rowIndex)=>{
      for(var i = rowIndex; i <= rowIndex + 50 ; i++){
        return  skip + i +1;
      }
    },
  }, {
    dataField: 'name',
    text: 'User Name '
  }, {
    dataField: 'contactNumber',
    text: 'Contact Number',
    
  },{
    dataField: 'createdAt',
    text: 'Create Date',
  }];


  const handleSearch = (e)=>{
    fetchedAllUsers({skips:0,searchKey:e.target.value})
    searchKeyword.current = e.target.value
  }

  const fetchedAllUsers = async({skips = 0,searchKey=""})=>{
    console.log("skips",searchKey)
    let startdate = inputState.startdate;
    let enddate = inputState.enddate;
    const res = await APIServices.get(`/diwali/getallDiwaliUsers/${skips}/50/${searchKey}?startDate=${startdate}&endDate=${enddate}`,headers);
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData.Listdata]
        setDiwaliuser(
        newData = newData.map(item=>{
          return {
            _id:item._id,
            name:item.name ,
            contactNumber:item.contactNumber,
            createdAt:moment(item.createdAt).format("lll")
          } 
        })
        )
        setUsers(newData);
        setTotalChats(resData.totalCount);
      }
    }
  }
  

  const handlePageChange = (selectedPage)=>{
    let skipValue = selectedPage.selected * 50
    console.log("skipValue",skipValue)   
    setSkip(skipValue)
    let searchKey = searchKeyword.current ?  searchKeyword.current : ""
    // console.log("searchKey",searchKey)
    fetchedAllUsers({skips:skipValue,searchKey:searchKey})
  }


  useEffect(()=>{
    fetchedAllUsers({skips:0})
  },[])

  return (<>
    <div className="gita_quote_heading">
    <div className="container d-flex align-items-center justify-content-between">
    <h4 className="hd-list-f left-q" >Diwali Users  <small>({totalChats})</small></h4>
    </div>
 </div>
    <div className="chat_window">
    <Row className="mlist">
    <div className="chat_search_box">
      <div className="chat-search-field">
        <span className="chat-bot-search-icon"><i className="fa fa-search"></i></span>
        <input type="text" className="form-control form-input w-50" placeholder="Search anything..." onChange={handleSearch} />
      </div>
      <hr/>
      <Row>
      <Col md={1} className='d-flex justify-content-end pe-0 align-items-end'>
        <Form.Label>Start Date</Form.Label>
      </Col>
      <Col md={2}>
        <input
          className='form-control'
          name='startdate'
          type='date'
          onChange={e => handleInputChange(e)}
          value={inputState.startdate}
        />
      </Col>
      <Col md={1} className='d-flex justify-content-end pe-0 align-items-end'>
        <Form.Label>End Date</Form.Label>
      </Col>
      <Col md={2}>
        <input
          className='form-control'
          name='enddate'
          type='date'
          onChange={e => handleInputChange(e)}
          value={inputState.enddate}
        />
      </Col>
      <Col md={4} className='ms-1'>
       {/*  <Button
          className='as_btn'
          variant='primary'
          onClick={handleSearch}
        >
          Get Users
        </Button>*/}
        <Button
          className='as_btn'
          variant='primary'
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
        className='as_btn ms-2'
        variant='primary'
        disabled={!diwaliuser || (diwaliuser && diwaliuser.length <= 0)}
        onClick={() => {
          handleDownload();
        }}
      >
        Export Users
      </Button>
       
      </Col>
    </Row>
    </div>
    <BootstrapTable keyField='index' data={ users } columns={ columns } />
    {totalChats > 50 && (
      <div className="d-flex justify-content-center align-items-left w-100 p-0 mt-2 custom-pagination">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            pageCount={totalChats < 50 ? 1 : Math.ceil(totalChats/50)} // Total number of pages
            marginPagesDisplayed={2} // Number of pages to display at the start and end of the pagination
            pageRangeDisplayed={1} // Number of pages to display in the middle of the pagination
            onPageChange={handlePageChange} // Callback function when page is changed
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      )}
    </Row >
    
      
    </div>
    </>
  );
};

export default Diwaliuserlist;

// chat_window after div row class add when table not full show
