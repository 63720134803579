import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Row, Col, Dropdown, Form, Modal, ToggleButton, ButtonGroup, Spinner, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import APIServicenew from '../utils/APIGeneralService';
import { formateDate, getCookies, setCookies } from '../utils/Helper';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Papa from 'papaparse';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

export default function ResponderCallhistory() {
  document.title = 'Responder Call History';
  const permissioncontext =usePermissionData("responderCallhistory")
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState('');
  const token = getCookies('accessToken');
  const [pageIndex, setPageIndex] = useState(0);
  const [responderaa, setResponderaa] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [flag, setFlag] = useState(false);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

 

  const [selectedResponder, setSelectedResponder] = useState('');

  const handleResponderChange = e => {
    setSelectedResponder(e.target.value);
  };

  const [callStatus, setCallStatus] = useState('');
  const handleCallStatus = event => {
    setCallStatus(event.target.value);
  };

  const [source, setSource] = useState('');
  const handleSourceStatus = e => {
    setSource(e.target.value);
  };

  const [callTo, setCallTo] = useState('');
  const handleCallTo = e => {
    setCallTo(e.target.value);
  };

  const [callType, setCallType] = useState('');
  const handleCallType = e => {
    setCallType(e.target.value);
  };

  // const [inputState, setInputState] = useState({
  //   startdate: '',
  //   enddate: '',
  // });

  // const handleInputChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  // };
  // const handleInputChangeEnd = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  // };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const handleReset = () => {
    setPageIndex(0);
    setOffset(0);
    setFlag(true);
    setStartDate('')
    setEndDate('')
    setSelectedRange('')
    // setInputState({
    //   startdate: '',
    //   enddate: '',
    // });
    setCallType('');
    setCallStatus('');
    setSource('');
    setCallTo('');
    setSelectedResponder('');
  };

  const statusMap = {
    2: { text: 'Completed', testClass: 'text-success fw-bold' },
    0: { text: 'Pending', testClass: 'text-pending fw-bold' },
    3: { text: 'Failed', testClass: 'text-danger font_14 fw-bold' },
    1: { text: 'Initiated', testClass: 'text-pending fw-bold' },
  };

  const [callHistoryStatus, setCallHistoryStatus] = useState(false);
  const [callHistoryData, setcallHistoryData] = useState([]);

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Customer Name/Number',
      // text: "Customer Name/Number",
      Cell: e => {
        return (
          <>
            <span>
              {e.row.original.userName} {e.row.original.userName && e.row.original.userNumber ? '/' : ''}{' '}
              {e.row.original.userNumber}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Astrologer Name/Number',
      // text: "Astrologer Name/Number",
      Cell: e => {
        return (
          <>
            <span>
              {' '}
              {e.row.original.astrologerName}{' '}
              {e.row.original.astrologerName && e.row.original.astrologerNumber ? '/' : ''}{' '}
              {e.row.original.astrologerNumber}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Responder Name',
      // text: "Source",
      Cell: e => {
        return <span>{e.row.original.responderName ?? '_'}</span>;
      },
    },
    {
      Header: 'Responder Number',
      // text: "Source",
      Cell: e => {
        return <span>{e.row.original.responderNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Call Status',
      // text: "Call Status",
      Cell: e => {
        return (
          // <span className={statusMap[e.row.original.callStatus]?.testClass}>
          //   {statusMap[e.row.original.callStatus]?.text}
          // </span>
          <>
            <span className={statusMap[e.row.original.callStatus]?.testClass}>
              {statusMap[e.row.original.callStatus]?.text}
            </span>
            <br />
            {e.row.original?.callStatus == '3' &&
            e.row.original?.callHistory &&
            e.row.original.callHistory.length > 0 ? (
              <Link
                className=''
                style={{ fontSize: '12px' }}
                onClick={() => {
                  setcallHistoryData(e.row.original);
                  setCallHistoryStatus(e.row.original?.callStatus == '3' && e.row.original?.callHistory ? true : false);
                }}
              >
                View History
              </Link>
            ) : (
              ''
            )}
          </>
        );
      },
    },

    {
      Header: 'Source',
      // text: "Source",
      Cell: e => {
        return <span>{e.row.original.source ?? '_'}</span>;
      },
    },
    {
      Header: 'Call to',
      // text: "Call to",
      Cell: e => {
        return <span>{e.row.original.callTo ?? '_'}</span>;
      },
    },
    {
      Header: 'Call Type',
      Cell: e => {
        return <span>{e.row.original.callType ?? '_'}</span>;
      },
    },
    {
      Header: 'Duration',
      // text: "Duration",
      Cell: e => {
        return <span>{e.row.original.duration + ' ' + 'min' ?? '_'}</span>;
      },
    },
    {
      Header: 'Date',
      // text: "Date",
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Modified Date',
      // text: "Modified Date",
      Cell: e => {
        return (
          <>
            <span>{e.row.original.updatedAt ? moment(e.row.original.updatedAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
          </>
        );
      },
    },
  ]);

  const fetchedAllUsers = useCallback(
    async (offset, perPage, index) => {
      const res = await APIServices.get(
        `/responders/callListing/${offset}/${perPage}?userId&responderId=${selectedResponder}&callStatus=${callStatus}&source=${source}&callTo=${callTo}&callType=${callType}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}`,
        headers
      );
      if (res && res.status === 200) {
        let resData = res.data;
        if (resData && resData.status) {
          let newData = [...resData?.data];
          setFlag(false);
          setUsers(newData);
          setTotalChats(resData.total_count);
          setPageCount(Math.ceil(resData.total_count / perPage));
        }
      } else {
        if (res?.response?.status === 401) {
          // logout();
        }
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [
      pageIndex,
      offset,
      callStatus,
      selectedResponder,
      source,
      callTo,
      callType,
      startDate,
      endDate,
    ]
  );

  const handleFilterClick = () => {
    if (
      source ||
      callTo ||
      callType ||
      callStatus ||
      startDate ||
      endDate ||
      perPage ||
      offset ||
      pageIndex ||
      selectedResponder
    ) {
      fetchedAllUsers(
        offset,
        perPage,
        0,
        startDate,
        endDate,
        callTo,
        callStatus,
        source,
        callType,
        selectedResponder
      );
    }
  };

  const fetchedResponder = async () => {
    const res = await APIServices.get('responders/list', headers);
    if (res && res.status === 200) {
      setResponderaa(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchedAllUsers(offset, perPage, pageIndex);
    fetchedResponder();
  }, [flag]);

  return (
    <>
    {
      permissioncontext.view?
      <>
       <div className='gita_quote_heading'>
        <div className='container d-flex align-items-center justify-content-between'>
          <h4 className='hd-list-f left-q'>
            {' '}
            Responder Call History <small>({totalChats})</small>{' '}
          </h4>
        </div>
      </div>
      <div className='chat_window '>
        <Row className='mlist br-6 p-20'>
          <div class='chat_search_box'>
                    {/*<div className="chat-search-field">
                  <span className="chat-bot-search-icon"><i className="fa fa-search"></i></span>
                  <input type="text" className="form-control form-input w-50" placeholder="Search anything..." onChange={handleSearch} />
                     </div> */}
            <Row className='align-items-end'>
              <Col md={3} className='mb-3'>
                <Form.Label>Responder</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleResponderChange}
                  value={selectedResponder}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  {responderaa.map((item, index) => {
                    return (
                      <>
                        <option className='text-capitalize' value={item._id}>
                          {item.name}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={3} className='mb-3'>
                <Form.Label>Call Status</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleCallStatus}
                  value={callStatus}
                  // value={selectedCallstatus?.current==="all"?selectedCallstatus?.current:selectedCallstatus?.current?.status}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='0'>
                    Pending
                  </option>
                  <option className='text-capitalize' value='1'>
                    Initiated
                  </option>
                  <option className='text-capitalize' value='2'>
                    Completed
                  </option>
                  <option className='text-capitalize' value='3'>
                    Failed
                  </option>
                </Form.Select>
              </Col>
              <Col md={3} className='mb-3'>
                <Form.Label>Source</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleSourceStatus}
                  value={source}
                  // value={selectedSourcestatus?.current}
                  // value={selectedCallstatus?.current==="all"?selectedCallstatus?.current:selectedCallstatus?.current?.status}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='puja'>
                    puja
                  </option>
                  <option className='text-capitalize' value='lead'>
                    lead
                  </option>
                  <option className='text-capitalize' value='call'>
                    call
                  </option>
                  <option className='text-capitalize' value='chat'>
                    chat
                  </option>
                  <option className='text-capitalize' value='customers'>
                    customers
                  </option>
                  <option className='text-capitalize' value='	puja recommendation'>
                    puja recommendation
                  </option>
                  <option className='text-capitalize' value='	callhistory'>
                    call history
                  </option>
                </Form.Select>
              </Col>
              <Col md={3} className='mb-3'>
                <Form.Label>Call to (Astrologer/User)</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleCallTo}
                  // onChange={handleChatCalleStatus}
                  value={callTo}
                  // value={selectcallchatstatus.current}
                  // value={selectedSourcestatus?.current==="all"?selectedSourcestatus?.current:selectedSourcestatus?.current?.status}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='astrologer'>
                    Astrologer
                  </option>
                  <option className='text-capitalize' value='user'>
                    User
                  </option>
                </Form.Select>
              </Col>
              <Col md={3} className='mb-3'>
                <Form.Label>Call Type</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleCallType}
                  value={callType}
                  // value={callTypestatus.current}
                  // value={selectedSourcestatus?.current==="all"?selectedSourcestatus?.current:selectedSourcestatus?.current?.status}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='incoming'>
                    Incoming
                  </option>
                  <option className='text-capitalize' value='outgoing'>
                    Outgoing
                  </option>
                </Form.Select>
              </Col>
              <Col md={3}  className='mb-3'>
              <Form.Label>Select By History Date</Form.Label>
              <div className="date-range-wrapper">
                  <div className='select-icon-wrapper'>  
                    <select
                    className='select-range'
                        value={selectedRange}
                        onChange={(e) => handleDateRange(e.target.value)}
                      >
                        <option value="disabled">Select Date Range</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="thisWeek">This Week</option>
                        <option value="last7Days">Last 7 Days</option>
                        <option value="thisMonth">This Month</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="customDate">Custom Date</option>
                    </select>
                    <img src='/images/down-arrow.png' className='select-icons'/>
                  </div>

                    {selectedRange === "customDate" && (
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        onChange={(update) => {
                          const [start, end] = update;
                          setStartDate(start);
                          setEndDate(end);
                        }}
                        // isClearable={true}
                        placeholderText="Select custom date range"
                        dateFormat="yyyy-MM-dd"
                      />
                    )}
                  </div>
              </Col>
              {/* <Col md={2} className='mb-3'>
                <Form.Label>Start Date</Form.Label>
                           
                <DatePicker
                  placeholderText={'Select a starting date'}
                  className='form-control form-ch fil-date-pick '
                  name='pujaDate'
                  selected={inputState.startdate ? new Date(inputState.startdate) : null}
                  onChange={date => {
                    handleInputChange(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>
              <Col md={2} className='mb-3'>
                <Form.Label>End Date</Form.Label>
               
                <DatePicker
                  placeholderText={'Select a ending date'}
                  className='form-control form-ch fil-date-pick '
                  name='pujaDate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleInputChangeEnd(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col> */}
              <Col md={4} className='mb-3'>
                <Button className='as_btn ms-0' variant='primary' onClick={handleFilterClick}>
                  Filter
                </Button>

                <Button className='as_btn reset-btn' variant='primary' onClick={handleReset}>
                  Reset
                </Button>
              </Col>
               
            </Row>
          </div>
          {/* <BootstrapTable keyField='index' data={ users } columns={ columns } /> */}
        </Row>
        <DynamicDataTableWithCount
                  columns={columns}
                  data={users}
                  totalCount={pageCount}
                  initialState={{
                    pageIndex: pageIndex,
                    pageSize: perPage,
                  }}
                  fetchData={fetchedAllUsers}
                  forcePage={offset / perPage}
                />


        <Modal
          show={callHistoryStatus}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={() => {
            setCallHistoryStatus(false);
          }}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>No Answer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-2 border-bottom'>
              <span className='fw-semibold'>Customer Number :- </span>
              <span>{callHistoryData?.userNumber}</span>
            </div>
            <div>
              {callHistoryData?.callHistory &&
                callHistoryData?.callHistory?.length > 0 &&
                callHistoryData?.callHistory.map((item, index) => {
                  return (
                    <>
                      <div key={item?._id}>
                        <span className='fw-semibold'>Responder Number {index + 1} :- </span>
                        <span>{item?.responderNumber}</span>
                      </div>
                    </>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              className='cancle_btn'
              onClick={() => {
                setCallHistoryStatus(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </>:
          <Notauthorized />
    }
     
    </>
  );
}
