import { useState, useCallback, useMemo, useEffect,useContext } from 'react';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row, Container } from 'react-bootstrap';
import APIServicenew from '../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Papa from 'papaparse';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';
import Select from 'react-select';

function PujaFeedback() {
  document.title = 'Puja Feedback';
  const permissioncontext =usePermissionData("pujaFeedback")
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const  {refreshToken} = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);

  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount , setAllDataCount] = useState()
  const [show, setShow ] = useState(false);
  const [questionData ,setQuestionData] = useState() 
  const [date , setDate] = useState('')
  const [search , setSearch] = useState('')
  const [rating ,setRating] = useState('')
  const [ pujaList ,setPujaList] = useState([])
  const [ puja ,setPuja] = useState('')
  const [ questList ,setQuestList] = useState([])
  const [ question ,setQuestion] = useState('')
  const [ flag , setFlag] = useState(false)

  const [userForNote ,setUserForNote] = useState()
  const [showNoteModel ,setShowNoteModel] = useState(false)  
  const [notesData ,setNotesData] = useState([])
  const [error ,setError] = useState(false) ; 
  const [note ,setNote] = useState('')
  const [loader, setLoader] = useState(false)
  const [countryList,setcountryList]=useState([])
  const [country, setCountry] = useState('');


  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

  const handleShowModal = (data)=>{
    setQuestionData(data)
    setShow(true)
  }
  const handleShowNote = () =>{
    setShowNoteModel(true)
  }

  const handleCloseNote = () => {
    setShowNoteModel(false);
    setError(false)
  
  }

  const handleChange = () =>{
    setError(false)
}
  const handleCloseModal = ()=>{setShow(false)}

  const columns = useMemo(
    () => [
      {
        Header: 'NO.',
        Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
      },
      {
        Header: 'Name',
        Cell: (e) => {
          return  <span>{e.row.original.name??"_"}</span>
        }
      },
      {
        Header: 'Number',
        Cell: (e) => {
          return  <span>{e.row.original.number??"_"}</span>
        }
      },
      {
        Header: 'Puja',
        Cell: e => {
          return  <span>{e.row.original.pujaId?.title}</span>
        }
        
      },
      {
        Header: 'Country Name',
        Cell: e => {
          return  <span>{e.row.original?.country}</span>
        }
        
      },
      {
        Header: 'Rating',
        Cell: e => {
          return  <span>{e.row.original.rating}</span>
        },
      },
      {
        Header: 'Review',
        Cell: e => {
          return  <span>{e.row.original.review??"_"}</span>
        },
      },
      {
        Header: 'Questions',
        Cell: e => {
          if (e.row.original.questions) {
            return (
              <h5>
                <input
                  type='button'
                  className='Et-btn'
                  value='View'
                  onClick={() =>
                    handleShowModal(
                      e.row.original.questions,
                    )
                  }
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Date',
        Cell: e => {
          return <span>{moment(e.row.original.updatedAt).format('DD MMM, YYYY hh:mm A')}</span>;
        },
      },
      {
        Header: 'Notes',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.add?"Et-btn notes-btn disabled-btn":'Et-btn notes-btn '}
                  disabled={!permissioncontext.add}
                  value='Notes'
                  onClick={() =>{ setUserForNote(e.row.original) ;handleUserNotes(e.row.original.userId)}}
                />
              </h5>
            );
          }
        },
      },
    ],
    
  );

  const handleInputChange = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setDate(formattedDate);
  };
  useEffect(()=>{
    pujaFetchData()
    questionFetchData()
    fetchCountryList()
  },[])

  const fetchCountryList=async()=>{
    let response = await APIServicesSec.get(`country/getPhoneCode`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setcountryList(apiData?.data)
      }
    }
  }

  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0)
  };

const options = countryList.sort((a, b) => a.name.localeCompare(b.name)).map(item => ({
  value: item._id,
  label: item.name,
}));

  const handleCountryChange = e => {
    setCountry(e.target.value);
    setSkip(0)
  };


  const fetchProfilesByPagination = useCallback(
    async (skip, limit, index) => {
      try {
        let countryId = country?.value === undefined ? '' : country?.value;
        const response = await APIServices.get(`astroPooja/pujaFeedbackList/${skip}/${limit}?searchKey=${search}&date=${date}&pujaId=${puja}&country=${countryId}&rating=${rating}&questionId=${question}`, headers);
        let data = response.response?.data
        if (response.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount) 
          setTableData(response.data?.Listdata);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }else{
          if(data?.status == 4){
            logout()
           }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex,skip ,search ,date ,puja,rating,question,country]
  );

 



  // useEffect(()=>{
  //   fetchProfilesByPagination(skip ,limit,pageIndex ,date ,puja ,rating,question)
  // },[search])
  const handleNotesAdd = async () => {

    if (!note || note === "") {
      setError(true)
  }else{
    let payload = {
      userId: userForNote?.userId,
      id:userForNote?.pujaId?._id,
      source:"Marketing-Puja-Feedback",
      name:userForNote?.pujaId?.title,
      type:adminUser?.adminType,
      adminName:adminUser?.name,
      adminId: adminUser?._id,
      message:note
    };
    setLoader(true)
    const res = await APIServices.post(`userNotes/userNoteMessage`, payload, headers);

    if (res && res?.data?.status === true) {
      toast.success("Note added successfully")
      setShowNoteModel(false)
      // getUserNotes(userForNote?.userId)
      setNote('')
      setLoader(false)

    }
    else {
      toast.error(res?.data?.message)
      setLoader(false)

    }
    setLoader(false)
  }
  }
  const handleUserNotes = async(id) => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data)
        setShowNoteModel(true)

      }else{
        console.log("error");
      }
    
    }
  }

  const pujaFetchData = async () => {
    let response = await APIServices.get(`astroPooja/getAll`,headers);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPujaList(apiData.Listdata);
      }
    }
  };
  const questionFetchData = async () => {
    let response = await APIServices.get(`webApi/puja/feedbackList`);
    if (response?.status === 200) {
      let apiData = response?.data;
        setQuestList(apiData.data);
      
    }
  };
  

  const handleFilterClick =() =>{
    if (date ||puja||rating || skip ||question || country) {
      fetchProfilesByPagination(skip ,limit,0 ,date ,puja ,rating,question,country)
   }
   }

   const resetData = () =>{
     setDate('');
     setPuja('');
     setRating('');
     setQuestion('')
     setSearch('')
     setFlag(true)
     setCountry('')
     setPageIndex(0)
    }
 
    useEffect(()=>{
      fetchProfilesByPagination(skip ,limit,pageIndex)
   },[flag])

   const handleDownload = () => {
    for (let feedback of tableData) {
      // if (feedback.name) feedback['Name'] = feedback.name;

      if (feedback.name){
        feedback['Name']=feedback.name
      }else{
        feedback['Name']= '_'

      }
      if (feedback.number) feedback['Number'] = feedback.number;
      if (feedback.rating) feedback['Rating'] = feedback.rating;
      if (feedback.review) feedback['Review'] = feedback.review;
      if (feedback.pujaId) feedback['Puja'] = feedback.pujaId?.title; // Assuming pujaId is an object with _id field
      if (feedback.country) feedback['Country Name'] = feedback?.country;
      if (feedback.questions && feedback.questions.length > 0) {
        feedback['Questions'] = feedback.questions?.map(question => question.questId?.question).join(', ');
      } else {
        feedback['Questions'] = '';
      }
      feedback['Answers'] = feedback.questions?.map(question => question.value).join(', ');
      feedback[' Date ']=moment(feedback?.updatedAt).format('DD MMM, YYYY hh:mm A')
      // Add additional fields as needed
  
      // Delete unwanted fields
      delete feedback.name;
      delete feedback.rating;
      delete feedback.review;
      delete feedback.pujaId;
      delete feedback.number;
      delete feedback.questions;
      delete feedback.country;
      delete feedback._id;
      delete feedback.userId;
      delete feedback.isDeleted;
      delete feedback.createdAt;
      delete feedback.updatedAt;
      delete feedback.__v;
      // Delete additional unwanted fields
  
      // Add fields for processing pujaFeedback specific data
    }
  
    let csv = Papa.unparse(tableData, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvData);
    } else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.setAttribute('download', 'puja_feedback_data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  
  

  return (
    <>
    {
      permissioncontext.view?
      <div className='App'>
      <div className='gita_quote_heading'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q '> Puja Feedback List <small>({allDataCount})</small></h4>
            <div className='_btn'>
             <Button
                    className="by-btn as_btn"
                    variant="primary"
                    disabled={!permissioncontext.view}
                    onClick={(e) => {
                      navigate(`/pujaFeedbackQuest`, {
                        state: { permissioncontext },
                      })
                    }}
                  >
                   Manage Puja Feedback Questions
                  </Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
  
         <Container className="" fluid>
         {/* <div className='chat-search-field mt-0 w-100'>
              <span className='chat-bot-search-icon'>
                <i className='fa fa-search'></i>
              </span>
              <input
                type='text'
                className='form-control form-input w-50'
                placeholder='Search by Username and Contact no.'
                onChange={ (e) => setSearch(e.target.value)}
                value={search}
              />
      </div> */}
            <div className="feed_area box-shadow my-3 p-20 br-6">
         <div className="chat_search_box py-0">  
        <div className="chat-search-field">
            <Form>
                <Form.Group as={Row} className="align-items-end"  controlId="">
              

                <Col lg={3} md={6} className='mb-3'>
                <Form.Label className="label-f-w">Puja</Form.Label>
                
                  <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize mt-0 hd-cr form-ch'
                  onChange={(e)=>{setPuja(e.target.value);setSkip(0)}}
                  value={puja}
                  >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                 {pujaList && Array.isArray(pujaList) && pujaList.map((item, index) => (
                 <option className='text-capitalize' value={item._id} key={index}>
                   {item.title}
                 </option>
               ))}

                </Form.Select>

                </Col>

                <Col lg={3} md={6} className='mb-3'>
                                <Form.Label class='label-f-w-cd'>
                                  Country
                                </Form.Label>
                                  <Select
                                   className=' mt-2'
                                    classNamePrefix='react-select'
                                    options={options}
                                    value={country}
                                    onChange={handleSelectChange}
                                    placeholder='Search by Country...'
                                    isClearable
                                  />
                            </Col>

                 <Col lg={3} md={6} className='mb-3'>
              <Form.Label className="label-f-w ">Date</Form.Label>

                  {/* <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="date"
                    type="date"
                    placeholder="date"
                    value={date}
                    onChange={(e) => {setDate(e.target.value);setSkip(0)}}
                  /> */}
                   <DatePicker
                        placeholderText={"Select a date"}
                        className="form-control form-ch fil-date-pick"
                        name="date"
                        selected={date ? new Date(date) : null}
                        onChange={(date) => {
                          handleInputChange(date);
                          setSkip(0);
                        }}
                        dateFormat="yyyy-MM-dd"
                  />
                 </Col>

                 <Col lg={3} md={6} className='mb-3'>
                 <Form.Label className="label-f-w">Rating</Form.Label>
                 <Form.Select
              aria-label="categories"
              className="  astroWatch-select-hover mt-0 hd-cr form-ch"
              value={rating}
              onChange={(e) => {setRating(e.target.value);setSkip(0)}}
            >
              <option >Select</option>
              <option value="1">1</option>
              <option value="2" >2</option>
              <option value= "3">3</option>
              <option value= "4">4</option>
              <option value= "5">5</option>
              

               </Form.Select>
                 
                 </Col>

               <Col lg={3} md={6} className='mb-3'>
                 <Form.Label className="label-f-w">Question</Form.Label>
               
               <Form.Select
                 aria-label='label-selected-menu'
                 className='fw-bold label-selected-menu text-capitalize mt-0 w-50 hd-cr form-ch'
                 onChange={(e)=>{setQuestion(e.target.value);setSkip(0)}}
                 value={question}
               >
                 <option className='fw-bold'>
                   All
                 </option>
                {questList && Array.isArray(questList) && questList.map((item, index) => (
                <option className='text-capitalize' value={item._id} key={index}>
                  {item.question}
                </option>
              ))}

           </Form.Select>
               </Col>

               <Col md={6} className='mb-3'> 
                        <Button variant="secondary"
                        className="as_btn ms-1"
                        onClick={()=> {handleFilterClick();setSkip(0)}}
                        >
                        Filter
                      </Button>
                      <Button variant="secondary"
                        className="as_btn ms-2 reset-btn"
                        onClick={()=> {resetData()}}
                        >
                        Reset
                      </Button>
                      {adminUser?.adminType === "super_admin" ?
                       (
                          <Button
                          className='as_btn ms-2 download-btn'
                          variant='primary'
                          disabled={!tableData || (tableData && tableData.length <= 0)}
                          onClick={() => {
                            handleDownload();
                          }}
                        >
                          Download File
                        </Button>

                       ) 
                       : ""}
                    </Col>
                </Form.Group>
            </Form>

      </div>
    </div> 
    </div> 
   </Container>
   {/* </div> */}
   <div className='overflow-scroll'>
         {tableData && tableData.length > 0 ? 
            (<DynamicDataTableWithCount
                columns={columns}
                data={tableData}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: limit,
                }}
                fetchData={fetchProfilesByPagination}
                forcePage={skip/limit}
              />)
              : (<h2 className="no_records">Records not Available</h2>)
              }
        </div>


      <Modal
      centered
      scrollable
        show={show}
        size='md'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseModal}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title>Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <ul className="list-group">
      {questionData?.map((item ,index) =><>
          <li className="list-group-item d-flex justify-content-between align-items-center">
          <>{index + 1}. </>
          {item?.questId?.question}
         <span className="text-capitalize">{item?.value}</span>
         </li>
        </>)}
     </ul>
     </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='close_btn as_btn reset-btn' onClick={handleCloseModal}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>

    {/*    Notes modal   */}
    <Modal show={showNoteModel} id="my-modal"  onHide={handleCloseNote} 
     backdrop="static"
     centered
     scrollable={true} >
        <Modal.Header closeButton>
          <Modal.Title>Add Note </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="aa d-flex">
            <span className="me-3">  Name :- {userForNote?.name} </span>
           <span> Contact Number :- {userForNote?.number} </span> 
          
          </div>
         
          <Row>
            <Col>
        <Form.Group as={Row} className="my-3" controlId="formAartiInput">
        
          <Col sm="12">
            <Form.Control
              as="textarea"
              name="content"
              placeholder="Type here..."
              // value={reply}
              style={{ height: 100 }}
              onChange={(e) => {handleChange();setNote(e.target.value)}}
            />
            {error?
             <p className='text-danger'>Please enter your note</p>:''}
          </Col>

          </Form.Group>
              
              </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
          {
            loader ? <Button variant="primary" className="as_btn" >
              Processing...
            </Button> : <Button variant="primary" className="as_btn" onClick={handleNotesAdd}>
              Add
            </Button>
          }
          <Button variant="secondary" className="as_btn reset-btn" onClick={handleCloseNote}>
            Close
          </Button>
        </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>
          {notesData && notesData?.notes?.length > 0?<>
          {notesData?.notes && notesData?.notes.length>0 && notesData?.notes?.map(( item ,index) =><>
            <Row>
                     { item?.source && item?.source != "" ?  
                          <Col xs={5}>
                              <span className="aa">Source :- </span>
                                <span className="item-msg">{item.source ? item.source : "" } </span>
                          </Col> : ""
                      }

{item?.source && item?.source !== "" && (
    (item.source === "Leads-Chat-Orders" || item.source === "Leads-Call-Orders") ? (
        item?.sourcename && item.sourcename !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chat-Orders") ? (
                    <span className="aa">Chat Id :- </span>
                ) : (
                    <span className="aa">Call Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Chadhawa-List" || 
         item.source === "Leads-Manokamna-List" || 
         item.source === "Marketing-Chadhawa-List" ||
         item.source === "Marketing-Manokamna-List") ? (
        item?.sourceid && item.sourceid !== "" && (
            <Col xs={7}>
                {(item.source === "Leads-Chadhawa-List" || item.source === "Marketing-Chadhawa-List") ? (
                    <span className="aa">Chadawa Id :- </span>
                ) : (
                    <span className="aa">Manokmana Id :- </span>
                )}
                <span className="item-msg">
                    {item?.sourceid?.length <= 50 ? item?.sourceid : `${item?.sourceid?.substring(0, 40)}...`}
                </span>
            </Col>
        )
    ) : (item.source === "Leads-Puja-Booking" || 
         item.source === "Leads-Puja-Suggestion" ||
         item.source === "Marketing-Puja-Booking" ||
         item.source === "Marketing-Puja-Feedback" ||
         item.source === "Marketing-Puja-Suggestion") ? (
        item?.sourcename && item.sourcename !== "" && item?.sourceid && (
            <Col xs={7}>
                <span className="aa">Puja Name :- </span>
                <span className="item-msg">
                    <Link to={`/shared-puja?pujaId=${item?.sourceid}`} state={{permissioncontext:permissioncontext}}>
                        {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                    </Link>
                </span>
            </Col>
        )
    )
                       :
                              item.source === 'Marketing-Reports-Inquiry'
                              || item.source === "Leads-Reports-Inquiry" ?
                              <Col xs={7}>
                                    <span className='aa'>Reports Name :- </span>
                                    <span className='item-msg'>
                                      
                                        {item?.sourcename?.length <= 50
                                          ? item?.sourcename
                                          : `${item?.sourcename?.substring(0, 40)}...`}
                                    </span>
                                  </Col> : null
)}

                </Row>
        <Row className="notes-item">
              <Col xs={9}>
                  <span className="item-name aa">
                                  Note :- 
                  </span>
                  <span className="item-msg">{item?.message}</span>
              </Col>
              <Col xs={3}>
                <div className="text-end d-flex flex-column">
                <span className="text-primary item-name aa">{item?.responderName || item?.adminName}</span> 
                <span className="item-text">{ item?.createdAt? moment(item?.createdAt).format('DD MMM, YYYY hh:mm A') :''}</span>
                </div>
              </Col>
            </Row>
          </>

          )}</>:<><h4 className='text-center text-secondary'>Not Available</h4></>}
        </Modal.Body>
     
      </Modal>
    </div>:
    <Notauthorized />
    }

    </>
  );
}

export default PujaFeedback;
