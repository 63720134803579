import { Row, Col, Button, Container, Modal, Form } from 'react-bootstrap';
import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { FaEdit, FaTrash, FaMinus, FaPlus } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import Select, { components } from 'react-select';

const SelectPanditModule = ({ show, dropdownData,titleDropdown, handleClose, pujaId,pujaData, adminUser,fetchPujaDetails, selectedPanditExpense }) => {
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const [price, setPrice] = useState('');
  const handleReset=()=>{
    setPrice('')
    setSelectedOption('')
  }

  // select pandit by id --

  // const [dropdownData, setDropdownData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  // useEffect(() => {
  //   titleDropdown();
  // }, []);
  
  // const titleDropdown = async () => {
  //   const response = await APIServices.get(`pandit/getPanditList`,headers);
  //   if (response?.status === 200) {
  //       setDropdownData(response?.data?.Listdata);
  //   }
  // };

  const handleSelectChange = selected => {
    setSelectedOption(selected); 

    if (selected?.value) {
        const updatedErrors = { ...errors };
        delete updatedErrors.selectedOption; 
        setErrors(updatedErrors);
      }
  };

  const options = dropdownData.map(item => ({
    value: item._id, 
    label: item.name,
  }));

  const validateInputs = () => {
    const newErrors = {};

    if (!selectedOption?.value || selectedOption?.value.trim() === '') {
      newErrors.selectedOption = 'Please select pandit.';
    }
    if (!price || price?.trim() === '') {
      newErrors.price = 'Please enter Price.';
    }
    if (parseFloat(price) <= 0) {
      newErrors.price = 'Please enter a valid Price greater than 0.';
    }

    return newErrors;
  };


  const handleSubmit = async e => {
    e.preventDefault();

    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('name', selectedOption?.label);
    formData.append('panditId', selectedOption?.value);
    formData.append('price', price);
    formData.append('pujaId', pujaId);
    formData.append('userId', adminUser);
    
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    // return
    try {
      const response = await APIServices.post('pandit/addPujaPandit', formData,headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false);
        handleClose()
        handleReset();
        fetchPujaDetails()
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div>
      <Modal
        centered
        show={show}
        // size='xl'
        size={`${pujaData?.pujaPandit === null} ? 'xl' : 'lg`}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={() => {
          handleClose();
            handleReset();
          setErrors({});
        }}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title>{pujaData?.pujaPandit === null ? "Puja Pandit" : "Pandit Info"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {( pujaData?.pujaExpencese || pujaData?.pujaPandit) ? 
           (
                  <Row>
                     <div className='row align-items-start gy-1 mb-2'>
                          <div className='col-md-6 d-flex justify-content-between align-items-start'>
                            <p className='my-1 w-30 fw-semibold'>Pandit Name :</p>
                            <p className='my-1 w-70'>{pujaData?.pujaPandit?.name ?? pujaData?.pujaExpencese?.panditName}</p>
                          </div>
                          <div className='col-md-6 d-flex align-items-start'>
                            <p className='my-1 fw-semibold me-2'>Price  Per Puja  :</p>
                            <p className='my-1 '>₹{pujaData?.pujaPandit?.price ?? pujaData?.pujaExpencese?.panditPrice}.00</p>
                          </div>
                      </div>
                  </Row>
           ) 
           :
           (
            <Form
                className='add_blog_form br-6'
                  // onSubmit={e => handleSubmit(e)}
              >
                <Row>
                  <div className=''>
                    <Row>
                      <Col sm={6} className='mt-3'>
                        <Form.Group as={Row} controlId='formGlobalPrice'>
                          <Form.Label className='required' column sm='3'>
                            Select Pandit
                          </Form.Label>
                          <Col sm='9'>
                            <Select
                            classNamePrefix="react-select"
                              options={options}
                              value={selectedOption}
                              onChange={handleSelectChange}
                              placeholder='Search by Pandit...'
                              isClearable
                            //   isInvalid={!!errors?.selectedOption}
                            />
                            {!!errors?.selectedOption && (
                          <p className='errorVal'>
                              {errors?.selectedOption}  
                          </p>
                          )}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col sm={6} className='mt-3'>
                        <Form.Group as={Row} controlId='formGlobalPrice'>
                          <Form.Label className='required' column sm='3'>
                            Price Per Puja
                          </Form.Label>
                          <Col sm='9'>
                            <Form.Control
                              type='number'
                              placeholder='Price ...'
                              name='price'
                              value={price}
                              isInvalid={!!errors?.price} 
                              onKeyPress={evnt => {
                                if (!/^\d$/.test(evnt.key) && evnt.key !== '.' && evnt.key !== 'Backspace') {
                                  evnt.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setPrice(e.target.value);
                                setErrors((prevErrors) => ({ ...prevErrors, price: '' }));
                              }}
                            />
                            <Form.Control.Feedback type='invalid'>
                              {errors?.price}
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row></Row>
                  </div>
                  {/* <div className='text-center mt-4'>
                    <Button variant='primary' type='submit' className='as_btn'>
                      {loading ? 'Loading...' : 'Add'}
                    </Button>
                  </div> */}
                </Row>
              </Form>
           )
        }
        </Modal.Body>
        <Modal.Footer>
          {(pujaData?.pujaExpencese?.expenceseItem || pujaData?.pujaPandit) ? "" : (
                   <Button variant='primary' type='submit' onClick={handleSubmit} className='as_btn'>
                      {loading ? 'Loading...' : 'Add'}
                    </Button>
          )}
                  <Button
                    variant='secondary'
                    className='cancle_btn'
                    onClick={() => {
                      handleClose();
                        handleReset();
                      setErrors({});
                    }}
                  >
                    Close
                  </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectPanditModule;
