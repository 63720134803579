import {
  ToastProvider,
  DefaultToastContainer,
} from "react-toast-notifications";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import { UserContextProvider } from "./context/UserContextProvider";

import AppRouter from "./AppRouter";

const App = () => {
  const ToastContainer = (props) => (
    <DefaultToastContainer
      className="toast-container"
      style={{ zIndex: 10000, top: 50 }}
      {...props}
    />
  );
  return (
    <div className="App">
      <UserContextProvider>
        <ToastProvider
          autoDismiss={true}
          autoDismissTimeout={3000}
          components={{ ToastContainer }}
        >
          <AppRouter />
        </ToastProvider>
      </UserContextProvider>
    </div>
  );
};

export default App;
