import React, { useEffect, useState, useRef ,useContext } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { getCookies ,setCookies } from "../../utils/Helper";
import APIServicenew from "../../utils/APIGeneralService";
import Post from "./post";
import { Col, Container, Row ,Form ,Button} from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

import usePermissionData from "../../components/Customhook/custompermission";
import Notauthorized from "../../components/Notauthorized";

const Feeds = () => {
  document.title = 'Feeds';
  const permissioncontext =usePermissionData("feeds")
  const [loading, setLoading] = useState(false)
  const [posts, setPost] = useState([])
  const accessToken = getCookies('accessToken')
  const listInnerRef = useRef();
  const [spinner, setSpinner] = useState({
    mode: false,
    noMore: false
  })
  // const [page, setPage] = useState(1)
  const [reached, setReached] = useState(false)
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(12);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [allDataCount , setAllDataCount] = useState([])
  const [data, setData] = useState();

  const [startDate , setStartDate] = useState("")
  const [ endDate, setEndDate] = useState("")
  const [seletOne, setSelectOne] = useState("")
  const [mostLiked , setMostLiked] = useState("")
  const [mostCommented ,setMostCommented] = useState("")
  const [select ,setSelect ] = useState("")
  const [reported ,setReported] = useState("")
  const [ flag , setFlag] = useState(false)
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

  const headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + accessToken
  }

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const getPosts = async () => {
    const posts = await APIServices.get(`/admin/post/list/${offset}/${perPage}/all?postBy=${seletOne}&startDate=${startDate}&endDate=${endDate}&select=${select}`, headers)
     let data = posts?.response?.data

    if (posts?.data?.status) {
      // setPost(prev => ([...prev, ...posts.data.list]))
      setPost([...posts.data.list])
      setData([...posts.data.list]);
      setAllDataCount(posts?.data?.total_count)
      setLoading(false)
      setReached(false)
      setFlag(false)
    } else {
      // setSpinner({ mode: false, noMore: true })
      setReached(true)
        if(data?.status == 4){
          logout()
         }
      
    }
  }
  
  useEffect(() => {
    if(posts && posts.length > 0){
      setLen(allDataCount);
      setPageCount(Math.ceil(allDataCount / perPage));
      
      // setData(posts);
    }
  }, [posts])

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = (selectedPage * perPage) % len;
    setOffset(newOffset)
    window.scrollTo({ top: 0 });
  }
  
  const handleFilterClick =() =>{

   if (seletOne ||startDate||endDate||select || offset) {
    getPosts()
  }
  }

  const handleStartDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
  };

  const handleEndDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
  };

  const resetData = () =>{
    setSelectOne("")
    setSelect("")
    setStartDate("")
    setEndDate("")
    setOffset(0)
    setFlag(true)
  }
  // const onScroll = () => {
  //   if (reached) return
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       setSpinner({ mode: true, noMore: true })
  //       setTimeout(() => {
  //         setPage(prev => prev + 1)
  //       }, 500)
  //     }
  //   }
  // };

  // onScroll={() => onScroll()} ref={listInnerRef}
   useEffect(()=>{
  getPosts()
},[flag])
  
  useEffect(() => {
    getPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  if (loading) {
    return (
      <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"><Spinner animation="border" /></div>
    )
  }

 
  
  return (<>
  {
    permissioncontext.view?
    <>
    <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Feeds <small>({allDataCount})</small></h4>
           </div>
        </div>
        <div className="chat_window">
    <Row className="mlist-1">
    <div class="feed_area">
    <div class="chat_search_box">
      <div className="chat-search-field">
               <Row>
               <Form>
               <Row>
                <Form.Group as={Row} className="mb-4 align-items-center"  controlId="">
                  <Form.Label class="label-f-w-1  mt-2">Start And End Date</Form.Label>
                 <Col md={2}>
                  {/* <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="date"
                    type="date"
                    placeholder="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  /> */}
                  <DatePicker
                        placeholderText={"Select a starting date"}
                        className="form-control form-ch fil-date-pick mt-2"
                        name="date"
                        selected={startDate ? new Date(startDate) : null}
                        onChange={(date) => {
                          handleStartDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                  />
                  </Col>
                 <Col md={2}>
                 {/* <Form.Control
                   className="hd-cr mt-2 form-ch"
                   name="date"
                   type="date"
                   placeholder="date"
                   value={endDate}
                    onChange={(e) => setEndDate( e.target.value)}
                 /> */}
                  <DatePicker
                        placeholderText={"Select a ending date"}
                        className="form-control form-ch fil-date-pick mt-2"
                        name="date"
                        selected={endDate ? new Date(endDate) : null}
                        onChange={(date) => {
                          handleEndDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                  />
                 </Col>
          <Col md={2}>
          <Form.Select aria-label="label-selected-menu" 
          className="fw-bold label-selected-menu text-capitalize mt-2 w-50 hd-cr"
          name =""
          value={seletOne}
          onChange={(e) => setSelectOne(e.target.value)}
          >
               <option className="fw-bold" value="">
               Posted By
                </option> 
                <option className="text-capitalize" value="user">
                customer
                </option>      
                <option className="text-capitalize" value="astrologer">
                astrologer
                </option>
                <option className="text-capitalize" value="admin">
                admin
                </option>
                </Form.Select>
          </Col>

          <Col md={2}>
          <Form.Select aria-label="label-selected-menu" 
          className="fw-bold label-selected-menu text-capitalize mt-2 w-50 hd-cr"
          name =""
          value={select}
          onChange={(e) => setSelect(e.target.value)}
          >
               <option className="fw-bold" value="">
               select Option
                </option> 
                <option className="text-capitalize" value="mostLiked">
                Most Liked
                </option>      
                <option className="text-capitalize" value="mostCommented">
                Most Commented
                </option>
                <option className="text-capitalize" value="reported">
                Reported
                </option>
                </Form.Select>
          </Col>
          
          <Button variant="secondary"
          className="as_btn"
          onClick={()=> {handleFilterClick();setOffset(0)}}>
          Filter
        </Button>
        <Button variant="secondary"
          className="as_btn reset-btn"
          onClick={()=> {resetData();setOffset(0)}}>
          Reset
        </Button>
          </Form.Group>
          </Row>
          </Form>
          </Row>
      </div>
    </div> 
    </div>
    <Container>
        <div className="postContainer row" >
          {
            posts.map((post, index) => {
              return (
                <Col md={6}  lg={4} xl={3} xxl={3}>
                    <div key={post._id}>
                      <Post postData={post} index={index + 1} perPage={perPage} offset={offset} setOffset={setOffset} getPosts = {getPosts} canDelete={permissioncontext.delete} canAction={permissioncontext.edit} />
                    </div>
                </Col>
              )
            })
          }

          <Container className="m-0 p-0">
            <Row>
              <Col>
                {
                  spinner.mode ?
                    <div className={`d-flex ${posts.length || 'h-100 align-items-center'} justify-content-center my-2 w-100`}><Spinner animation="border" /></div>
                    :
                    spinner.noMore &&
                    <div className={`primaryColor d-flex ${posts.length || 'h-100 align-items-center'} justify-content-center my-2 w-100`}>No more posts !</div>
                }
              </Col>
            </Row>
          </Container>
        </div>
  </Container>
  </Row>
  
  <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
  </div></>:
  <Notauthorized />
  }
    
  </>);
}


export default Feeds;
