import { useEffect, useState, useContext } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContextProvider';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import bimg from '../../src/imgs/banner_background.png';
import APIServicenew from '../utils/APIGeneralService';
import { setCookies } from '../utils/Helper';
import moment from 'moment';

import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

const MediaGallery = () => {
  document.title = 'Media Gallery';
  const permissioncontext =usePermissionData("mediaGallery")
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(12);
  const [loading, setLoading] = useState();
  const [type, setType] = useState('all');
  const [file1, setFile1] = useState('');
  const [video, setVideo] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataCount, setAllDataCount] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  // const [showcrop, setShowCrop] = useState(false);
  // const [cropimg, setCropImg] = useState("");
  // const [caartifileimage,setFileCaartiimage]=useState()
  const [imagePreview, setImagePreview] = useState();
  const [dropDownList, setDropDownList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueNew, setSelectedValueNew] = useState('');
  const [addNewValue, setAddNewValue] = useState('')

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };
  useEffect(()=>{
    getGalleryFolder()
  },[selectedValueNew])

  useEffect(() => {
    fetchData();
  }, [offset, type,selectedValueNew]);

  async function fetchData() {
    setLoading(true);
    let response = await APIServices.get(`mediaGallery/getAll/${offset}/${perPage}?type=${type}&folderName=${selectedValueNew}`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata);
        setAllDataCount(apiData.totalCount);
        setLoading(false);
      }
    } else {
      if (data.status == 4) {
        logout();
      }
    }
  }

  const handleClose = () => {
    setErrors('');
    setFile1('');
    setImagePreview('');
    setVideo('');
    setSelectedValue("")
    setSelectedValueNew('')
    setAddNewValue('')
    // setFileCaartiimage("")
    setShowModal(false);
  };

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!file1 && file1 === '' && !video && video === '') newErrors.video = 'Please upload image or video file.';
    if (!selectedValue ) {
      newErrors.folderName = 'Please select a value from folder'
    }

    let maxLength = 10
 
    if (imagePreview && imagePreview.length > maxLength ) 
    newErrors.file = "Images can't be more than 10";
 
     if(selectedValue === 'addNew'){
       if ( !addNewValue || addNewValue.trim() === "") {
         newErrors.addNewValue = 'Please fill a value'
       }
     }
    return newErrors;
  };

  const getGalleryFolder=async()=>{
    let response = await APIServices.get(`mediaGallery/getGalleryFolder`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setDropDownList(apiData?.data)
      }
    }
  }

  async function handleCreate() {
    setLoader(true);
  
    // Validate inputs
    const newErrors = validateInputs();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
      return;
    }
  
    try {
      if (file1 && file1.length > 0) {
        let allSuccessful = true; // To track if all uploads are successful
        let failedUploads = []; // To track any failed uploads
        // Handle file uploads
        for (const file of file1) {
          let payload = new FormData();
          payload.append('file', file);
          payload.append('folderName', selectedValue === 'addNew' ? addNewValue : selectedValue);
       const response = await APIServices.post(
            'mediaGallery/upload/file',
            payload,
            { ...headers, 'Content-Type': 'multipart/form-data' },
            (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted);
            }
          );
  
          // if (response?.status === 200) {
          //   const apiData = response?.data;
          //   if (apiData?.status === true) {
          //     toast.success(apiData?.message);
          //   } else {
          //     toast.error(apiData?.message);
          //   }
          // }
          
          // else {
          //   toast.error("Failed to upload file");
          // }
          if (response?.status === 200) {
            const apiData = response?.data;
            if (apiData?.status !== true) {
              allSuccessful = false; 
              failedUploads.push(file.name);
            }
          } 
          else {
            allSuccessful = false;
            failedUploads.push(file.name);
          }
        }
        if (allSuccessful) {
          toast.success("Uploaded Successfully!!");
        } else {
          toast.error("Failed to upload");
        }
      } else {
        // Handle video upload
        let payload = new FormData();
        payload.append('video', video);
        console.log("video" ,video);
        payload.append('folderName', selectedValue === 'addNew' ? addNewValue : selectedValue);
  
        const response = await APIServices.post(
          'mediaGallery/upload/video',
          payload,
          { ...headers, 'Content-Type': 'multipart/form-data' },
          (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          }
        );
  
        if (response?.status === 200) {
          const apiData = response?.data;
          if (apiData?.status === true) {
            toast.success(apiData?.message);
          } else {
            toast.error(apiData?.message);
          }
        } else {
          toast.error("Failed to upload video");
        }
      }
  
      // Reset state
      setShowModal(false);
      setVideo('');
      setFile1([]);
      setDropDownList([]);
      setImagePreview('');
      setSelectedValue("");
      setSelectedValueNew('')
      setAddNewValue('');
      fetchData();
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error("An error occurred during the upload.");
    } finally {
      setLoader(false);
    }
  }

  const handleCloseDt = () => setShowDt(false);
  const handleShowDt = id => {
    setShowDt(true);
    setSelectedId(id);
  };
  const handledelete = async () => {
    let res = await APIServices.delete(`mediaGallery/findByIdAndremove/${selectedId}`, headers);
    if (res?.status === 200) {
      setShowDt(false);
      setOffset(0);
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  };
  function copy(text) {
    navigator.clipboard.writeText(text);
    toast.info('copied');
  }

  function handleChange(e, field) {
    let key, value;
    key = e?.target?.name;
    if (field === 'file') {
      value = e?.target?.files?.[0];
    } else if (key === 'video') {
      value = e?.target?.files?.[0];
      setVideo(value);
    }
    else if(key === 'folderName'){
      setSelectedValue(e.target.value)
      setAddNewValue('')
    }
    else if(key === 'addNewValue'){
      setAddNewValue(e.target.value)
    }
    
    if (!!errors[key === 'file' ? 'video' : key]) {
      setErrors({
        ...errors,
        [key === 'file' ? 'video' : key]: null,
      });
    }
  }
  const onImageChange = event => {
  var fileObj = [];
  var arrobj = [];

  if (event.target.files && event.target.files.length > 0) {
    // Check if the number of selected files exceeds 10
    if (event.target.files.length > 10) {
      alert("You can only select up to 10 images.");
      return;
    }

    setFile1(event.target.files);
    fileObj.push(event.target.files);
    for (let i = 0; i < fileObj[0]?.length; i++) {
      arrobj?.push(URL.createObjectURL(fileObj[0][i]));
    }
    setImagePreview(arrobj);
  }

  let key, value;
  key = event?.target?.name;
  if (!!errors[key]) {
    setErrors({
      ...errors,
      [key]: null,
    });
  }
};

  // useEffect(()=>{
  //   if(caartifileimage){
  //     onImageChange(caartifileimage)
  //   }
  // },[cropimg,caartifileimage])

 

  return (
    <>
    {
      permissioncontext.view?
<div className='main gallery-media aarti_section'>
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              Media Gallery <small>({allDataCount})</small>
            </h4>
            <div className='d-flex align-items-center justify-content-between'>
               <div className=''>
                <Form.Group as={Row} className='mb-1' controlId='formTitleInput'>
                    <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize w-100 hd-cr mt-2 form-ch drodown_btn'
                      value={selectedValueNew}
                      // onChange={(e)=>handleChange(e)}
                      onChange={(e)=> setSelectedValueNew(e.target.value)}
                      name='folderName'
                    >
                      <option value=''>Select By Folder</option>
                      {dropDownList && dropDownList?.length>0 &&  dropDownList
                        .slice()  
                        .sort((a, b) => a.localeCompare(b))  
                        .map((item,ind)=>{
                        return <option key={ind} value={item}>{item}</option>
                      })}
                    </Form.Select>
                  
                </Form.Group>
              </div>
              <div className='_btn ms-5'>
                <Button
                  className='by-btn as_btn me-0'
                  variant='primary'
                  disabled={!permissioncontext.add}
                  onClick={() => {
                    setShowModal(true);
                    // getGalleryFolder()
                  }}
                >
                  Add Media
                </Button>
                {/* &nbsp;&nbsp; */}
              </div>
            </div>
          </div>
        </div>
        <div className='aarti_inner pt-4'>
          <div className='container box-shadow br-6 p-20'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <div className='row py-2'>
                      <div className='container'>
                        <div className='d-flex align-items-center justify-content-between '>
                          <h6 className='hd-list-f'>
                            <span
                              className={type === 'all' ? 'left-q hd-cr borderRoute' : 'left-q hd-cr'}
                              onClick={e => {
                                setType('all');
                                setOffset(0);
                              }}
                            >
                              {' '}
                              All
                            </span>
                            <span
                              className={type === 'image' ? 'left-q hd-cr borderRoute' : 'left-q hd-cr'}
                              onClick={e => {
                                setType('image');
                                setOffset(0);
                              }}
                            >
                              Images
                            </span>
                            <span
                              className={type === 'video' ? 'left-q hd-cr borderRoute' : 'left-q hd-cr'}
                              onClick={e => {
                                setType('video');
                                setOffset(0);
                              }}
                            >
                              Videos
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <hr style={{ color: 'black' }} />
                    <div className='row my-3'>
                      {allData?.map(item => (
                        <div className='col-md-6 col-lg-4 col-xl-3' key={item?._id}>
                          <div className='card mb-3 Reels_video'>
                            <div className='card-header'>
                              {item?.file !== null ? (
                                <img src={item?.file ? item?.file : bimg} className='card-img-top' alt='img' />
                              ) : (
                                <iframe
                                  title='astrowatch-content'
                                  width='560'
                                  height='242'
                                  className='w-100'
                                  src={item?.video ? item?.video : bimg}
                                  type='video/mp4'
                                  autoPlay
                                  controls
                                ></iframe>
                              )}
                             <div className='icon'>
                                <FaTrash
                                  className={permissioncontext.delete?'dt-btn hd-cr ':'dt-btn hd-cr disabled-btn'}
                                  size={20}
                                  onClick={e => {
                                    if( permissioncontext.delete )
                                    handleShowDt(item?._id);
                                  }}
                                />
                              </div>
                            </div>
                            <div className='card-body '>
                              <p
                                style={{ textAlign: 'center', fontSize: '15px' }}
                                className='description font-500 mb-2'
                              >
                                <span className='calicon'>
                                  <i className='ri-calendar-line'></i>
                                </span>
                                {/* {new Date(item.createdAt).toDateString()} */}
                                {moment(item.createdAt).format("DD MMM, YYYY hh:mm A")}
                              </p>
                            </div>
                            <div className='card-footer mg-footer pt-2'>
                              <p className='mb-0' style={{ fontSize: '14px', color: '#767c90' }}>Click to copy</p>
                              <p
                                type='button'
                                className='link_copy_cs my-1'
                                data-toggle='tooltip'
                                title='Click to copy'
                                onClick={() => copy(item?.video !== null ? item?.video : item?.file)}
                              >
                                {item?.video !== null ? item?.video : item?.file}
                              </p>
                              {item?.folderName ? 
                                  <>
                                    <div className='d-flex align-items-center flex-wrap gap-2 mb-2'>
                                      <span style={{ fontSize: '12px', color: '#767c90' }}>Folder Name :- </span>
                                      <span style={{ fontSize: '12px', color: '#767c90', fontWeight:"bold" }}>{item?.folderName}</span>
                                    </div>
                                  </>
                                :""
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {allData && allData.length > 0 ? (
                  <div className='pagination'>
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='Next>'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(allDataCount / perPage)}
                      previousLabel='< Previous'
                      renderOnZeroPageCount={null}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}
                      forcePage={offset / perPage}
                    />
                  </div>
                ) : (
                  <h2 className='no_records'>Records not Available</h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>:
      <Notauthorized />
    }
      

      <Modal
      centered
      scrollable
        show={showModal}
        backdrop='static'
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='' column sm='3'>
                Images
              </Form.Label>
              {/* <label id='labimg'>
                <img className='imgs' src={imagePreview ? imagePreview : upimg} width='100%' alt='' /> */}
              <Col sm='9'>

                <Form.Control
                  type='file'
                  name='file'
                  className='form-control imgs '
                  onChange={e => {
                    onImageChange(e);
                    handleChange(e);
                  }}
                  // defaultValue={file1}
                  isInvalid={!!errors?.file}
                  size='40'
                  accept='image/png, image/gif, image/jpeg, image/webp'
                  // id='inputds'
                  multiple
                />
                <Form.Control.Feedback type='invalid'>{errors?.file}</Form.Control.Feedback>
                {imagePreview?
                <div className="gallery">
                {imagePreview
                    ? imagePreview?.map((item, key) => (
                        <img key={key} width="60px" height="60px" src={item} alt="" />
                      ))
                  : ""}
                </div>:""}
                <small>Maximum Limit 10</small>
              </Col>
              {/* </label> */}
            </Form.Group>

            <div className='text-center mb-2 fw-bold text-danger'>OR</div>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm='3'>
                Video
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='file'
                  name='video'
                  accept='video/*'
                  className='is-invalid-Q form-control '
                  defaultValue={video}
                  isInvalid={!!errors?.video}
                  onChange={e => handleChange(e)}
                />
                <Form.Control.Feedback type='invalid'>{errors?.video}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm='3' className='required'>
                Select Folder
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                  value={selectedValue}
                  onChange={(e)=>handleChange(e)}
                  isInvalid={!!errors?.folderName}
                  name='folderName'
                >
                  <option value=''>Select Folder</option>
                  <option value='addNew'>Add New</option>
                  {dropDownList && dropDownList?.length>0 && dropDownList
                        .slice()  
                        .sort((a, b) => a.localeCompare(b))  
                        .map((item,ind)=>{
                    return <option key={ind} value={item}>{item}</option>
                  })}
                </Form.Select>
                {/* {dropdownError && <div style={{ color: 'red' }}>{dropdownError}</div>} */}
                <Form.Control.Feedback type='invalid'>{errors?.folderName}</Form.Control.Feedback>
              </Col>
              {selectedValue === 'addNew' && (
                  <Form.Group as={Row} className='mb-3' controlId='formNewInput'>
                    <Form.Label column sm='3'>
                      {/* Dropdown */}
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        type='text'
                        onChange={(e)=> handleChange(e)}
                        placeholder='Enter new folder name'
                        className='fw-bold text-capitalize w-50 ms-2 mt-2 form-ch'
                        name='addNewValue'
                        isInvalid={!!errors?.addNewValue}

                      />
                      <Form.Control.Feedback className='ms-2' type='invalid'>{errors?.addNewValue}</Form.Control.Feedback>

                    </Col>

                  </Form.Group>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loader ? (
            <Button variant='primary' className='as_btn'>
              {progress === 100 ? 'Processing...' : `Uploading(${progress}%)...`}
            </Button>
          ) : (
            <Button variant='primary' className='as_btn' onClick={e => handleCreate(e)}>
              Add
            </Button>
           )} 
          <Button
            variant='secondary'
            className='as_btn reset-btn'
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <ToastContainer /> */}
      {/* <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/> */}
    </>
  );
};

export default MediaGallery;
