import { useEffect, useState,useContext } from "react";
import { Button, Modal, Row, Form, Col ,OverlayTrigger ,Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { FaEdit, FaTrash ,FaCopy} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios'
import { Editor } from "@tinymce/tinymce-react";
import Cookies from "universal-cookie";
import bimg from "../../src/imgs/default.jpg";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import CropModal from "../components/Shared/CropModal";
import { getBaseURL ,setCookies } from "../utils/Helper";
import { SlugifyStringWithDash } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

import {  } from "../utils/Helper";
import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

const SiddhaPurush =  () =>{
  document.title = 'SiddhaPurush';
  const permissioncontext =usePermissionData("mysteriousTemple")

    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)

    const headers = { Authorization: `Bearer ${token}` };
    let astroUserId = cookies.get("astroUserId");
    const  {refreshToken} = useContext(UserContext);
    const navigate = useNavigate();
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(6);
    const [pageCount, setPageCount] = useState(0);
    const [len, setLen] = useState();
    const [loading, setLoading] = useState();
    const [ title ,setTitle] = useState("")
    const [ description ,setDescription] = useState("")
    const [imageType, setImageType] = useState("english");
    const [allData , setAllData] = useState([])
    const [ contentType , setContentType] =  useState("")
    const [ file , setFile] = useState ("");
    const [ image , setImage] = useState("");
    const [ imgShow , setImgShow] = useState("");
    const [show, setShow] = useState(false);
    const [showG, setShowG] = useState(false);
    const [showType , setShowType] = useState()
    const [video ,setVideo] = useState("");
    const [url ,setUrl] = useState("")
    const [showdt, setShowDt] = useState(false);
    const [data, setData] = useState();
    const [errors, setErrors] = useState({});
    const [ err ,setErr] = useState()
    const [selectedId, setSelectedId] = useState()
    const [slug,setSlug]=useState()
    const [address ,setAddress] = useState()
    const [content ,setContent] = useState()
    const [allDataCount , setAllDataCount] = useState()
    const Navigate = useNavigate();
    const [showcrop, setShowCrop] = useState(false);
    const [cropimg, setCropImg] = useState("");
    const [caartifileimage,setFileCaartiimage]=useState()
    const[loader,setLoader]=useState(false)

    const logout =() =>{
      refreshToken(null)
      setCookies("accessToken", null);
      navigate("/");
    }
    const onImageChange = (event) => {
      setImage(event);
          setFile(event);
      };
    
      const handleClose = () =>{ 
        setShow(false)
        setShowG(false)
        setErrors("")
        // setContentType("")
        setImage("")
        setFile("")
        setVideo("")
        setUrl("")
        setErr("")
      }
      const handleCloseDt = () => setShowDt(false);
      
      const handleShowDt = (id) => {
        setSelectedId(id);
        setShowDt(true);
      };
      const reset= () =>{
        setImage("")
        setFile("")
        setVideo("")
        setUrl("")
      }
       const handleShow = async (item) => {
        setShow(true);
        setImage("")
        setFile("")
        setSelectedId(item?._id);
        setTitle(item?.title)
        setSlug(item?.slug)
        setAddress(item?.address)
        setContent(item?.content)
        // setContentType(item?.contentEn === true ?"english" : item?.contentHi === true ? "hindi" :"")
        setImgShow(item?.file);
        setDescription(item?.description)
      };
    
      const handleclick = async (status, id) => {
        var datas = status === true ? false : true;
        const formData = new FormData();
        formData.append("status", datas);
        await APIServices.put(`siddhaPurush/updateStatus/${id}`, formData, headers)
          .then((res) => {
            updateFile()
          })
          .catch((err) => {
            console.log(err);
          });
      };
      const fetchData = async () => {
         setLoading(true);
      let response = await APIServices.get(`siddhaPurush/getAll/${imageType}/${offset}/${perPage}`, headers);
      let data = response?.response?.data
       
      if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            setData(apiData?.Listdata);
           
            setAllDataCount(apiData.totalCount)
            setLoading(false);
          }
        
    }else{
      if(data?.status == 4){
        logout()
       }
    }
      };

      async function updateFile(showToast) {
        let res = await APIServices.get(`siddhaPurush/updateFile`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          if (apiData?.status) {
            if (showToast) {
              toast.success(apiData?.message);
            }
            fetchData();
          }
        }
      }


      const handlePageClick = (e) => {
        const selectedPage = e.selected;
        console.log(selectedPage * perPage);
        const newOffset = (selectedPage * perPage);
        setOffset(newOffset);
      };

      const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
      
          if (!title ||title === "") 
          newErrors.title = "Please enter title.";
    
          if (!slug ||slug === "") 
          newErrors.slug = "Please enter slug.";

          if (!address ||address === "") 
          newErrors.address = "Please enter address.";

          if (!description ||description === "") 
          newErrors.description = "Please enter description.";
  
          if (!content ||content === "") 
          newErrors.content = "Please enter content.";

          if (file === "" && (!imgShow || imgShow === "")) 
          newErrors.file = "Please add image.";
    
          // if (!contentType ||contentType === "") 
          // newErrors.contentType = "Please enter content type."; 
    
          return newErrors;
      }; 

       async function handleEdit() {
        const formData = new FormData();
        if (file) {
          formData.append("file", file);
        }
         formData.append("title" , title)
        //  formData.append("slug" , slug)
         formData.append("address" , address)
         formData.append("description" , description)
         formData.append("content" ,content)
        // if (contentType === "english") {
        //   formData.append("contentEn", true);
        //   formData.append("contentHi", false);
    
        // } else if (contentType === "hindi") {
        //   formData.append("contentHi", true);
        //   formData.append("contentEn", false);
        // }
        formData.append("userId", astroUserId);
    
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setLoader(false)
        }else {
          let response = await APIServices.put(`siddhaPurush/editOne/${selectedId}`, formData, headers);
          if (response?.status === 200) {
            let apiData = response?.data;
            if (apiData?.status === true) {
              setShow(false);
              toast.success(apiData?.message);
              setCropImg();
              updateFile();
              setLoader(false)
              setFile('');
            }else{
              toast.error(apiData?.message)
              setLoader(false)
            }
          }else if(response?.status === 400){
            let errData = response?.data;
            if(errData && errData.message){
              toast.error(errData?.message);
            }
          }
          setLoader(false)
        }
      }

      async function handleCreate() {
        const formData = new FormData();
        formData.append("title" , title)
        formData.append("slug" , slug)
        formData.append("address" , address)
        formData.append("description" , description)
        formData.append("content" ,content)
        formData.append("file", file);
        // if (contentType === "english") {
        //   formData.append("contentEn", true);
    
        // } else if (contentType === "hindi") {
        //   formData.append("contentHi", true);
        // }
        formData.append("userId", astroUserId);
    
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };

        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setLoader(false)
        }else {
          let response = await APIServices.post(`siddhaPurush/upload`, formData, headers);
          if (response?.status === 200) {
            let apiData = response?.data;
            if (apiData?.status === true) {
              setShow(false);
              toast.success(apiData?.message);
              setCropImg();
              updateFile();
              setLoader(false)
            }else{
              toast.error(apiData?.message)
              setLoader(false)
            }
          }else if(response?.status === 400){
            let errData = response?.data;
            if(errData && errData.message){
              toast.error(errData?.message);
            }
          }
          setLoader(false)
        }
      };
      
  const handleCloneHindi = async (id) => {
    const res = await APIServices.post(`siddhaPurush/hi/cloneById/${id}`, {userId: astroUserId}, headers)
    if(!res || (res && res.status !== 200)){
      toast.error("Something went wrong. Try Again!!", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return;
    }
    const data = res?.data
    if(data && data.status === false){
      toast.warn(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if(data && data.status && data.message){
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }
      const handledelete = async () => {
        let res = await APIServices.delete(`siddhaPurush/findByIdAndremove/${selectedId}`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          setShowDt(false);
          setOffset(0)
          if (apiData?.status) {
            toast.success(apiData?.message);
            updateFile();
          }
        }
      };
     
      const handleAddImgAndVideo = async() =>{
        const formData = new FormData();
        formData.append("siddhaPurush_id" ,selectedId)
        formData.append("type" ,showType)
        if(url){
          formData.append("file_url" , url)
        }
        if(file && typeof file === 'object'){
          formData.append("file" , file)
        }else if(video && typeof video === 'object'){
          formData.append("video" , video)
        }
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        setLoader(true)
        if (!file && !video && !url) {
          setErr(`Please upload ${showType.toLowerCase()} or ${showType.toLowerCase()} url.`)
        }else if(file || video || url){
        let response = await APIServices.post(`siddhaPurush/uploadGallery`, formData, headers);
        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status === true) {
            setShowG(false);
            toast.success(apiData?.message);
            reset()
            setCropImg();
            updateFile();
            setLoader(false)
          }else{
            toast.error(apiData?.message)
            setLoader(false)
          }
        }}
        setLoader(false)
      }



      function handleChange(e) {
        let key, value;
        key = e?.target?.name;
        if( e === "content") key = e
        if (!!errors[key]){
          setErrors({
            ...errors,
            [key]: null,
            });
        }
        setErr("")
      }
      useEffect(() => {
        fetchData();
      }, [offset ,imageType]);


      const TinymceEditor = () =>{
        return(
          <>
          <Form.Group as={Row} className="mb-3">
          <Form.Label className="required" column sm="3">
           Content
          </Form.Label>
          <Col sm="9">
          <Editor
          // apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
          apiKey={process.env.REACT_APP_TINY_EDITER_KEY}
          cloudChannel='5-stable'
          disabled={false}
          // initialValue=""
          value={content}
          inline={false} 
          onEditorChange={value => {setContent(value);handleChange("content")}}
          plugins=''
          tagName='div'
          textareaName=''
          toolbar=''
          outputFormat='html'
          init={{
              height: 300, 
              // language: '',
              //menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
      
                image_title: true,
                automatic_uploads: true,
                selector: 'textarea', 
                file_picker_types: 'file image',
                // images_upload_url: `${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`,
      
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement("input");
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*')
      
                  input.onchange = async function () {
                  var file = this.files[0];
                  let formdata = new FormData();
                  formdata.append("commonFile", file, file.name);
                  const res = await axios.post(`${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`, formdata, { headers: { "Content-Type": "multipart/form-data" } })  
                  if (res.status === 200) {
                    if (res?.data?.status === false) {
                      console.log('not 200', res?.data?.message);
                    }
                    else {
                      cb(res.data.path)
                    }
                  } else {
                    console.log('Failed in uploading BLOODY FILE')
                  }
                };
                input.click();
         }} 
        }
        />
       
        <span  className="errorVal">{errors?.content}</span>
        </Col>
        </Form.Group>
      </>
        )
      }
      
      const renderFormModalG = () => {
        return(
          <Modal show={showG} backdrop="static" onHide={()=>{handleClose(); setCropImg()}}>
          <Modal.Header closeButton>
         <Modal.Title>{`Add ${showType}`} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
            <Form.Group as={Row} className="mb-3">
            <Form.Label className="" column sm="3">{`${showType} Url`}</Form.Label>
            <Col sm="9">
              <Form.Control
                  type="text"
                  placeholder="Url..."
                  className="form-control "
                  value={url}
                  onChange={(e) => {setUrl(e.target.value);handleChange(e)}}
              />
              </Col>
                    </Form.Group>
            <div className='text-center mb-2 fw-bold text-danger'>OR</div>
              <Form.Group as={Row} className="mb-3">
                <Form.Label className="" column sm="3">{ `Upload ${showType}`} </Form.Label>

                {showType === "Image"?
                <Col sm="9">
                <label id="labimg">
              
              {cropimg?<img 
              className="imgs" 
              src={cropimg}
              onClick={()=>setCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={image? image: bimg}
              width="100%" alt="" />}

               <Form.Control
                // type="file"
                className="form-control "
                name="file"
                onClick={(e)=>{setShowCrop(true);handleChange(e)}}
               
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
                </label>
                <span  className="errorVal">{err}</span>

                </Col>:

                <Col sm="9">
                 <Form.Control
                   type="file"
                   name="video"
                   accept="video/*"
                   className="is-invalid-Q form-control "
                   defaultValue={video}
                   // isInvalid={!!errors?.video}
                   onChange={(e) => {handleChange(e);setVideo(e?.target?.files?.[0])}}
                 />
                  {/* <Form.Control.Feedback type="invalid">
                       {errors?.video}
                  </Form.Control.Feedback> */}
                   <span  className="errorVal">{err}</span>
               </Col>}
              </Form.Group>
             
            </Form>
          </Modal.Body>
          <Modal.Footer>
          {loader? <Button
            variant="primary"
            className="as_btn"
            
          >
         Processing...
          </Button>: <Button
          variant="primary"
          className="as_btn"
          // onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}
          onClick={() => handleAddImgAndVideo()}
        >
          Add
        </Button>}
            <Button variant="secondary" className="as_btn" onClick={()=>{handleClose(); setCropImg()}}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        )
        }
      const renderFormModal = () => {
        return(
          <Modal centered scrollable show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg()}} enforceFocus={false}>
          <Modal.Header closeButton>
         <Modal.Title>{`${selectedId ? `Edit Content/${imageType === "english" ?"English":"Hindi"}` : 'Add Content/English'}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Title
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder="Title..."
                    name='title'
                    defaultValue={title}
                    isInvalid={!!errors?.title}
                    className="form-control "
                    onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
                  />
                     <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {selectedId?
               <Form.Group as={Row}  className="mb-3 " controlId="formTitleInput">
               <Form.Label className="required" column sm="3">Slug</Form.Label>
               <Col sm="9">
               <Form.Control type="text" placeholder="slug..." name='slug' value={slug} readOnly />
                </Col>
               </Form.Group>
               :
              <Form.Group as={Row}  className="mb-3 " controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Slug
              </Form.Label>
                    <Col sm="9">
                    <Form.Control
                     type="text" 
                     placeholder="slug..."
                     name='slug'
                     isInvalid={!!errors?.slug} value={slug} 
                     onChange={(e) => { setSlug(e.target.value ); handleChange(e)}}
                     onBlur={(e) => { setSlug(SlugifyStringWithDash(e.target.value)) ; handleChange(e)}}
              />
              <Form.Control.Feedback type="invalid">
              {errors?.slug}
          </Form.Control.Feedback>
         </Col>
      </Form.Group>}
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Address
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="textarea"
                  name='address'
                  defaultValue={address}
                  isInvalid={!!errors?.address}
                  style={{ height: 80 }}
                  placeholder="Address..."
                  className="form-control "
                  onChange={(e) =>{ setAddress(e.target.value);handleChange(e)}}
                />
                   <Form.Control.Feedback type="invalid">
                {errors?.address}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
                <Form.Label column sm="3" className="required">
                  Description
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="Description..."
                    style={{ height: 150 }}
                    isInvalid={!!errors?.description}
                    defaultValue={description}
                    onChange={(e) => {setDescription(e.target.value);handleChange(e)}}
                  />
                    <Form.Control.Feedback type="invalid">
                {errors?.description}
           </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Image
                </Form.Label>
                <Col sm="9">
                <label id="labimg">
              
              {cropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={cropimg}
              onClick={()=>setCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={image ?image:imgShow?imgShow : bimg}
              width="100%" alt="" />}

               <Form.Control
                // type="file"
                className="form-control "
                isInvalid={!!errors?.file}
                name="file"
                onClick={(e)=>{setShowCrop(true);handleChange(e)}}
               
                size="40"
                accept="image/png, image/gif, image/jpeg"
                id="inputds"
              />
              <Form.Control.Feedback type="invalid">
            {errors?.file}
           </Form.Control.Feedback>
                </label>
                </Col>
              </Form.Group>
              {/* <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
                <Form.Label className="required" column sm="3">
                  Content Type
                </Form.Label>
                <Col sm="9">
                  <Form.Select
                    aria-label="Default select example"
                    required
                    className="hd-cr"
                    name ="contentType"
                    defaultValue={contentType}
                    isInvalid={!!errors?.contentType}
                    onChange={(e) =>{ setContentType(e.target.value);handleChange(e)}}
                  >
                    <option value="">Choose</option>
                    <option value="english">English</option>
                    <option value="hindi">Hindi</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                {errors?.contentType}
              </Form.Control.Feedback>
                </Col>
              </Form.Group> */}
              {TinymceEditor()}
            </Form>
          </Modal.Body>
          <Modal.Footer>
          {loader? <Button
            variant="primary"
            className="as_btn"
            
          >
         {`${selectedId ? 'Processing...' : 'Processing...'}`}
          </Button>: <Button
          variant="primary"
          className="as_btn"
          onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}
        >
          {`${selectedId ? 'Edit' : 'Add'}`}
        </Button>}
            <Button variant="secondary" className="as_btn reset-btn" onClick={()=>{handleClose(); setCropImg()}}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        )
        }
      useEffect(()=>{
        if(caartifileimage){
          onImageChange(caartifileimage)
        }
      },[cropimg,caartifileimage])

 
    return(
<>
{
permissioncontext.view?
<div className="main aarti_section">
        <div className="gita_quote_heading">
          <div className="container d-flex align-items-center justify-content-between">
            <h4 className="hd-list-f left-q"> Siddha Purush <small>({allDataCount})</small></h4>
            <div className="_btn">
              <Button
                 disabled={!permissioncontext.add}
                variant="primary"
                className="by-btn as_btn"
                onClick={()=>updateFile(true)}
              >
                Update File
              </Button>
            
              <Button
                disabled={!permissioncontext.add}
                variant="primary"
                onClick={handleShow}
                className="by-btn as_btn me-0"
              >
                Add Content 
              </Button>
            </div>
          </div>
        </div>
        <div className="aarti_inner pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <Sidebar />
              </div>
              <div className="col-md-8 col-lg-9">
                {loading ? (
                  <div className="center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                             value={imageType}
                             onChange={(e) => {setImageType(e.target.value); setOffset(0)}}
                          >
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                  
                    <div className="row mt-3">
                        {data?.map((item)=>
                         <div className="col-md-6 col-lg-4">
                    <div className="card mb-4" key={item?._id}>
                      <div className="card-header">
                        <img
                          // crossOrigin="anonymous"
                          src={(item?.file) ?item?.file : bimg}
                          className="card-img-top"
                          alt="img"
                        />
                        <div className="icon">
                           <FaEdit
                             className="me-2 hd-cr"
                             size={20}
                             onClick={(e) => {
                                        if(permissioncontext.edit)
                                            handleShow(item);
                             }}
                           />
                         
                           {item.status === true ? (
                          <input
                            type="button"
                            disabled={!permissioncontext.edit}
                                   className={!permissioncontext.edit?"_active me-1 disabled-btn":'_active me-1'}
                            style={{fontSize:"8px", borderRadius:"15px"}}
                            value="Active"
                            onClick={() =>
                              handleclick(item.status, item._id)
                            }
                          />
                        ) : (
                          <input
                            type="button"
                            disabled={!permissioncontext.edit}
                            className={!permissioncontext.edit?"_inactive me-1 disabled-btn":'_inactive me-1'}
                            style={{fontSize:"8px", borderRadius:"15px"}}
                            value="Inactive"
                            onClick={() =>
                              handleclick(item.status, item._id)
                            }
                            />
                          )}
                           {imageType === "hindi" ? "" :
                            <OverlayTrigger placement='bottom' overlay={<Tooltip id='button-edit'>Clone</Tooltip>}>
                            <FaCopy
                              className={permissioncontext.add?"me-1 hd-cr":"me-1 hd-cr disabled-btn"}
                              title="Hindi Clone"
                              style={{color:"red"}}
                              size={20}
                              onClick={(e) => {
                                            if(permissioncontext.add)
                                                handleCloneHindi(item?._id);
                              }}
                            />
                            </OverlayTrigger>}
                           <FaTrash
                            className={permissioncontext.delete?"dt-btn hd-cr":"dt-btn hd-cr disabled-btn"}
                            size={20}
                            onClick={(e) => {
                                        if(permissioncontext.delete)
                                            handleShowDt(item?._id);
                            }}
                          />
                        </div>
                      </div>
                      <div className="card-body">
                            <h5 className="card-title-p">{item.title}
                            </h5>
                            <p className="card-text description">
                        {item.description}
                      </p>
                    </div>
                    <p className="card-text description">
                        {item.address?.slice(0,80)}
                      </p>
                    <div className="card-footer text-center">
                        <Button
                          className="btn-more-g"
                          variant="primary"
                          disabled={!permissioncontext.add}
                          onClick={() => {
                            setShowG(true)
                            setShowType("Image")
                            setSelectedId(item?._id)
                          }}
                        >
                          ADD IMAGE
                        </Button>
                        <Button
                          className="btn-more-g"
                          variant="primary"
                          disabled={!permissioncontext.add}
                          onClick={() => {
                           setShowG(true)
                           setShowType("Video")
                           setSelectedId(item?._id)
                           
                          }}
                        >
                          ADD VIDEO
                        </Button>
                        <Button
                          className="btn-more-g"
                          variant="primary"
                          onClick={(e) => {
                            Navigate(`/sidhaPurushGallery/${item._id}`);
                          }}
                        >
                          VIEW 
                        </Button>
                    </div>
                    </div>
                  </div>)}
                  
                    
                  </div> 
                  </>
                )}
               <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div> 
              </div>
            </div>
          </div>
        </div>
      </div>:
    <Notauthorized />
}
        
      {renderFormModal()}
      {renderFormModalG()}
      <Modal
      centered
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="success_btn"
            onClick={handledelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
      </>
    )
}


export default SiddhaPurush ;