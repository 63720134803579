import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row, Container } from 'react-bootstrap';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { formateDate, setCookies } from '../../utils/Helper';
import { UserContext } from '../../context/UserContextProvider';
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import Papa from 'papaparse';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import usePermissionData from '../../components/Customhook/custompermission';
import Notauthorized from '../../components/Notauthorized';
import Select from 'react-select';

function CareerInquiries() {
  document.title = 'Career Inquiries';
  const permissioncontext = usePermissionData('careerInquiries');
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [country, setCountry] = useState('');
  const [inquireStatus, setInquireStatus] = useState('');
  const [flag, setFlag] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [countData,setCountData]=useState()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRange, setSelectedRange] = useState('');
  const [countryList, setcountryList] = useState([]);

  const handleDateRange = range => {
    const now = new Date();
    let start, end;

    if (range === 'disabled') {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange('');
      return;
    }

    switch (range) {
      case 'today':
        start = end = now;
        break;
      case 'yesterday':
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case 'last7Days':
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'customDate':
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const statusMap = {
    'in-progress': { text: 'In-progress', testClass: 'text-warning fw-bold' },
    pending: { text: 'Pending', testClass: 'text-pending fw-bold' },
    closed: { text: 'Closed', testClass: 'text-danger fw-bold font_14' },
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Name',
      Cell: e => {
        return <span>{e.row.original.name ?? '_'}</span>;
      },
    },
    {
      Header: 'Country',
      Cell: e => {
        return <span>{e.row.original.countryId?.name ?? '_'}</span>;
      },
    },
    {
      Header: 'Email',
      Cell: e => {
        return <span className='text-lowercase'>{e.row.original.email ?? '_'}</span>;
      },
    },
    {
      Header: 'Mobile Number',
      Cell: e => {
        return <span>{e.row.original.contactNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Inquiry Status',
      Cell: e => {
        return (
          <span className={statusMap[e.row.original.inquireStatus]?.testClass}>
            {statusMap[e.row.original.inquireStatus]?.text}
          </span>
        );
      },
    },
    {
      Header: 'Inquiry Date',
      Cell: e => {
        return <span>{moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A')}</span>;
      },
    },
    {
      Header: 'Updated Date',
      Cell: e => {
        return <span>{moment(e.row.original.updatedAt).format('DD MMM, YYYY hh:mm A')}</span>;
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original._id) {
          return (
              <h5>
              <input
                type='button'
                className={!permissioncontext.view?"Et-btn  disabled-btn":'Et-btn  '}
                disabled={!permissioncontext.view}
                value='View'
                onClick={() =>
                  navigate(`/careerDetails/${e.row.original._id}`, {
                    state: { permissioncontext },
                  })
                }
              />
            </h5>
          );
        }
      },
    },
  ]);

    const handleCountData = async (id) => {
      const res = await APIServices.get(`admin/career/getAdminCareerCount`, headers);

      if (res && res.status === 200) {
        if (res.data && res.data?.status) {
          setCountData(res.data.resData);
        } else {
          console.log("error");
        }
      }
    };

  const fetchContactList = useCallback(
    async (skip, limit, index) => {
      try {
        // let page =skip/limit+1;
        let countryId = country?.value === undefined ? '' : country?.value;
        const response = await APIServices.get(
          `admin/career/careerList/${skip}/${limit}?name=${name}&email=${email}&contactNumber=${number}&startDate=${
            startDate ? formateDate(startDate) : ''
          }&endDate=${endDate ? formateDate(endDate) : ''}&countryId=${countryId}&inquireStatus=${inquireStatus}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.total_count / limit));
          setAllDataCount(response.data?.total_count);
          setTableData(response.data?.data);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, name, email, number, startDate, endDate, country, inquireStatus]
  );

  const handleFilterClick = () => {
    if (name || email || number || startDate || endDate || inquireStatus || skip || country) {
      fetchContactList(skip, limit, 0, name, email, number, startDate, endDate, country, inquireStatus);
    }
  };

  const resetData = () => {
    setNumber('');
    setEmail('');
    setName('');
    setStartDate('');
    setEndDate('');
    setSelectedRange('');
    setCountry('');
    setInquireStatus('');
    setFlag(true);
  };

  useEffect(() => {
    fetchContactList(skip, limit, pageIndex);
    handleCountData()
    fetchCountryList();
  }, [flag]);

  const fetchCountryList = async () => {
    let response = await APIServices.get(`country/getPhoneCode`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setcountryList(apiData?.data);
      }
    }
  };

  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0);
  };

  const options = countryList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(item => ({
      value: item._id,
      label: item.name,
    }));

  const fetchedAllBookingDownload = async () => {
    let countryId = country?.value === undefined ? '' : country?.value;
    const response = await APIServices.get(
      `admin/career/careerList?name=${name}&email=${email}&contactNumber=${number}&startDate=${
        startDate ? formateDate(startDate) : ''
      }&endDate=${endDate ? formateDate(endDate) : ''}&countryId=${countryId}&inquireStatus=${inquireStatus}`,
      headers
    );
    if (response?.data?.status === true) {
      handleDownload(response.data?.data);
    } else {
      if (response?.response?.data?.status == 4) {
        logout();
      }
    }
  };
  const handleDownload = tableData => {
    for (let item of tableData) {
      if (item.name) item['Name'] = item.name;
      // if (item.contactNumber) item['Mobile Phone'] = item.contactNumber;
      if (item.email) item['Email'] = item.email;
      if (item?.contactNumber) item['Contact Number'] = item?.contactNumber;
      if (item?.inquireStatus) item['Inquire Status'] = item?.inquireStatus;
      if (item.countryId) item['Country'] = item.countryId?.name;
      item['Created Date '] = moment(item?.createdAt).format('DD MMM, YYYY hh:mm A');
      item['Updated Date '] = moment(item?.updatedAt).format('DD MMM, YYYY hh:mm A');
      // Add additional fields as needed

      // Delete unwanted fields
      delete item.name;
      delete item.contactNumber;
      delete item.inquireStatus;
      delete item.email;
      delete item.countryId;
      delete item.number;
      delete item._id;
      delete item.contactBy;
      delete item.countryCode;
      delete item.reply;
      delete item.source;
      delete item.status;
      delete item.document;
      delete item.isDeleted;
      delete item.createdAt;
      delete item.updatedAt;
      delete item.__v;
      // Delete additional unwanted fields
    }

    let csv = Papa.unparse(tableData, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvData);
    } else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.setAttribute('download', 'career_data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
    {permissioncontext.view ?
      <div className='App'>
        <div className='gita_quote_heading'>
          <div className='container'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q counter-top'> Career Inquiries <small>{allDataCount}</small></h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
             Today Inquiries <small>{countData?.usersAddedToday}/{countData?.usersAddedProgressToday}</small>
            </h4>
            <h4 className="hd-list-f font_20 left-q counter-top">
              This Week Inquiries <small>{countData?.usersAddedThisWeek}/{countData?.usersAddedProgressThisWeek}</small>
            </h4>
            <h4 className="hd-list-f font_20 left-q counter-top">
              This Month Inquiries <small>{countData?.usersAddedThisMonth}/{countData?.usersAddedProgressThisMonth}</small>
            </h4>
              {/* <div className='_btn'>
           
            </div> */}
            </div>
          </div>
        </div>
        <ToastContainer />

        <Container className='mb-2 ' fluid>
          <div className='feed_area box-shadow br-6 p-20 my-3'>
            <div className='chat_search_box'>
              <div className='chat-search-field'>
                <Row>
                  <Form>
                    <Form.Group as={Row} className='align-items-end' controlId=''>
                      <Col lg={3} md={6} className='mb-3'>
                        <Form.Label className='label-f-w-cd   '>Name</Form.Label>

                        <Form.Control
                          className='form-ch'
                          name='name'
                          type='search'
                          placeholder='Search By Name'
                          value={name}
                          onChange={e => {
                            setName(e.target.value);
                            setSkip(0);
                          }}
                        />
                      </Col>

                      <Col lg={3} md={6} className='mb-3'>
                        <Form.Label className='label-f-w-cd   '>Email</Form.Label>

                        <Form.Control
                          className='form-ch'
                          name='email'
                          type='search'
                          placeholder='Search By Email'
                          value={email}
                          onChange={e => {
                            setEmail(e.target.value);
                            setSkip(0);
                          }}
                        />
                      </Col>

                      <Col lg={3} md={6} className='mb-3'>
                        <Form.Label className='label-f-w-cd   '>Mobile Number</Form.Label>

                        <Form.Control
                          className='form-ch'
                          name='number'
                          type='search'
                          placeholder='Search By Mobile Number'
                          value={number}
                          onChange={e => {
                            setNumber(e.target.value);
                            setSkip(0);
                          }}
                        />
                      </Col>

                      <Col lg={3} md={6} className='mb-3'>
                        <Form.Label class='label-f-w-cd'>Country</Form.Label>
                        <Select
                          className=' mt-1'
                          classNamePrefix='react-select'
                          options={options}
                          value={country}
                          onChange={handleSelectChange}
                          placeholder='Search by Country...'
                          isClearable
                        />
                      </Col>
     
                      <Col lg={3} md={6} className='mb-3'>
                        <Form.Label className='label-f-w-cd   '>Search By Inquiry Date</Form.Label>
                        <div className='date-range-wrapper'>
                          <div className='select-icon-wrapper'>
                            <select
                              className='select-range'
                              value={selectedRange}
                              onChange={e => handleDateRange(e.target.value)}
                            >
                              <option value='disabled'>Select Date Range</option>
                              <option value='today'>Today</option>
                              <option value='yesterday'>Yesterday</option>
                              <option value='thisWeek'>This Week</option>
                              <option value='last7Days'>Last 7 Days</option>
                              <option value='thisMonth'>This Month</option>
                              <option value='lastMonth'>Last Month</option>
                              <option value='customDate'>Custom Date</option>
                            </select>
                            <img src='/images/down-arrow.png' className='select-icons' />
                          </div>

                          {selectedRange === 'customDate' && (
                            <DatePicker
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              maxDate={new Date()}
                              onChange={update => {
                                const [start, end] = update;
                                setStartDate(start);
                                setEndDate(end);
                              }}
                              // isClearable={true}
                              placeholderText='Select custom date range'
                              dateFormat='yyyy-MM-dd'
                            />
                          )}
                        </div>
                      </Col>

                      {/* <Col md={2}>
                        <DatePicker
                              placeholderText={"To date"}
                              className="form-control form-ch fil-date-pick "
                              name="date"
                              selected={startDate ? new Date(startDate) : null}
                              onChange={(date) => {
                                handleStartDate(date);
                                setSkip(0);
                              }}
                              dateFormat="yyyy-MM-dd"
                        />
                      </Col>

                        <Col md={2}>
                      <DatePicker
                              placeholderText={"From date"}
                              className="form-control form-ch fil-date-pick "
                              name="date"
                              selected={endDate ? new Date(endDate) : null}
                              onChange={(date) => {
                                handleEndDate(date);
                                setSkip(0);
                              }}
                              dateFormat="yyyy-MM-dd"
                        />
                       </Col> */}

                      {/* <Form.Label className="label-f-w-cd   "> Inquiry status</Form.Label> */}

                      <Col  lg={3} md={6} className='mb-3'>
                        <Form.Label className='label-f-w-cd  '>Search By Status</Form.Label>
                        <Form.Select
                          aria-label='label-selected-menu'
                          className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr form-ch'
                          value={inquireStatus}
                          onChange={e => {
                            setInquireStatus(e.target.value);
                            setSkip(0);
                          }}
                        >
                          <option defaultValue=''> Inquiry status</option>
                          <option value='pending'>Pending</option>
                          <option value='in-progress'>In-progress</option>
                          <option value='closed'>Closed</option>
                        </Form.Select>
                      </Col>

                      <Col  lg={4} md={6} className='mb-3'>
                        <Button
                          variant='secondary'
                          className='as_btn ms-0'
                          onClick={() => {
                            handleFilterClick();
                            setSkip(0);
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='secondary'
                          className='as_btn reset-btn ms-2'
                          onClick={() => {
                            resetData();
                          }}
                        >
                          Reset
                        </Button>

                        {adminUser?.adminType === 'super_admin' ? (
                          <Button
                            className='as_btn ms-2 download-btn'
                            variant='primary'
                            disabled={!tableData || (tableData && tableData.length <= 0)}
                            onClick={() => {
                              fetchedAllBookingDownload();
                            }}
                          >
                            Download File
                          </Button>
                        ) : (
                          ''
                        )}
                        </Col>

                    </Form.Group>
                  </Form>
                </Row>
              </div>
            </div>
          </div>
        </Container>
        {/* </div> */}
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={fetchContactList}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}
      </div>:
     <Notauthorized />}
    </>
  );
}

export default CareerInquiries;
