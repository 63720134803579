import { useEffect, useState,useContext } from "react";
import { Button, Modal, Row ,Form ,Col, Container } from "react-bootstrap";
import { UserContext } from "../context/UserContextProvider";

import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import ModalComponent from "../components/Shared/ModalComponent";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import { getBaseURL ,setCookies } from "../utils/Helper";
import { Heart, HeartFill} from 'react-bootstrap-icons';
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

export default function Reels() {
  document.title = 'Reels';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const { refreshToken } = useContext(UserContext);
  const permissioncontext =usePermissionData("reels")
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showURLModal, setShowURLModal] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [ type , setType] = useState("english")
  const [allData , setAllData] = useState([])
  const [allDataCount , setAllDataCount] = useState([])
  const [data, setData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [startDate , setStartDate] = useState("")
  const [ endDate, setEndDate] = useState("")
  const [errors, setErrors] = useState({});
  const[loader,setLoader]=useState(false)
  const initialData = {
    description:"",
    video:"",
    tags:"",
    category:"",
    hash_tags:"",
    urls:""
  }
  const [newData, setNewData] = useState(initialData);
  const [progress ,setProgress] = useState(0)
  const [selectedIds ,setSelectedIds] = useState([])
  const [urlData, setURLData] = useState();
  const navigate = useNavigate();
  const [ flag , setFlag] = useState();
  const [ flag1 , setFlag1] = useState(false)


  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  useEffect(() => {
    fetchData();
  }, [offset]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`astroReel/getAllReel/${offset}/${perPage}/?startDate=${startDate}&endDate=${endDate}`, headers)
    let data = response?.response?.data
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
        setFlag1(false)
        setLoading(false)
      }
    }else{
      if(data?.status == 4){
     logout()
    }
  }
  }
  const handleFilterClick =() =>{

    if (startDate||endDate|| offset) {
      fetchData()
   }
   }
   
   const handleStartDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setStartDate(formattedDate);
  };

  const handleEndDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setEndDate(formattedDate);
  };

   const resetData = () =>{
     setStartDate("")
     setEndDate("")
     setOffset(0)
    setFlag1(true)
   }

   useEffect(()=>{
    fetchData()
  },[flag1])

  useEffect(() => {
    if(allData && allData.length > 0){
      setLen(allDataCount);
      setPageCount(Math.ceil(allDataCount / perPage));
      setData(allData);
    }
  }, [allData])

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = (selectedPage * perPage) % len;
    setOffset(newOffset)
  }

  async function updateFile() {
    let res = await APIServices.get(`astroReel/updateFile`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  }
  const validateInputs = () => {
    const newErrors = {};
  if(!newData.urls){
      if (newData.video === "") 
      newErrors.video = "Please upload video.";
  }
  if(!newData.video ){
      if (newData.urls === "") 
      newErrors.urls = "Please add urls.";
  }
      return newErrors;
  };  

  async function addReel() {
    let payload = new FormData();
    payload.append('video', newData?.video);
    payload.append('tags', newData?.tags);
    payload.append('category', newData?.category);
    payload.append('hash_tags', newData?.hash_tags);
    payload.append('description', newData.description);
    payload.append("userId", astroUserId)
  
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
      setSelectedFile(newData?.video);
      
      let response = await APIServices.post(`astroReel/addReel`, payload, { ...headers, "Content-Type": "multipart/form-data" },(progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted)
    })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          toast.success(apiData?.message);
          setLoader(false)
          fetchData();
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }
  
  async function addReelURL(){
    let payload = {
      'category' : newData?.category,
      'urls' : newData?.urls,
      'astroUserId' : astroUserId,
    }
   
    // payload.append('category', newData?.category);
    // payload.append('urls', newData?.urls);
    // payload.append("userId", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
      let response = await APIServices.post(`astroReel/addReelURL`, payload, { ...headers, "Content-Type": "application/json" })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowURLModal(false);
         
          toast.success(apiData?.message);
          setLoader(false)
          fetchData();
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
          
        }
      }
      setLoader(false)
    }
  }
  
  async function updateReel() {
    let payload = new FormData();
    payload.append('tags', newData?.tags);
    payload.append('category', newData?.category);
    payload.append('hash_tags', newData?.hash_tags);
    payload.append('description', newData.description);
    payload.append("userId", astroUserId);
    payload.append("video", newData?.video);
    if(newData?.video == ''){
      payload.append('source', newData?.source);
    }
    setSelectedFile(newData?.video);
    setLoader(true)
    let response = await APIServices.put(`astroReel/updateReel/${newData?._id}`, payload, { ...headers, "Content-Type": "multipart/form-data" },(progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted)
  })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShowModal(false);
        toast.success(apiData?.message);
        setLoader(false)
        fetchData();
      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setShowModal(true)
        setLoader(false)
      }
    }
    setLoader(false)
  }
  
  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }
  const multidelete = () => {
   if (flag === true) {
    setShowDt(true)
   }else{
    toast.warn("Please select cards for delete");
   }
  }

  const handleMultiDt = (e ,id) => {
    let checked = e.target.checked 
    if(checked === true){
      setFlag(true)
      setSelectedIds(current => [...current, id]);
    }else if(checked === false){
      setSelectedIds(current => current.filter((ls)=> ls !== id))
    }
  }

  useEffect(()=>{
    if (selectedIds?.length === 0) {
      setFlag(false)
    }
  },[selectedIds])

  const handleMultidelete = async () => {

    let payload = {reelIds: selectedIds}
    let res = await APIServices.delete(`astroReel/deleteMultiReels`, headers ,payload)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        setSelectedIds([])
        setFlag(false)
        fetchData()
      }
    }
  }

  const handledelete = async () => {
    let res = await APIServices.delete(`astroReel/deleteReel/${newData?._id}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
      }
    }
  }
  const multiActivecheck = () => {
    if (flag === true) {
     multiActive()
    }else{
     toast.warn("Please select records for Active");
    }
   }

   const multiActive = async () => {
    let payload = {reelIds: selectedIds,status:true}

    let res = await APIServices.put(`astroReel/updateMulitStatus`,payload, headers )
    if (res?.status === 200) {
       
      
      
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
        setSelectedIds([])
        setFlag(false)
        
      }
    }
  }


   const multiInactivecheck = () => {
    if (flag === true) {
    multiInactive()
    }else{
     toast.warn("Please select records for Inactive");
    }
   }


   const multiInactive = async () => {
    let payload = {reelIds: selectedIds,status:false}

    let res = await APIServices.put(`astroReel/updateMulitStatus`,payload, headers )
    if (res?.status === 200) {
       
      
      
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
        setSelectedIds([])
        setFlag(false)
        
      }
    }
  }
 
  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroReel/updateStatus/${id}`, formData, headers)
      .then((res) => {
        fetchData()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function handleChange(e, field = "text") {
    let key, value;
    key = e?.target?.name;
    if (field === "text") {
      value = e?.target?.value;
    }
    else if (field === "file") {
      value = e?.target?.files?.[0];
    }else if (field === "audio"){
      value = e?.target?.files?.[0];
    }else if (field === "video"){
      value = e?.target?.files?.[0];
    }
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
    setNewData(prev => ({ ...prev, [key]: value }))
  }
  function handleRt (e){
    let key = e?.target?.name;
    if (!!errors[key])
        setErrors({
          ...errors,
          [key]: null,
        });
    }

   

  return (
    <>
    {
      permissioncontext.view?
<div className="main aarti_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Reels <small>({allDataCount})</small></h4>
           <div className="_btn">
              
              <Button variant='primary' disabled={!permissioncontext.edit} className='by-btn as_btn' onClick={() => {multiActivecheck()}}>
                Multi Active
              </Button>
              <Button variant='primary' disabled={!permissioncontext.edit} className='by-btn as_btn' onClick={() => {multiInactivecheck()}}>
                Multi Inactive
              </Button>
              <Button
               className="by-btn as_btn me-0"
                variant="primary"
                disabled={!permissioncontext.add}
                onClick={() => {
                  // if(canAdd) {
                  setNewData(initialData);
                  setShowModal(true);
                  // }
                }}
              >
                Add Reels
              </Button>&nbsp;&nbsp;
            
              <Button
               className="by-btn as_btn me-0"
                variant="primary"
                disabled={!permissioncontext.add}
                onClick={() => {
                  // if(canAdd) {
                    setNewData(initialData);
                    setShowURLModal(true);
                  // }
                }}
              >
                Add Reels URL
              </Button>&nbsp;&nbsp;
              <Button
               className="by-btn as_btn me-0"
               style={{background:"red"}}
                variant="primary"
                disabled={!permissioncontext.delete}
                onClick={() => {
                  // if(candelete)
                  multidelete()
                }}
              >
                Multi Delete
              </Button>
           </div>
           </div>
        </div>
        <div className="aarti_inner">
           <div className="container-fluid">
              <div className="row">
                 <div className="col-md-12 col-lg-12"> 
                 {loading ? <div className="center"><Spinner animation="border" /></div> :
              <>
              <div className="chat_window">
         <Row className="mb-4 mlist-1">
            <div class="feed_area">
         <div class="chat_search_box">  
        <div className="chat-search-field">
               <Row>
               <Form>
               <Row>
                <Form.Group as={Row} className="mb-3 align-items-center"  controlId="">
                  <Form.Label class="label-f-w-1  mt-2 ">Start And End Date</Form.Label>
                 <Col md={2}>
                  {/* <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="date"
                    type="date"
                    placeholder="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  /> */}
                  <DatePicker
                        placeholderText={"Start date"}
                        className="form-control form-ch fil-date-pick mt-2"
                        name="date"
                        selected={startDate ? new Date(startDate) : null}
                        onChange={(date) => {
                          handleStartDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                  />
                  </Col>
                 <Col md={2}>
                 {/* <Form.Control
                   className="hd-cr mt-2 form-ch"
                   name="date"
                   type="date"
                   placeholder="date"
                   value={endDate}
                    onChange={(e) => setEndDate( e.target.value)}
                 /> */}
                 <DatePicker
                        placeholderText={"End date"}
                        className="form-control form-ch fil-date-pick mt-2"
                        name="date"
                        selected={endDate ? new Date(endDate) : null}
                        onChange={(date) => {
                          handleEndDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                  />
                 </Col>
          
          <Button variant="secondary"
          className="as_btn"
          onClick={()=> {handleFilterClick();setOffset(0)}}
          >
          Filter
        </Button>
        <Button variant="secondary"
          className="as_btn reset-btn"
          onClick={()=> {resetData();setOffset(0)}}
          >
          Reset
        </Button>
          </Form.Group>
          </Row>
          </Form>
          </Row>
      </div>
    </div> 
    </div> 
    <Container>
            <div className=" row">
              {
                data && data.length > 0 && data?.map((item) =>
                <div className="col-md-6 col-lg-4 col-xl-3">
                  <div className="card mb-3 Reels_video" key={item?._id} >
                    <div className="card-header">
                     <video
                        className="aarti_video"
                        controls
                        src={(item?.video) ? item?.video : bimg}
                      />
                       <div className=" icon1">
                        <input
                            className="form-check-input border_checkbox_c"
                            type="checkbox"
                            name="languages"
                            value="Javascript"
                            id="flexCheckDefault"
                            onChange={(e)=>
                            { handleMultiDt( e ,item?._id)}
                             
                            }
                       /></div>
                      <div className="icon card-icons">
                        <FaEdit
                          className={permissioncontext.edit?"me-2 hd-cr":"me-2 hd-cr disabled-btn"}
                          size={20}
                          onClick={() => {
                            if(permissioncontext.edit) {
                                setShowModal(true);
                                setNewData({
                              _id : item._id ,
                              category: item.category ?? "",
                              hash_tags:item?.hash_tags ?? "",
                              tags:item?.tags ?? "",
                              source: item.video ?? "",
                              description:item?.description ?? "",
                            });
                            }
                          }}
                        />
                       
                        <FaTrash
                          className={permissioncontext.delete?"dt-btn hd-cr":"me-2 hd-cr disabled-btn"}
                          size={20}
                          onClick={e => {
                            if(permissioncontext.delete) {
                                setNewData(item);
                                handleShowDt()
                                setFlag(false)
                            }
                          }}
                        />
                          {item.status === true ? (
                          <input
                            type="button"
                            disabled = {!permissioncontext.edit}
                            className={!permissioncontext.edit?"_active disabled-btn":'_active '}
                            style={{fontSize:"8px", borderRadius:"15px"}}
                            value="Active"
                            onClick={() =>
                              handleclick(item.status, item._id)
                            }
                          />
                        ) : (
                          <input
                            type="button"
                            disabled = {!permissioncontext.edit}
                            className={!permissioncontext.edit?"_inactive disabled-btn":'_inactive '}
                            style={{fontSize:"8px", borderRadius:"15px"}}
                            value="Inactive"
                            onClick={() =>
                              handleclick(item.status, item._id)
                            }
                            />
                          )}
                      </div>
                    </div>
                    <div className="card-body height_box_reel " >
                    <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">
                        <span className="calicon">
                          <i className="ri-calendar-line"></i>
                        </span>
                        {/* {new Date(item.createdAt).toDateString()} */}
                        {moment(item.createdAt).format("DD MMM, YYYY hh:mm A")}
                      </p>
                      
                      <h6 className="mb-2" style={{color:"blue", fontSize: "13px" }}>{item.hash_tags }</h6>
                      <p className="card-text-reel description">
                        
                        {  item.description === true?item.description?.slice(0, 180):item.description?.slice(0,180)}
                       
                      </p>
                    </div>
                    <div className="card-footer">
                            <span className="cursor-pointer ms-2" style={{fontSize:14}}>
                                <span>
                                    {
                                      item?.like_count >0 ? (
                                            <HeartFill style={{ color: '#B21414' }} />
                                        ) : (
                                            <Heart />
                                        )} </span>{' '}
                                Like {item?.like_count}
                            </span>
                            <span className="cursor-pointer">
                                <button className="bg-transparent border-0"style={{fontSize:14}}>
                                    
                                    <span className="mx-1" >Comments</span>
                                    ({item?.comment_count > 0 ? item?.comment_count : 0})
                                </button>
                            </span>
                            </div>
                   
                  </div>
                  </div>

                )
              }
           
            </div>
          </Container>
            </Row>
            </div>
           
          </>
        }

   {loading?"":
    <div className="pagination">
         {allData && allData.length>0 ? 
        (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={offset/perPage}
          />

        ) :(<h2 className="no_records">Records not Available</h2>)
        }
            </div>}
                 </div>
              </div>
           </div>
        </div>
      </div>:
       <Notauthorized />
    }
      
     
      <ModalComponent
        id="readMore"
        show={show}
        onHide={() => setShow(false)}
        modalHeading={newData?.title}
        size="lg"
        localData={ newData.contentLangEn === true? newData?.contentEn : newData?.contentHi}
      />

      <ModalComponent
        id="updateReel"
        show={showModal}
        onHide={() => {setShowModal(false);setErrors("")}}
        modalHeading={(newData?._id) ? "Edit Reel" : "Add Reel"}
        size="lg"
        localData={newData}
        errors = {errors}
        progress ={progress}
        handleRt={handleRt}
        onButtonClick={(newData?._id) ? updateReel : addReel}
        handleChange={handleChange}
        loader={loader}
      />

      <ModalComponent
        id="addReelURL"
        show={showURLModal}
        onHide={() => {setShowURLModal(false);setErrors("")}}
        modalHeading={"Add Reels URL's"}
        size="lg"
        localData={newData}
        errors = {errors}
        handleRt={handleRt}
        onButtonClick={addReelURL}
        handleChange={handleChange}
        loader={loader}
      />

      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt} 
        >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {flag === true?`Are you sure you want to delete ${selectedIds?.length} records?`:` Are you sure you want to delete this element?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={flag === true?handleMultidelete:handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  )
}