import moment from 'moment';
import slugify from 'slugify';
import Cookies from 'universal-cookie';

import { PermissionObj } from './PermissionObj';

const cookies = new Cookies();

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  cookies.set(name, value, { expires: expire, path: '/' });
};

export const getCookies = name => {
  return cookies.get(name);
};

export const removeCookies = (name, options = { path: '/' }) => {
  cookies.remove(name, options);
};

export function flatten(array) {
  let newArr = [];
  for (let x = 0; x < array.length; x++) {
    if (Array.isArray(array[x])) {
      for (let y = 0; y < array[x].length; y++) {
        newArr.push(array[x][y]);
      }
    } else {
      newArr.push(array[x]);
    }
  }
  return newArr;
}

export const handleDuplicateEvents = (fetchedEvents, events) => {
  let newevents;
  if (fetchedEvents.length) {
    const allevnt = fetchedEvents.map(item => item.id);
    newevents = events.filter(event => {
      if (allevnt.includes(event.id)) {
        return false;
      } else {
        return true;
      }
    });
  } else {
    newevents = events;
  }
  return newevents;
};

export function getBaseURL(path) {
  if (path) {
    return `${process.env.REACT_APP_IMG_URL}${path}`;
  }
  return null;
}
export const formateDate = date => {
  return moment(date).format('YYYY[-]MM[-]DD');
};

export const formateDateAndTime = date => {
  return moment(date).format('YYYY[-]MM[-]DD HH:mm');
};

export const SlugifyStringWithDash = dataString => {
  if (dataString) {
    return slugify(dataString, {
      replacement: '-',
      lower: true,
      strict: false,
      remove: /[*+~.()'"!:@]/g,
    });
  }
};

export function capitalizeFirstLetter(str) {
  if(str){
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }else{
    return str
  }
}

export function objectToFormData(obj) {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== undefined && value !== '') {
        if (typeof value === 'object' && value !== null) {
          formData.append(key, value?.[0]);
        } else {
          formData.append(key, value);
        }
      }
    }
  }

  return formData;
}

export function objectToFormData1(obj) {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // if (value !== undefined && value !== '') {
        // console.log(key, value, "jaaaaaaaaaaaaaa")
        if (typeof value === 'object') {
          formData.append(key, value?.[0]);
        } else {
          formData.append(key, value);
        }
      // }
    }
  }

  return formData;
}

export async function fetchImageAsFile(imageUrl) {
  if(!imageUrl) return null;
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  
  // Create a File object from the blob
  const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
  const file = new File([blob], fileName, { type: blob.type });

  // Create a FileList with a single File object
  const fileList = new DataTransfer();
  fileList.items.add(file);

  return fileList.files;

}

export const getPermissionData = (permissionName, subType="") => {

    let DataArray = [];

    DataArray = PermissionObj?.list?.find(obj => obj?.permissionName === permissionName);
    
    if(subType != "") {
        let subDataArray = [];

        if(DataArray?.children?.length > 0 ) {
            subDataArray = DataArray?.children?.find(obj => obj?.permissionName === permissionName);
            return subDataArray;
        }
        else {
            return []
        }
    }
    else {
        return DataArray;
    }
}