import React, { useEffect, useState ,useContext} from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../components/Shared/Sidebar";
import bimg from "../../src/imgs/sawal_bg.png";
import Cookies from "universal-cookie";
import APIServicenew from "../utils/APIGeneralService";
import { FaEdit, FaTrash } from "react-icons/fa";
import { setCookies } from '../utils/Helper';
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

function Questions() {
  document.title = 'Questions';
  const permissioncontext =usePermissionData("mysteriousTemple")
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [len, setLen] = useState();
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [selectedId, setSelectedId] = useState();
  const [show, setShow] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [ type , setType] = useState("english")
  const [content, setContent] = useState("");
  const [allData , setAllData] = useState([])
  const [errors, setErrors] = useState({});
  const [loader,setLoader]=useState(false)
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId");
  const [allDataCount , setAllDataCount] = useState()

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const handleClose = () => {
    setShow(false)
    setErrors("")
    setContent("")
  };
  const handleCloseDt = () => setShowDt(false);

  const handleShow = async(item) => {
    setShow(true);
    setSelectedId(item._id);
    setContent(item?.content);
  };

  const handleShowDt = (id) => {
    setSelectedId(id);
    setShowDt(true);
  };
  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroQuest/updateStatus/${id}`, formData, headers)
      .then((res) => {
        updateFile()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if ((type === "hindi" || type === "english" )&& (!content || content === "")) 
      newErrors.content = "Please enter content.";

      return newErrors;
  }; 
  async function handleEdit() {
    const formData = new FormData();
    if( type === "hindi"){
      formData.append("contentHi", true);
      formData.append("contentEn", false);
      formData.append("content", content);
    }else if(type === "english"){
      formData.append("contentEn", true);
      formData.append("contentHi", false);
      formData.append("content", content);

    };
    formData.append("userId", astroUserId);

    const headers = { Authorization: `Bearer ${token}` };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
     } else {
       let response = await APIServices.put(
         `astroQuest/editOne/${selectedId}`,
         formData,
         headers
         );
         if (response?.status === 200) {
           let apiData = response?.data;
           if (apiData?.status === true) {
        setShow(false);
        toast.success(apiData?.message);
        setLoader(false)
        updateFile();
      }else{
        toast.error(apiData?.message)
        setLoader(false)
      }
    }
    setLoader(false)
}
  }

  const fetchData = async () => {
    setLoading(true);
    let response = await APIServices.get(`astroQuest/getAll/${type}/${offset}/${perPage}`, headers);
    let data = response?.response?.data
    
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false);
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  };
 
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage);
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset);
  };


  async function updateFile(showToast) {
    let res = await APIServices.get(`astroQuest/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData();
      }
    }
  }

  const handledelete = async () => {
    let res = await APIServices.delete(
      `astroQuest/findByIdAndremove/${selectedId}`,
      headers
    );
    if (res?.status === 200) {
      let apiData = res?.data;
      setShowDt(false);
      if (apiData?.status) {
        toast.success(apiData?.message);
        setOffset(0)
        updateFile();
      }
    }
  };

  async function handleCreate() {
    const formData = new FormData();
    if( type === "hindi"){
      formData.append("contentHi", true);
      formData.append("contentEn", false);
      formData.append("content", content);
    }else if(type === "english"){
      formData.append("contentEn", true);
      formData.append("contentHi", false);
      formData.append("content", content);
    }
    formData.append("userId", astroUserId);
    const headers = { Authorization: `Bearer ${token}` };

    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
   } else {
     let response = await APIServices.post(
       `astroQuest/create`,
       formData,
       headers
       );
       if (response?.status === 200) {
         let apiData = response?.data;
         if (apiData?.status === true) {
        setShow(false);
        toast.success(apiData?.message);
        setLoader(false)
        updateFile();
      }else{
        toast.error(apiData?.message)
        setLoader(false)
      }
    }
    setLoader(false)
}
  }
  useEffect(() => {
    fetchData();
  }, [ offset ,type]);

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }
  const renderFormModal = () => {
    return(
      <Modal show={show} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Questions`} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
       
           <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
            <Form.Label className="required" column sm="3">
              Content
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                type="text"
                name="content"
                style={{height:150}}
                placeholder="Content..."
                defaultValue={content}
                isInvalid={!!errors?.content}
                className="form-control "
                onChange={(e) => {setContent(e.target.value);handleChange(e)}}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.content}
           </Form.Control.Feedback>
            </Col>
          </Form.Group>
       
        </Form>
      </Modal.Body>
      <Modal.Footer>
      {loader?<Button variant='primary' className='as_btn' >
      {`${selectedId ? 'Processing...' : 'Processing...'}`}
    </Button>:<Button variant='primary' className='as_btn' onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
    {`${selectedId ? 'Edit' : 'Add'}`}
  </Button>}
       
        <Button variant="secondary" 
        className="as_btn"
        onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    )
}



  return (
    <>
    {
      permissioncontext.view?
<div className="main aarti_section">
        <div className="gita_quote_heading">
          <div className="container d-flex align-items-center justify-content-between">
            <h4 className="hd-list-f left-q">Questions <small>({allDataCount})</small></h4>
            <div className="_btn">

        <Button
       disabled={!permissioncontext.add}
         variant="primary" className="by-btn as_btn" onClick={()=>updateFile(true)}>
          Update File
        </Button>
        <Button variant="primary" 
        onClick={handleShow}
               disabled={!permissioncontext.add}
        className="by-btn as_btn me-0"
        >
          Add Question
        </Button>
            </div>
          </div>
        </div>
        <div className="aarti_inner pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <Sidebar />
              </div>
              <div className="col-md-8 col-lg-9">
        {loading ? 
          <div className="center">
            <Spinner animation="border" />
          </div>
         : 
          <>
            <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={type}
                            onChange={(e) => {setType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>

                          </Form.Select>
                        </div>
                      </div>
                    </div>
            <div className="row mt-3">
         { data?.map((item)=>
            <div className="col-md-6 col-lg-4">
              <div className="card mb-4" key={item?._id}>
              <div class="card-header">
             <img
              
              src={bimg}
              className="card-img-top"
              alt="img"
            />
            <div class="center">{item?.content?.slice(0, 140)}</div>

             <div className="icon">
            <FaEdit
              className={permissioncontext.edit?"me-2 hd-cr":"me-2 hd-cr disabled-btn"}
              size={20}
              onClick={(e) => {
                if(permissioncontext.edit)
                    handleShow(item);
              }}
            />
            <FaTrash
              className={permissioncontext.delete?"dt-btn hd-cr":"dt-btn hd-cr disabled-btn"}
              size={20}
              onClick={(e) => {
                if(permissioncontext.delete)
                    handleShowDt(item._id);
              }}
            />
             {item.status === true ? (
             <input
               type="button"
               disabled={!permissioncontext.edit}
               className={!permissioncontext.edit?"_active me-1 disabled-btn":'_active me-1'}
               style={{fontSize:"8px", borderRadius:"15px"}}
               value="Active"
               onClick={() =>
                 handleclick(item.status, item._id)
               }
             />
           ) : (
             <input
               type="button"
               disabled={!permissioncontext.edit}
               className={!permissioncontext.edit?"_inactive  disabled-btn":'_inactive '}
               style={{fontSize:"8px", borderRadius:"15px"}}
               value="Inactive"
               onClick={() =>
                 handleclick(item.status, item._id)
               }
                    />
                  )}
          </div>
        </div>
        <div className="card-body">
          <div class="containerM">
       
          </div>
          <p className="cal-dat">
            <span className="calicon">
              <i className="ri-calendar-line"></i>
            </span>
            {/* {new Date(item.updatedAt).toDateString()} */}
            {moment(item.updatedAt).format("DD MMM, YYYY hh:mm A")}
          </p>
        </div>
        {/* <div class="card-footer text-muted">
         
        </div> */}
      </div>
      </div>
    // ):""
            )}
           </div>
           
           </>
        }
         <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
      </div>
      </div>
      </div>
      </div>
   </div>:
   <Notauthorized />
    }
       
      {renderFormModal()}  
      <Modal
      centered
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="success_btn"
            onClick={handledelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Questions;
