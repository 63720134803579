import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row, Container } from 'react-bootstrap';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from '../../utils/Helper';
import { UserContext } from '../../context/UserContextProvider';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import moment from 'moment';
import Papa from 'papaparse';

function CareerDetails() {
  document.title = 'Career Details';
  const cookies = new Cookies();
  const location = useLocation();
  const { permissioncontext } = location.state || {};
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { id: detailsId } = useParams();
  const [error, setError] = useState(false);
  const [reply, setReply] = useState('');
  const [inquireStatus, setInquireStatus] = useState('in-progress');
  const [contactDetails, setContactDetails] = useState([]);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  useEffect(() => {
    handleContactDetails(detailsId);
  }, [detailsId]);

  const handleContactDetails = async id => {
    const response = await APIServices.get(`admin/career/careerDetail/${id}`, headers);

    if (response?.data?.status === true) {
      setContactDetails(response?.data?.data);
    } else {
      if (response?.response?.data?.status == 4) {
        logout();
      }
    }
  };

  const handleChange = () => {
    setError(false);
  };
  const handleUpdate = async () => {
    if (!reply || reply === '') {
      setError(true);
    } else {
      let payload = {
        replier_id: adminUser?._id,
        replier_type: adminUser?.adminType,
        replier_name: adminUser?.name,
        reply: reply,
        name: contactDetails?.name,
        email: contactDetails?.email,
        inquireStatus: inquireStatus,
      };

      const response = await APIServices.put(
        `admin/career/careerReplyNew/${contactDetails?._id}`,
        payload,
        headers
      );

      if (response?.data.replyStatus == true) {
        toast.success('Reply send successfully');
        handleContactDetails(detailsId);
        setReply('');
      } else {
        if (response?.response?.data?.status == 4) {
          logout();
        }
      }
    }
  };

  return (
    <div className='App'>
      <div className='gita_quote_heading'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q '>
              <FaArrowLeft
                className='hd-cr '
                style={{ marginRight: '10px', width: '15px' }}
                size={25}
                onClick={() => navigate(-1)}
              />
              Career Details
            </h4>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Container className='mb-2 '>
        <div class='feed_area'>
          <div class='chat_search_box box-shadow br-6 p-20'>
            <div className='chat-search-field'>
              <div class='card-header mb-4'>
                <div className='row'>
                  <div className='col-8'>
                    <ul class='list-group userD-list'>
                      <li>Name: {contactDetails?.name}</li>
                      <li>Email: {contactDetails?.maskEmail}</li>
                      <li>Contact Number : {contactDetails?.contactNumber}</li>
                    </ul>
                  </div>
                  <div className='col-4 text-end'>
                    <p className='mb-2'>
                      {moment(contactDetails?.createdAt).format('DD MMM, YYYY hh:mm A')}
                    </p>
                    {contactDetails && contactDetails?.document &&
                      <button
                        className={!permissioncontext?.add ? 'as_btn disabled-btn' : 'as_btn'}
                        disabled={!permissioncontext?.add}
                        onClick={() => {
                          if (permissioncontext.add) {
                            window.open(contactDetails?.document, '_blank');
                          }
                        }}
                      >
                        View Document
                      </button>
                    }
                  </div>
                </div>

              </div>
              <div className='card-body'>
                <Form.Group as={Col} className='mb-3' controlId='formAartiInput'>
                  <Form.Label column sm='3' className='contact_detail_question br-6 mb-1'>
                    Question
                  </Form.Label>
                  <Col sm='9'>
                    <p>{contactDetails?.message}</p>
                  </Col>
                </Form.Group>

                {contactDetails &&
                  contactDetails?.reply?.map((item, index) => (
                    <>
                      <ul className='list-group list-group-flush mb-4'>
                        <li key={index} className='list-group-item px-0'>
                          <span className='contact_detail_question br-6'>Reply</span>
                          <div className='d-flex justify-content-between mt-1'>
                            {item?.reply}

                            <div className='text-end d-flex flex-column'>
                              <span className='text-primary item-name aa'>{item?.replier_name}</span>
                              <span className='item-text'>
                                {item?.createdAt ? moment(item?.createdAt).format('DD MMM, YYYY hh:mm A') : ''}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </>
                  ))}

                {contactDetails?.inquireStatus != 'closed' ? (
                  <>
                    <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
                      <Form.Label column sm='1' className='required'>
                        Reply
                      </Form.Label>
                      <Col sm='11'>
                        <Form.Control
                          as='textarea'
                          name='content'
                          placeholder='Reply...'
                          value={reply}
                          style={{ height: 110 }}
                          onChange={e => {
                            handleChange();
                            setReply(e.target.value);
                          }}
                        />
                        {error ? <p className='text-danger'>Please enter your reply</p> : ''}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className='' controlId='formAartiInput'>
                      <Form.Label column sm='1' className='mb-4'>
                        Status
                      </Form.Label>
                      <Col sm='4'>
                        <Form.Select
                          aria-label='categories'
                          className=' astroWatch-select-hover hd-cr right-q mb-2'
                          value={inquireStatus}
                          onChange={e => {
                            setInquireStatus(e.target.value);
                          }}
                        >
                          <option value='in-progress'>In-progress</option>
                          <option value='closed'>Closed</option>
                        </Form.Select>
                      </Col>
                      <Col sm='3'>
                        <Button
                          type='submit'
                          size='sm'
                          className='as_btn mt-0  ms-4 mb-4 '
                          disabled={!permissioncontext?.add}
                          onClick={e => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* </div> */}
    </div>
  );
}

export default CareerDetails;
