import { useEffect, useState,useContext } from "react";
import { Button, Modal, Row ,Form } from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash ,FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import bimg from "../../src/imgs/banner_background.png";
import ModalComponent from "../components/Shared/ModalComponent";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import { getBaseURL,getCookies ,setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Notauthorized from "../components/Notauthorized";

export default function Aarti() {
  document.title = 'Aarti';
  const location = useLocation()
  const  {permissioncontext}  = location.state || {};
  const token = getCookies("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = getCookies("astroUserId")
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [progress ,setProgress] = useState(0)
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [ type , setType] = useState("english")
  const [allData , setAllData] = useState([])
  const [allDataCount , setAllDataCount] = useState()
  const [data, setData] = useState();
  const[loader,setLoader]=useState(false)
  const initialData = {
    title: "",
    content:"",
    file: "",
    imageType:"",
    // description:"",
    audio:"",
    // songUrl: "",
  }
  const [newData, setNewData] = useState(initialData);

  // ////////  crop modal/////////
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()
  // //////////////
  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  useEffect(() => {
    fetchData();
  }, [offset ,type]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`astroAarti/getAll/${type}/${offset}/${perPage}`, headers)
    let data = response?.response?.data
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false)
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage)
    const newOffset = selectedPage * perPage;
    setOffset(newOffset)
  }

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroAarti/updateFile`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData();
      }
    }
  }

  const validateAudioFile = (file) => {
    const acceptedFormats = ['audio/mpeg', 'audio/wav', 'audio/flac', 'audio/aac', 'audio/ogg'];
    return acceptedFormats.includes(file.type);
  };
  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (newData.title === "") 
      newErrors.title = "Please enter title.";

      if (newData.content === "") 
      newErrors.content = "Please enter content.";

      if (newData.file === "") 
      newErrors.file = "Please add image.";

      if (newData?.imageType === "")
      newErrors.imageType = "Please select content type.";
      
      if(newData?.audio === '')
      newErrors.audio = "Please add audio.";

      const audioValid = validateAudioFile(newData?.audio)

      if(audioValid === false)
      newErrors.audio = "Only MP3, MPEG, WAV, FLAC, AAC, and OGG audio file types are allowed.";

      // if(newData?.songUrl === '')
      // newErrors.songUrl = "Please add Song URL.";

      // if(newData?.description === '')
      // newErrors.description = "Please enter description.";

      return newErrors;
  };  
  async function addAarti() {
    let payload = new FormData();
    payload.append('title', newData?.title);
    payload.append('file', newData.file);
    if (newData.imageType === "english") {
      payload.append("contentLangEn", true);
      payload.append('contentEn', newData?.content);
    } else if (newData.imageType === "hindi") {
      payload.append("contentLangHi", true);
     payload.append('contentHi', newData?.content);
    }
    if (typeof newData?.audio === 'object') {
      payload.append('audio', newData?.audio);
    }
    // payload.append('description', newData.description);
    // payload.append('songUrl', newData.songUrl);

    payload.append("userId", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
    let response = await APIServices.post(`astroAarti/upload`, payload, { ...headers, "Content-Type": "multipart/form-data" },(progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted)
  })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
       setShowModal(false);
        toast.success(apiData?.message);
        setLoader(false)
        updateFile();
      setCropImg();

      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }

  async function updateAarti() {
    let payload = new FormData();

    if (typeof newData?.file === 'object') {
      payload.append('file', newData?.file);
    }
    if (typeof newData?.audio === 'object') {
      payload.append('audio', newData?.audio);
    }
    payload.append('title', newData?.title);
    if (newData.imageType === "english") {
      payload.append("contentLangEn", true);
      payload.append('contentEn', newData?.content);
      payload.append("contentLangHi", false);
      payload.append('contentHi', null);

    } else if (newData.imageType === "hindi") {
      payload.append("contentLangHi", true);
    payload.append('contentHi', newData?.content);
    payload.append("contentLangEn", false);
    payload.append('contentEn', null);
    }
    // payload.append('description', newData.description);
    // payload.append('songUrl', newData.songUrl);
    payload.append("user_id", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
    let response = await APIServices.put(`astroAarti/editOne/${newData?._id}`, payload, { ...headers, "Content-Type": "multipart/form-data" },(progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted)
  })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShowModal(false);
        toast.success(apiData?.message)
        setLoader(false)
        updateFile();
        setCropImg();

      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }
  

  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }
  const handledelete = async () => {
    let res = await APIServices.delete(`astroAarti/findByIdAndremove/${newData?._id}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        setOffset(0)
        updateFile()
      }
    }
  }
  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroAarti/updateStatus/${id}`, formData, headers)
      .then((res) => {
        updateFile()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleChange(e, field = "text") {       
    let key, value;
    key = e?.target?.name;
    if (field === "text") {
      value = e?.target?.value;
    }
    else if (field === "file") {
      value = e;
    }else if (field === "audio"){
      value = e?.target?.files?.[0];
    }
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
    setNewData(prev => ({ ...prev, [key]: value }))
  }

  function handleImage(e){
    setNewData(prev => ({...prev, file: e}))
  }
 
  function handleRt (e){
    let key = e?.target?.name;
    if (!!errors[key])
        setErrors({
          ...errors,
          [key]: null,
        });
    }
    
  return (
    <>
    {
      permissioncontext?.view ?
<div className="main aarti_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">

           <h4 className="hd-list-f left-q" >
            
           <FaArrowLeft
                 className="hd-cr "
                 style={{marginRight: "15px"}}
                 size={25}
                 onClick={() => navigate(-1)}/>
            
            Aarti <small>({allDataCount})</small></h4>
           <div className="_btn">
                <Button
                variant="primary"
                className="by-btn as_btn"
                disabled={!permissioncontext.add}
                onClick={()=>updateFile(true)}
              >
                Update File
              </Button>
              <Button
               className="by-btn as_btn me-0"
                variant="primary"
                disabled={!permissioncontext.add}
                onClick={() => {
                  setNewData(initialData);
                  setShowModal(true);
                  setCropImg()
                }}
              >
                Add Aarti
              </Button>
           </div>
           </div>
        </div>
        <div className="aarti_inner pt-5">
           <div className="container">
              <div className="row">
              {/* <div className="col-md-4 col-lg-3"> 
                   <Sidebar />
                 </div> */}
                 <div className="col-md-8 col-lg-12"> 
                 {loading ? <div className="center"><Spinner animation="border" /></div> :
          <>
          <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={type}
                            onChange={(e) => {setType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>

                          </Form.Select>
                        </div>
                      </div>
                    </div>

                
            <div className="row mt-3">
            
              {
                data?.map((item) =>
                <div className="col-md-6 col-lg-3">
                  <div className="card mb-4" key={item?._id} >
                    <div className="card-header">
                    <img
                       
                        src={(item?.file) ? item?.file : bimg}
                        className="card-img-top"
                        alt="img"
                      />
                      <div className="icon">
                        <FaEdit
                          className={permissioncontext.edit?"me-2 hd-cr ":"me-2 hd-cr disabled-btn"}
                          size={20}
                          onClick={() => {
                            if(permissioncontext.edit){

                              setShowModal(true);
                              setCropImg()
                              setNewData({
                                _id : item._id ,
                                title: item.title ?? "",
                                content:item.contentLangEn === true?  item.contentEn : item.contentHi ?? initialData,
                                file: item.file ?? "",
                                imageType: item?.contentLangHi ? "hindi" :item?.contentLangEn ? "english":"" ,
                                description:item?.description ?? "",
                                audio: item.audio ?? "",
                                songUrl: item.songUrl ?? "",
                              });
                            }
                          }}
                        />
                        <FaTrash
                          className={permissioncontext.delete?"dt-btn hd-cr ":"dt-btn hd-cr disabled-btn "}
                          size={20}
                          onClick={e => {
                            if(permissioncontext.delete){

                              setNewData(item);
                              handleShowDt()
                            }
                          }}
                        />
                        {item.status === true ? (
                        <input
                          type="button"
                          disabled={!permissioncontext.edit}
                          className={!permissioncontext.edit?"_active me-1  disabled-btn":'_active me-1 '}
                          style={{fontSize:"8px", borderRadius:"15px"}}
                          value="Active"
                          onClick={() =>
                            handleclick(item.status, item._id)
                          }
                        />
                      ) : (
                        <input
                          type="button"
                          disabled={!permissioncontext.edit}
                          className={!permissioncontext.edit?"_inactive  disabled-btn":'_inactive '}
                          style={{fontSize:"8px", borderRadius:"15px"}}
                          value="Inactive"
                          onClick={() =>
                            handleclick(item.status, item._id)
                          }
                        />
                      )}
                      </div>
                    </div>
                    <div className="card-body height_box" >
                    <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">
                        <span className="calicon">
                          <i className="ri-calendar-line"></i>
                        </span>
                        {new Date(item.updatedAt).toDateString()}
                      </p>
                      <h5 className="card-title heading_18 mb-2">{item.title}</h5>
                      <p className="card-text description">
                        
                        {  item.contentLangEn === true?item.contentEn?.slice(0, 180):item.contentHi?.slice(0,180)}
                       
                      </p>
                    </div>
                   
                    <span className="ms-3" style={{color:"", textAlign: "center",
                  fontSize:"15px"}}>
                   <a rel="noopener" target = "_blank" href={item.audio}>
                    Listen Aarti
                    </a>

                  </span>
                    <div className="card-footer text-center">
                        <Button
                          className="btn-more as_btn"
                          variant="primary"
                          onClick={() => {
                            setNewData(item);
                            setShow(true);
                          }}
                        >
                          see more
                        </Button>
                    </div>
                  </div>
                  </div>

                )
              }
           
            </div>
           
          </>
        }

<div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
                 </div>
              </div>
           </div>
        </div>
      </div>:
      <Notauthorized />
    }
      

      <ModalComponent
        id="readMore"
        show={show}
        onHide={() => setShow(false)}
        modalHeading={newData?.title}
        size="lg"
        localData={ newData.contentLangEn === true? newData?.contentEn : newData?.contentHi}
      />
      
      <ModalComponent
        id="updateAarti"
        show={showModal}
        onHide={() => {setShowModal(false);setErrors("")}}
        modalHeading={(newData?._id) ? "Edit Aarti" : "Add Aarti"}
        size="lg"
        localData={newData}
        errors = {errors}
        loader={loader}
        progress ={progress}
        onButtonClick={(newData?._id) ? updateAarti : addAarti}
        handleChange={handleChange}
        handleImage={handleImage}
        handleRt={handleRt}
        setShowCrop={setShowCrop}
        caartifileimage={caartifileimage}
        setFileCaartiimage={setFileCaartiimage}
        cropimg={cropimg}
         setCropImg={setCropImg}
         showcrop={showcrop}
      />

      <Modal centered show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt} 
        >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  )
}