import { useState, useCallback, useMemo, useEffect,useContext} from 'react';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import APIServicenew from '../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/default.jpg";
import FestivalCrop from './festivals/festivalCrop';
import moment from 'moment';
import { UserContext } from "../context/UserContextProvider";
import { getBaseURL ,setCookies } from "../utils/Helper";
import { useNavigate } from "react-router-dom";

import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

function ChanakyasQuotes() {
  document.title = 'Quotes';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const permissioncontext =usePermissionData("chanakyasquotes")

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [loader,setLoader]=useState(false)
  const [contentHi, setContentHi] = useState();
  const [quoteImage, setQuoteImage] = useState();
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [status , setStatus] = useState("all")
  const [showQuoteModal, setShowQuoteModal] = useState();
  const [selectedId, setSelectedId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allDataCount , setAllDataCount] = useState()
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [ image , setImage] = useState("");
  const [selectedIds ,setSelectedIds] = useState([])
  const [ flag , setFlag] = useState();
  const [ flag1 , setFlag1] = useState(false)
  const [checked,setChecked]=useState()
  const [selectStatus,setSelectStatus]=useState()
  const [language,setLanguage]=useState("english")
  const [ contentType , setContentType] =  useState("")
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();


  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const handleActiveClick = async (skip, limit, index, status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append('status', datas);

    await APIServices.put(`chanakyasQuotes/updateStatus/${id}`, formData, headers)
      .then(res => {
        // fetchProfilesByPagination(skip, limit, index);
        handleJSONFileUpdate()
      })
      .catch(err => {
        console.log(err);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Select',
        Cell: e => 
          <input
          className="form-check-input border_checkbox_c"
          style={{width:"1.5em",height:"1.5em"}}
            type='checkbox'
            checked={selectedIds.includes(e?.row?.original?._id)? true : false}
            onClick={(event) => handleMultiDt(event,e?.row?.original?._id,e?.row?.original?.status)}
          />
        
      },
      {
        Header: 'NO',
        Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
      },
      {
        Header: ' Quotes',
        Cell: e => {
          if (e.row.original.file) {
            return (
              <img
                src={`${e.row.original.file}`}
                alt=''
                className='quotes_table_preview_image'
              />
            );
          } else {
            return <span>{e.row.original.contentHi}</span>;
          }
        },
      },
      {
        Header: 'Action',
        Cell: e => {
          if (e.row.original.status === true) {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.edit?"_active  disabled-btn":'_active '} 
                  disabled={!permissioncontext.edit}
                  value='Active'
                  onClick={() =>
                    handleActiveClick(
                      e.state.pageIndex * e.state.pageSize,
                      e.state.pageSize,
                      e.state.pageIndex,
                      e.row.original.status,
                      e.row.original._id
                    )
                  }
                />
              </h5>
            );
          } else {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.edit?"_inactive  disabled-btn":'_inactive '} 
                  disabled={!permissioncontext.edit}
                  value='Inactive'
                  onClick={() =>
                    handleActiveClick(
                      e.state.pageIndex * e.state.pageSize,
                      e.state.pageSize,
                      e.state.pageIndex,
                      e.row.original.status,
                      e.row.original._id
                    )
                  }
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Edit',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.edit?"Et-btn edit-btn1  disabled-btn":'Et-btn edit-btn1 '} 
                  disabled={!permissioncontext.edit}
                  value='Edit'
                  onClick={() =>
                    handleShowQuoteModal(
                      e.row.original._id,
                      e.row.original.contentHi,
                      e.row.original.file,
                      e.row.original.contentLangEn === true?"english":e.row.original?.contentLangHi === true ? "hindi" :"",
                      e.row.original.category,
                      e.row.original.tags
                    )
                  }
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Delete',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>
                <input
                  type='button'
                  className={!permissioncontext.delete?"Dt-btn  disabled-btn":'Dt-btn '} 
                  disabled={!permissioncontext.delete}
                  value='Delete'
                  onClick={() => handleShowDeleteModal(e.row.original._id)}
                />
              </h5>
            );
          }
        },
      },
      {
        Header: 'Date',
        Cell: e => {
          return <span>{moment(e.row.original.updatedAt).format("DD MMM, YYYY hh.mm A")}</span>;
        },
      },
    ],
    [checked ,selectedIds,permissioncontext]
  );

  const fetchProfilesByPagination = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(`chanakyasQuotes/getAll/${language}/${skip}/${limit}?status=${status}`, headers);
        let data = response?.response?.data
       
        if (response.data.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount)
          setTableData(response.data?.Listdata);
          setSelectedIds([])
          window.scrollTo({ top: 0 });
        }else{
          if(data.status == 4){
          logout()
         }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex,language,status]
  );

  const handleJSONFileUpdate = async (showToast) => {
    const response = await APIServices.get('chanakyasQuotes/updateFile', headers);
    if (response.data.status === true) {
      fetchProfilesByPagination(skip, limit, pageIndex);
      if (showToast) {
        setTimeout(() => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 1000);
      }
    }
  };

  const handleQuotesModalClose = () => {
    setShowQuoteModal(false);
    setErrors("")
    setSelectedId(null);
    setContentHi(null);
    setQuoteImage(null);
    setContentType("");
    setCategory("");
    setTags("");
    setImage("")
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedId(null);
  };

  const handleShowQuoteModal = (id, contentHi, file,contentType, category, tags) => {
    setContentHi(contentHi);
    setQuoteImage(file==="null"?"":file);
    setContentType(contentType)
    setCategory(category);
    setTags(tags);
    setSelectedId(id);
    setShowQuoteModal(true);
  };

  const handleShowDeleteModal = id => {
    setShowDeleteModal(true);
    setSelectedId(id);
  };

  const handleDelete = async () => {
    await APIServices.delete(`chanakyasQuotes/findByIdAndremove/${selectedId}`, headers)
      .then(res => {
        if (res.data.status === true) {
          // fetchProfilesByPagination(skip, limit, pageIndex);
          handleJSONFileUpdate()
          setShowDeleteModal(false);
          setTimeout(() => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setSelectedId(null);
        setContentHi(null);
        setQuoteImage(null);
        setContentType("");
        setCategory("");
        setTags("");
      });
  };


  const reset =() =>{
    setSelectedId(null);
    setContentHi(null);
    setQuoteImage(null);
    setContentType("");
    setCategory("");
    setTags("");
  }

  useEffect(()=>{
    fetchProfilesByPagination(skip, limit, pageIndex);
  },[language,skip ,status])


  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
    if(quoteImage==="null"&&(!contentHi||contentHi === ""||contentHi==="null"))
    newErrors.file = 'Please enter quote content or quote image.';
  
      if ((!contentHi || contentHi === "")&& (!quoteImage || quoteImage === "")) 
      newErrors.file = 'Please enter quote content or quote image.';



      let maxLength = 10
 
        if (image && (image.length > maxLength && !selectedId)) 
        newErrors.file = "Images can't be more then 10";


        if (!contentType ||contentType === "") 
        newErrors.contentType = "Please select content type."; 


      return newErrors;
  }; 
  const handleQuoteButtonClick = async e => {
    e.preventDefault();
    const newErrors = validateInputs();
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let res;
      try {
        if (selectedId) {
          const formData = new FormData();
    contentHi && formData.append('contentHi', contentHi);
    if (quoteImage && typeof quoteImage === 'object') {
      formData.append('file', quoteImage);
    }
    if (contentType === "english") {
      formData.append("contentLangEn", true);
      formData.append("contentLangHi", false);

    } else if (contentType === "hindi") {
      formData.append("contentLangHi", true);
      formData.append("contentLangEn", false);
    }
    formData.append('category', category);
    formData.append('tags', tags);
    formData.append('user_id', astroUserId);
    const newHeaders = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    };

    
    setLoader(true)
          res = await APIServices.put(`chanakyasQuotes/editOne/${selectedId}`, formData, newHeaders);
          if (res.data.status === true) {
            setShowQuoteModal(false);
            setLoader(false)
            setCropImg();
            // fetchProfilesByPagination(skip, limit, pageIndex);
            handleJSONFileUpdate()
            setTimeout(() => {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 1000);
            reset()
            
          }else if(res.data.status === false){
            toast.error(res.data.message);
            setLoader(false)
          }
        } else {
          if((quoteImage===undefined||quoteImage===null||quoteImage.length===0)&&contentHi){
            const formData = new FormData();
    contentHi && formData.append('contentHi', contentHi);
    // if (quoteImage && typeof quoteImage === 'object') {
    //   formData.append('file', quoteImage);
    // }
    if (contentType === "english") {
      formData.append("contentLangEn", true);

    } else if (contentType === "hindi") {
      formData.append("contentLangHi", true);
    }
    formData.append('category', category);
    formData.append('tags', tags);
    formData.append('user_id', astroUserId);
    const newHeaders = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    };

   
    setLoader(true)
          res = await APIServices.post(`chanakyasQuotes/createquote`, formData, newHeaders);
          if (res.data.status === true) {
            setShowQuoteModal(false);
            setLoader(false)
            setCropImg();
            // fetchProfilesByPagination(skip, limit, pageIndex);
            handleJSONFileUpdate()
            setTimeout(() => {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 1000);
            reset()
            
          }else if(res.data.status === false){
            toast.error(res.data.message);
            setLoader(false)
          }

          }else{

          let i = 0;
          for (i;i< quoteImage.length;i++) {
          const formData = new FormData();
    contentHi && formData.append('contentHi', contentHi);
    if (quoteImage && typeof quoteImage === 'object') {
      formData.append('file', quoteImage[i]);
    }
    if (contentType === "english") {
      formData.append("contentLangEn", true);

    } else if (contentType === "hindi") {
      formData.append("contentLangHi", true);
    }
    formData.append('category', category);
    formData.append('tags', tags);
    formData.append('user_id', astroUserId);
    const newHeaders = {
      ...headers,
      'Content-Type': 'multipart/form-data',
    };

   
    setLoader(true)
          res = await APIServices.post(`chanakyasQuotes/createquote`, formData, newHeaders);
          if (res.data.status === true) {
            setShowQuoteModal(false);
            setLoader(false)
            setCropImg();
            // fetchProfilesByPagination(skip, limit, pageIndex);
            handleJSONFileUpdate()
            setTimeout(() => {
              toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 1000);
            reset()
            
          }else if(res.data.status === false){
            toast.error(res.data.message);
            setLoader(false)
          }
        }
        setImage("")
      }
        }
       
        setLoader(false)
      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    }
  };

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if(key === "quote"){
      key = "quoteImage"
    }
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()


useEffect(()=>{
  if(cropimg){ 
    setQuoteImage(cfileimage)
  }
},[cropimg,cfileimage])


useEffect(()=>{
  fetchProfilesByPagination(skip, limit, pageIndex);
  setChecked()
},[checked])


const multidelete = () => {
 if (flag === true) {
  setShowDeleteModal(true)
 }else{
  toast.warn("Please select records for delete");
 }
}

const handleMultiDt = (event,id, status) => {
  let checked =event?.target?.checked
  setSelectStatus(status)
  if(checked === true){
    setFlag(true)
    setSelectedIds(current => [...current, id]);
  }else if(checked === false){
    setSelectedIds(current => current.filter((ls)=> ls !== id))
  }
}


useEffect(()=>{
  if (selectedIds?.length === 0) {
    setFlag(false)
  }
},[selectedIds])

const handleMultidelete = async () => {

  let payload = {chanakyasIds: selectedIds}
  let res = await APIServices.delete(`chanakyasQuotes/deleteMultiQuotes`, headers ,payload)
  if (res?.status === 200) {
     
    setShowDeleteModal(false);
    
    let apiData = res?.data;
    if (apiData?.status) {
      toast.success(apiData?.message);
      handleJSONFileUpdate()
      setSelectedIds([])
      setFlag(false)
      // reset()
      setChecked(false)
      
    }
  }
}

const multiActivecheck = () => {
  if (flag === true) {
   multiActive()
  }else{
   toast.warn("Please select records for Active");
  }
 }


const multiActive = async () => {
  // var status=selectStatus ===true? false : true;
  let payload = {chanakyasIds: selectedIds,status:true}

  let res = await APIServices.put(`chanakyasQuotes/updateMultiStatus`,payload, headers )
  if (res?.status === 200) {
     
    
    
    let apiData = res?.data;
    if (apiData?.status) {
      toast.success(apiData?.message);
      handleJSONFileUpdate()
      setSelectedIds([])
      setSelectStatus()
      setFlag(false)
      // reset()
      setChecked(false)
      
    }
  }
}


const multiInactivecheck = () => {
  if (flag === true) {
  multiInactive()
  }else{
   toast.warn("Please select records for Inactive");
  }
 }


const multiInactive = async () => {
  
  let payload = {chanakyasIds: selectedIds,status:false}

  let res = await APIServices.put(`chanakyasQuotes/updateMultiStatus`,payload, headers )
  if (res?.status === 200) {
     
    
    
    let apiData = res?.data;
    if (apiData?.status) {
      toast.success(apiData?.message);
      handleJSONFileUpdate()
      setSelectedIds([])
      setSelectStatus()
      setFlag(false)
      // reset()
      setChecked(false)
      
    }
  }
}



var fileObj = [];
var arrobj = [];
const onImageChange = (event) => {
if (event.target.files && event.target?.files) {
  setQuoteImage(event.target.files);
  fileObj.push(event.target.files);
  for (let i = 0; i < fileObj[0]?.length; i++) {
    arrobj?.push(URL.createObjectURL(fileObj[0][i]));
    setImage(arrobj);
  }
}
let key, value;
key = event?.target?.name;
if (!!errors[key]){
  setErrors({
    ...errors,
    [key]: null,
    });
}
};
  
  const renderQuoteFormModal = () => {
    return (
      <Modal centered scrollable show={showQuoteModal} backdrop='static' onHide={()=>{handleQuotesModalClose(); setCropImg()}}>
        <Modal.Header closeButton>
          <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Quote`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm="3">
                Quote
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as='textarea'
                  type='text'
                  placeholder='Quote...'
                  name='file'
                  style={{ height: 100 }}
                  onChange={e => {setContentHi(e.target.value);handleChange(e)}}
                  defaultValue={contentHi}
                />
              </Col>
            </Form.Group>
            <div className='text-center mb-2 fw-bold text-danger'>OR</div>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm="3">
                Quote Image
              </Form.Label>
              <Col sm="9">
              {selectedId?
              <label id="labimg">
                <Form.Control
                  as='input'
                  // type='file'
                  isInvalid={!!errors?.quoteImage}
                  className='is-invalid-Q'
                  name='file'
                  onClick={e => {
                    setShowCrop(true);handleChange(e)
                  }}
                  accept='image/png, image/gif, image/jpeg'
                  size='40'
                />
               
                {cropimg?<img 
                  // crossOrigin="anonymous" 
                  className="imgs" 
                  src={cropimg}
                  onClick={()=>setCropImg()}
                  width="100%" alt="" />:<img 
                  className="imgs" 
                  // src={file ? file : upimg}
                  src={selectedId ? quoteImage?quoteImage:bimg:""}
        
                  width="100%" alt="" />}
                 <Form.Control.Feedback type="invalid">
                    {errors?.quoteImage}
               </Form.Control.Feedback>
               </label>:<Form.Control
                type="file"
                name="file"
                isInvalid={!!errors?.file}
                className="form-control "
                onChange={onImageChange}
                size="40"
                accept="image/png, image/gif, image/jpeg"
                multiple
             />
             }
             <Form.Control.Feedback type="invalid">
             {errors?.file}
        </Form.Control.Feedback>
             {image?
             <div className="gallery">
             {image
                ? image?.map((item, key) => (
                    <img width="60px" height="60px" src={item} alt="" />
                  ))
              : ""}
             </div>:""}
             {selectedId?"":<small>Maximum Limit 10</small>}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Content Type
              </Form.Label>
              <Col sm="9">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  name ="contentType"
                  defaultValue={contentType}
                  isInvalid={!!errors?.contentType}
                  onChange={(e) =>{ setContentType(e.target.value);handleChange(e)}}
                >
                  <option value="">Select</option>
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors?.contentType}
              </Form.Control.Feedback>
              </Col>
              </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='' column sm="3">
                Category
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type='text'
                  placeholder='Category...'
                  name='category'
                 
                  onChange={e => {setCategory(e.target.value);}}
                  defaultValue={category}
                />
             
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='' column sm="3">
                Tags
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type='text'
                  placeholder='Tag...'
                  name='tags'
             
                  onChange={e => {setTags(e.target.value);}}
                  defaultValue={tags}
                />
               
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        {loader?<Button variant='primary' className='as_btn' >
        {`${selectedId ? 'Processing...' : 'Processing...'}`}
      </Button>:<Button variant='primary' className='as_btn' onClick={e => handleQuoteButtonClick(e)}>
      {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
          <Button variant='secondary' className='as_btn reset-btn' onClick={()=>{handleQuotesModalClose(); setCropImg()}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };




  return (
    <>
    {
      permissioncontext?.view?
<div className='App'>
      <div className='gita_quote_heading'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>Chanakyas Quote List <small>({allDataCount})</small></h4>
            <div className='_btn'>
            <Button variant='primary'disabled={!permissioncontext.edit} className='by-btn as_btn' onClick={() => multiActivecheck()}>
                Multi Active
              </Button>
              <Button disabled={!permissioncontext.edit} variant='primary' className='by-btn as_btn' onClick={() => multiInactivecheck()}>
                Multi Inactive
              </Button>
              <Button disabled={!permissioncontext.add} variant='primary' className='by-btn as_btn' onClick={() => handleJSONFileUpdate(true)}>
                Update File
              </Button>
              <Button disabled={!permissioncontext.add} variant='primary' className='add-btn as_btn' onClick={() => setShowQuoteModal(true)}>
                Add Quote
              </Button>
              <Button disabled={!permissioncontext.delete} variant='primary' className='by-btn as_btn ms-3' style={{background:"red"}} onClick={() => multidelete()}>
                Multi Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-2">
       <div className="d-flex align-items-center" style={{justifyContent:"flex-end"}}>
         <h4 className="hd-list-f left-q"></h4>
         <Form.Select
          aria-label="categories"
          className=" w-20 astroWatch-select-hover hd-cr right-q"
          value={status}
          onChange={(e) => {setStatus(e.target.value);setSkip(0);setPageIndex(0)}}
        >
          <option value="all">All</option>
          <option value="active" >Active</option>
          <option value= "inActive">InActive</option>
        </Form.Select>
         <Form.Select
           aria-label="categories"
           className=" w-20 astroWatch-select-hover hd-cr"
            value={language}
            onChange={(e) => {setLanguage(e.target.value);setSkip(0);setPageIndex(0)}}
         >
           <option value="hindi">Hindi</option>
           <option value="english">English</option>
         </Form.Select>
                    </div>
                    </div>
      {renderQuoteFormModal()}
      <Modal
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>{flag === true?`Are you sure you want to delete ${selectedIds?.length} records?`:` Are you sure you want to delete this element?`}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={flag === true?handleMultidelete:handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <FestivalCrop 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    
    setFileCimage={setFileCimage}/>

      <ToastContainer />
      {
        tableData && tableData.length > 0 ? 
        (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            // canEdit={canEdit}
            // canDelete={canDelete}
            // canAction={canAction}
            shouldUpdate={true}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={fetchProfilesByPagination}
          />
        ):
        (<h2 className="no_records">Records not Available</h2>)
      }
     
    </div>:
     <Notauthorized />
    }
    </>
    
  );
}

export default ChanakyasQuotes;
