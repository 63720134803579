import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import APIServicenew from '../utils/APIGeneralService';
import { getCookies } from '../utils/Helper';
import { ToastContainer, toast } from 'react-toastify';
import { useToasts } from 'react-toast-notifications';
import Modal from 'react-bootstrap/Modal';

export default function Whatsapptemplatechange() {
  document.title = 'Whatsapp Template';
  const { state } = useLocation();
  const[addcheck,setAddCheck]=useState(state)
  var navigate = useNavigate();
  const { addToast } = useToasts();
  const templatedata = JSON.stringify(state?.row?.template, null, 2);
  const [headline, setHeadLine] = useState(state?.row?.Name || []);
  const [title ,setTitle] = useState(state?.row?.title)
  const [type, setType] = useState(state?.row?.type);
  const [message, setMessage] = useState(state?.row?.messageForResponder);
  const [template, setTemplate] = useState(templatedata);

  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const token = getCookies('accessToken');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const[phoneno,setPhoneNo]=useState()
  const[testid,setTestId]=useState(state?.row?._id)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 

  const handleArr = e => {
    let headlinear = e.target.value?.split(',');
    setHeadLine(headlinear);
  };

  const validateInputs = () => {
    let isValid = false;

    const newErrors = {};
    if (!title || title === '') newErrors.title = 'Please enter title.';
if(addcheck){
  if (!type || type === '') newErrors.type = 'Please enter type.';

}
if(type=='default_msg'){

}else{

  if (!headline || headline == '') newErrors.headline = 'Please enter headline.';
}

    if (!message || message === '') newErrors.message = 'Please enter message.';

    if (!template || template === '') newErrors.template = 'Please enter template.';

    return newErrors;
  };

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;

    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  const validateInputsTest = () => {
    let isValid = false;

    const newErrors = {};
    if (!phoneno || phoneno === '') newErrors.phoneno = 'Please enter phone number.';
    if (phoneno?.length > 10 || phoneno?.length < 10) newErrors.phoneno = 'Please enter valid number.';


    return newErrors;
  };

  function handleChangeTest(e) {
    let key, value;
    key = e?.target?.name;

    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }


  const handleTest = async e => {
   
    const newErrors = validateInputsTest();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let payload = {
        mobileNumber:phoneno
      };
      const response = await APIServices.post(`/whatsAppApi/wAppTemplateTest/${testid}`, payload, headers);

      if (response?.data?.status === true) {
        handleClose();
        setTestId();
        setPhoneNo();
        setLoader(false);
        toast.success(response?.data.message);
      } else {
        setLoader(false);
      }
    }
  };

  const handleTemplate = e => [
    // console.log("object",JSON.parse(e?.target?.value))
    setTemplate(e?.target?.value),
  ];

  const handleEdit = async e => {
    let payload = {
      headlineArr: headline,
      // messageType:type,
      title:title,
      messageForResponder: message,
      template: JSON.parse(template),
    };
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      const response = await APIServices.post(`whatsAppApi/wAppTemplateUpdate/${state?.row?._id}`, payload, headers);

      if (response?.data?.status === true) {
        // navigate("/whatsapptemplate")
        setLoader(false);
        toast.success(response?.data.message);
      } else {
        setLoader(false);
      }
    }
  };


  const handleAdd = async e => {
   
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let payload = {
        headlineArr: headline,
        // messageType:type,
        title:title,
        messageForResponder: message,
        template: JSON.parse(template),
      };
      const response = await APIServices.post(`whatsAppApi/addTemp`, payload, headers);

      if (response?.data?.status === true) {
        navigate("/whatsapptemplate")
        setLoader(false);
        toast.success(response?.data.message);
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <div
        className='notifiction_area pb-4'
        style={{
          backgroundImage: 'url("/images/why_choose_us.jpg")',
        }}
      >
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            {!addcheck?<h4 className='hd-list-f left-q'>Add Template</h4>:<h4 className='hd-list-f left-q'>Edit Template</h4>}
            
          </div>
        </div>
        <div className='container'>
          <div className='notifiction_form box-shadow br-6 p-20  bg-white'>
            <Row>
              

              <Col className='my-2 col-12 col-sm-6' md={12}>
                <Form.Group as={Col} size='md' controlId='validationCustom01'>
                
                    <>
                      <Form.Label class='required'>Title</Form.Label>
                      <Form.Control
                        required
                        name='title'
                        type='text'
                        placeholder='Title'
                        value={title}
                        isInvalid={!!errors.title}
                        onChange={e => {
                          setTitle(e.target.value);
                          handleChange(e);
                        }}
                      />
                    </>
                  <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className='my-2 col-12 col-sm-6 col-md-4' md={6}>
                <Form.Group as={Col} size='md' controlId='validationCustom01'>
                  {!addcheck  ? (
                    ""
                  ) :type == 'default_msg' ? (
                    <>
                      {' '}
                      <Form.Label class=''>Type</Form.Label>
                      <Form.Control
                        required
                        // name="type"
                        type='text'
                        placeholder='Type'
                        readOnly
                        value='Default message'
                      />{' '}
                    </>
                  ) : (
                    <>
                      <Form.Label class='required'>Type</Form.Label>
                      <Form.Control
                        required
                        name='type'
                        type='text'
                        placeholder='Type'
                        readOnly
                        value={type}
                        isInvalid={!!errors.type}
                        onChange={e => {
                          setType(e.target.value);
                          handleChange(e);
                        }}
                      />
                    </>
                  )}

                  <Form.Control.Feedback type='invalid'>{errors.type}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className='my-2 col-12 col-sm-6 col-md-4' md={12}>
                <Form.Group as={Col} size='md' controlId='validationCustom01'>
                  {type == 'default_msg' ? (
                    <>
                      <Form.Label class=''>Head Line</Form.Label>
                      <Form.Control
                        required
                        // name="headline"
                        type='text'
                        placeholder='Headline'
                        readOnly
                        defaultValue={headline}
                        // isInvalid={!!errors.headline}
                        onChange={e => handleArr(e)}
                      />
                    </>
                  ) : (
                    <>
                      <Form.Label class='required'>Head Line <small>(please enter comma separated)</small> </Form.Label>
                      <Form.Control
                        required
                        name='headline'
                        type='text'
                        placeholder='Headline'
                        defaultValue={headline}
                        isInvalid={!!errors.headline}
                        onChange={e => {
                          handleArr(e);
                          handleChange(e);
                        }}
                      />
                    </>
                  )}
                  <Form.Control.Feedback type='invalid'>{errors.headline}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className='my-2 col-12 col-sm-6 col-md-4' md={12}>
                <Form.Group as={Col} size='md' className='' controlId='validationCustom01'>
                  <Form.Label class='required'>Message For Responder</Form.Label>
                  <Form.Control
                    required
                    as='textarea'
                    placeholder='Message For Responder'
                    value={message}
                    name='message'
                    onChange={e => {
                      setMessage(e.target.value);
                      handleChange(e);
                    }}
                    isInvalid={!!errors.message}
                    style={{ marginTop: '8px', height: '160px' }}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className='my-2 col-12 col-sm-6 col-md-4' md={12}>
                <Form.Group as={Col} size='md' className='' controlId='validationCustom01'>
                  <Form.Label class='required'>Template</Form.Label>
                  <Form.Control
                    required
                    as='textarea'
                    placeholder='Template'
                    name='template'
                    value={template}
                    onChange={e => {
                      handleTemplate(e);
                      handleChange(e);
                    }}
                    isInvalid={!!errors.template}
                    style={{ marginTop: '8px', height: '500px' }}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.template}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-center d-flex'>
              {!addcheck?loader ? (
                <Button variant='primary' className='as_btn' type='submit'>
                  Processing...
                </Button>
              ) : (
                <Button variant='primary' className='as_btn' type='submit' onClick={e => handleAdd(e)} >
                {/* onClick={e => handleAdd(e)} */}
                  Add Template
                </Button>
              ):loader ? (
                <Button variant='primary' className='as_btn' type='submit'>
                  Processing...
                </Button>
              ) : (
                <Button variant='primary' className='as_btn' type='submit' onClick={e => handleEdit(e)}>
                  Edit Template
                </Button>
              )}
              {/* {loader ? (
                <Button variant='primary' className='as_btn' type='submit'>
                  Processing...
                </Button>
              ) : (
                <Button variant='primary' className='as_btn' type='submit' onClick={e => handleEdit(e)}>
                  Edit Template
                </Button>
              )} */}
              
              <Button variant='primary' className='as_btn reset-btn' type='submit' onClick={() => navigate('/whatsapptemplate')}>
                Back
              </Button>
              {addcheck?
              <Button variant='primary' className='as_btn test-btn' type='submit' onClick={() =>{handleShow();} }>
                Test Template
              </Button>:""}
            </Row>
          </div>
        </div>
      </div>
      <Modal centered show={show}  size='sm' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Test Template</Modal.Title>
        </Modal.Header>
        <Modal.Body  ><div className='container'><h5 className='font_20'>Enter phone number</h5>
        <input type='number'
        className='px-2 br-6 form-control'
                         maxLength={10}
                         minLength={10}
                         name="phoneno"
                        placeholder='Enter phone number'
                        value={phoneno}
                        isInvalid={!!errors.phoneno}
                        onChange={e => {
                          setPhoneNo(e.target.value);
                          handleChangeTest(e);
                        }} 
                        /><div type='invalid' className='template-error' >{errors.phoneno}</div> </div> </Modal.Body>
        <Modal.Footer >
        <Button variant="primary"  className='as_btn px-3' onClick={handleTest}  >
        {/* onClick={handleAdd} */}
            Yes
          </Button>
          <Button variant="secondary"  className='as_btn cancle_btn' onClick={handleClose}>
            Cancel
          </Button>
          
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}
