import { Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import { Link, useNavigate , useLocation} from 'react-router-dom'


const Sidebar = () => {
    const location = useLocation();
    const active = location.pathname.split("/")
    return (
        <>
            <div className="sidenav">
                {/* <Link to="/puja" className={active[1] ==="puja" ? 'activel' :''}>Puja</Link> */}
                <Link to={'/mysteriousTemple' } className={active[1] ==="mysteriousTemple" ? 'activel' :''}> Mysterious Temple</Link>
                {/* <Link to="/todaysThought" className={active[1] ==="todaysThought" ? 'activel' :''}>Today's Thought</Link>
                <Link to="/morningQuotes" className={active[1] ==="morningQuotes" ? 'activel' :''}>Morning Quotes</Link>
                <Link to="/inspiringQuotes" className={active[1] ==="inspiringQuotes" ? 'activel' :''}>Inspiring Quotes</Link> */}
                <Link to={"/banner"}  className={`${active[1] ==="banner" ? 'activel' :''}`}> Manage Banners </Link>
                <Link to={"/mastergod"}  className={active[1] ==="mastergod" ? 'activel' :''}>Mandir God</Link>
                {/* <Link to="/godImages" className={active[1] ==="godImages" ? 'activel' :''}>God Images</Link> */}
                <Link to={ "/questions"}  className={active[1] ==="questions" ? 'activel' :''}>Questions</Link>
                {/* <Link to="/aarti" className={active[1] ==="aarti" ? 'activel' :''}> Aarti </Link> */}
                <Link to={false ?"/siddhaPurush": '#'}  className={`lineThrough ${active[1] ==="siddhaPurush" ? 'activel' :''}`}>Siddha Purush</Link>
                <Link to={false ?"/books" : '#' }  className={`lineThrough ${active[1] ==="books" ? 'activel' :''}`}>Books</Link>
                <Link to={false ? "/bhajan" : '#'}  className={`lineThrough ${active[1] ==="bhajan" ? 'activel' :''}`}> Bhajan </Link>
                <Link to={false ? "/chalisa" : '#'}  className={`lineThrough ${active[1] ==="chalisa" ? 'activel' :''}`}> Chalisa </Link>
                <Link to={false ? "/mantra" : '#'}  className={`lineThrough ${active[1] ==="mantra" ? 'activel' :''}`}> Mantra </Link>
                <Link to={false ? "/devLok" : '#'}  className={`lineThrough ${active[1] ==="devLok" ? 'activel' :''}`}>DevLok</Link>
                <Link to={false ? "/panchang" : '#'}  className={`lineThrough ${active[1] ==="panchang" ? 'activel' :''}`}>Panchang</Link>
                <Link to={false ? "/astrogurus" : '#'}  className={`lineThrough ${active[1] ==="astrogurus" ? 'activel' :''}`}>Guru</Link>
                <Link to={false ? "/astrologyAdvice" : '#'}  className={`lineThrough ${active[1] ==="astrologyAdvice" ? 'activel' :''}`}>Astrology Advice</Link>
                <Link to={false ? "/meditationYogaAndExercise" : '#'}  className={`lineThrough ${active[1] ==="meditationYogaAndExercise" ? 'activel' :''}`}> Meditation Yoga And Exercise</Link>
                <Link to={false ? "/pujaFromPanditJi" : '#'}  className={`lineThrough ${active[1] ==="pujaFromPanditJi" ? 'activel' :''}`}>Puja From Pandit Ji</Link>   
            </div>
        </>
    )
};
export default Sidebar;
