import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import { formateDateAndTime, SlugifyStringWithDash, capitalizeFirstLetter, setCookies } from '../../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import usePermissionData from '../../components/Customhook/custompermission';
import Notauthorized from '../../components/Notauthorized';
import Papa from 'papaparse';

const PujaPandit = () => {
  const navigate = useNavigate();
  document.title = 'Pandit List';
  const permissioncontext =usePermissionData("pujaPandit")
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [status, setStatus] = useState('');
  const [singleData,setSingleData] = useState([])
  const [singleDataModal,setSingleDataModal] = useState(false)

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Name',
      Cell: e => {
        // return <span>{e.row.original.name ?? '_'}</span>;
        return  <Link to={`/panditOfPujaDetails?panditId=${e.row.original?._id}`}>{e.row.original?.name ?? '_'}</Link>
      },
    },
   
    {
      Header: 'Address',
      Cell: e => {
        return <span>{e.row.original.address ?? '_'}</span>;
      },
    },
     {
      Header: 'Mobile Number',
      Cell: e => {
        return <span>{e.row.original.maskedContactNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Status',
      Cell: e => {
        if (e.row.original.status === 1) {
          return (
            <h5>
              <input type='button'  className={!permissioncontext.edit?"_active disabled-btn":'_active'} disabled={!permissioncontext.edit} value='Active' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        } else {
          return (
            <h5>
              <input type='button'  className={!permissioncontext.edit?"_inactive disabled-btn":'_inactive'} disabled={!permissioncontext.edit} value='Inactive' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        }
      },
    },
    {
      Header: 'Details',
      Cell: e => {
        return (
          <>
             <h5>
                <input
                  type='button'
                  className='Et-btn notes-btn'
                  value='View Details'
                  onClick={() => {
                    setSingleData(e.row.original);
                    setSingleDataModal(true);
                  }}
                  
                />
             </h5>
          </>
        );
      },
    },
    {
      Header: 'Created At',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original._id) {
          
          return (
            <>
            <div className='d-flex'>
                <Button type="button" disabled={!permissioncontext.edit}  className={!permissioncontext.edit?"Et-btn edit-btn1 disabled-btn":'Et-btn edit-btn1'} onClick={()=>{getEditaData(e.row.original)}}>Edit</Button>
                <Button type="button" disabled={!permissioncontext.delete}  className={!permissioncontext.delete?"ms-2 Dt-btn disabled-btn":'ms-2 Dt-btn'} onClick={() => { handleShowDt(e.row.original)}}>Delete</Button>
            </div>
            </>
          );
        }
      },
    },
   
  ]);

  const [showdt,setShowdt ] = useState(false)
  const [selectedId, setSelectedId] = useState("")

  const handleCloseDt = () => setShowdt(false);
  const handleShowDt = id => {
    setSelectedId(id?._id);
    setShowdt(true);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const getEditaData = async (data) => {
    navigate(`/addEditPandit?editDataId=${data?._id}`);
  };

  const handleDelete = async () => {
    const response = await APIServices.delete(`pandit/deleteOne/${selectedId}`, headers);
    if (response?.data?.status === true) {
      let newData = tableData.filter(item => item._id !== selectedId);
      setTableData(newData);
      toast.success(response?.data.message);
      setShowdt(false);
      handleCloseDt()
      pujaPanditList()
    } else {
      console.log("Something went Wrong")
    }
  };

  const handleReset = () => {
    setName('')
    setAddress('')
    setMobileNumber('')
    setStatus('')
    setFlag(true)
  };

 
  useEffect(() => {
    pujaPanditList(skip, limit, pageIndex);
  }, [flag]);

  const pujaPanditList = useCallback(
    async (skip, limit, index) => {
      try {
        let updateStatus = status === "Select Status" ? "" : status
        const response = await APIServices.get(`pandit/getAllPandit/${skip}/${limit}?name=${name}&address=${address}&contactNumber=${mobileNumber}&status=${updateStatus}`,headers);
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.total_count / limit));
          setAllDataCount(response.data?.total_count)
          setTableData(response.data?.Listdata);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }else{
          if(response?.response?.data?.status == 4){
            logout()
           }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, name,  address , mobileNumber, status]
  );

  const handleStatusUpdate = async item => {
    const formData = new FormData();
    var datas = item?.status === 1 ? 2 : 1;
    let id = item?._id;
    formData.append('status', datas);

   const res = await APIServices.put(`pandit/updateStatus/${id}`, formData, headers)
   if(res?.data?.status === true){
     pujaPanditList(skip, limit, pageIndex);
   }
   else{
     console.log("error..")
   }
  };

  const handleFilterClick = () => {
    if (name || address || mobileNumber || status) {
      pujaPanditList(skip, limit, 0, name,  address , mobileNumber, status);
    }
  };

  const handleDownload = data => {
    for (let item of data) {
      if (item.name) {
        item['Name'] = item.name;
      } else {
        item['Name'] = '_';
      }
      if (item.maskedContactNumber) {
        item['Contact Number'] = item.maskedContactNumber;
      } else {
        item['Contact Number'] = '_';
      }
      if (item.address) {
        item['Address'] = item.address;
      } else {
        item['Address'] = '_';
      }
      if (item.paymentMode) {
        item['Payment Type'] = item.paymentMode;
      } else {
        item['Payment Type'] = '_';
      }
      if (item.upiNumber) {
        item['UPI Number'] = item.upiNumber;
      } else {
        item['UPI Number'] = '_';
      }
      if (item.upiHolderName) {
        item['Holder Name'] = item.upiHolderName;
      } else {
        item['Holder Name'] = '_';
      }
      if (item.accNumber) {
        item['Account Number'] = item.accNumber;
      } else {
        item['Account Number'] = '_';
      }
      if (item.ifscCode) {
        item['IFSC Code'] = item.ifscCode;
      } else {
        item['IFSC Code'] = '_';
      }
      if (item.accHolderName) {
        item['Account Holder Name'] = item.accHolderName;
      } else {
        item['Account Holder Name'] = '_';
      }
      if (item.bankName) {
        item['Bank Name'] = item.bankName;
      } else {
        item['Bank Name'] = '_';
      }

      delete item._id;
      delete item.accHolderName;
      delete item.accNumber;
      delete item.address;
      delete item.bankName;
      delete item.contactNumber;
      delete item.createdAt;
      delete item.ifscCode;
      delete item.isDeleted;
      delete item.maskedContactNumber;
      delete item.name;
      delete item.paymentMode;
      delete item.status;
      delete item.updatedAt;
      delete item.upiHolderName;
      delete item.upiNumber;
      delete item.__v;
    }
    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'UserList.csv');
    else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.download = 'PujaPnadit.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const DownloadFile = async () => {
    let updateStatus = status === "Select Status" ? "" : status
        const res = await APIServices.get(`pandit/getAllPandit/${skip}/${limit}?name=${name}&address=${address}&contactNumber=${mobileNumber}&status=${updateStatus}`,headers);
        
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        handleDownload(resData?.Listdata);
      }
    }
  };

  return (
    <>
    {
      permissioncontext.view?
      <div>
      <div className='main aarti_section'>
        <>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>Pandit <small>({allDataCount})</small></h4>
              <div className='_btn'>
                <Button
                  style={{ backgroundColor: 'green' }}
                  className='by-btn as_btn me-0'
                  variant='primary'
                  disabled={!permissioncontext.add}
                  onClick={() => {
                    navigate('/addEditPandit');
                  }}
                >
                  <FaPlus style={{ marginRight: '5px' }} size={13} />
                  Pandit
                </Button>
              </div>
            </div>
          </div>
        </>
          <div className='user-list-table'>
            <div className='feed_area box-shadow br-6 p-20 my-3 font_16'>
              <div className='chat_search_box user-search'>
                <div className='chat-search-field'>
                    <Form>
                        <Form.Group as={Row} className='' controlId=''>
                          <Col md={3} className='mb-3'>
                            <Form.Label className='label-f-w-cd'>Pandit Name</Form.Label>
                            <Form.Control
                              className=' mt-2 form-ch'
                              name='name'
                              type='search'
                              placeholder='Search By Pandit Name'
                              value={name}
                              onChange={e => {
                                setName(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>
                          <Col md={3} className='mb-3'>
                            <Form.Label className='label-f-w-cd'>Address</Form.Label>

                            <Form.Control
                              className=' mt-2 form-ch'
                              name='address'
                              type='search'
                              placeholder='Search By Address'
                              value={address}
                              onChange={e => {
                                setAddress(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>
                          <Col md={3} className='mb-3'>
                            <Form.Label className='label-f-w-cd'>Mobile Number</Form.Label>

                            <Form.Control
                              className='mt-2 form-ch'
                              name='number'
                              type='search'
                              placeholder='Search By Mobile No.'
                              value={mobileNumber}
                              onChange={e => {
                                setMobileNumber(e.target.value);
                                setSkip(0);
                              }}
                            />
                          </Col>
                          <Col md={3} className='mb-3'>
                          <Form.Label className='label-f-w-cd'>Status</Form.Label>
                            <Form.Select
                              aria-label='categories'
                              className='astroWatch-select-hover hd-cr form-ch mt-2'
                              value={status}
                              onChange={e => {
                                setStatus(e.target.value);
                                setSkip(0);
                              }}
                            >
                              <option>Select Status</option>
                              <option value='1'>Active</option>
                              <option value='2'>Inactive</option>
                            </Form.Select>
                        </Col>
                        <Col md={4}>
                            <Button
                              variant='secondary'
                              className='as_btn ms-0'
                              onClick={() => {
                                handleFilterClick();
                                setSkip(0);
                              }}
                            >
                              Filter
                            </Button>
                            <Button
                              variant='secondary'
                              className='as_btn reset-btn'
                              onClick={() => {
                                handleReset();
                              }}
                            >
                              Reset
                            </Button>
                            {adminUser?.adminType === "super_admin" ? 
                            (<Button
                              className='as_btn ms-2 download-btn'
                              variant='primary'
                              onClick={() => {
                                DownloadFile();
                              }}
                             >
                              Download File
                         </Button>) : "" }
                          </Col>
                          
                        </Form.Group>

                    </Form>
                </div>
              </div>
            </div>
          </div>
              {tableData && tableData.length > 0 ? (
              <DynamicDataTableWithCount
                columns={columns}
                data={tableData}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: limit,
                }}
                fetchData={pujaPanditList}
                forcePage={skip / limit}
              />
            ) : (
              <h2 className='no_records'>Records not Available</h2>
            )}

      <Modal
          centered
          show={showdt}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={handleCloseDt}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
              Cancel
            </Button>
            <Button variant='primary' className='success_btn' onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        {/* view details */}
        <Modal
        centered
        scrollable
        show={singleDataModal}
        size='lg'
        // size={`${pujaData?.pujaExpencese === null} ? 'xl : 'lg`}
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={() => {
          setSingleDataModal(false)
        }}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title>Pandit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Pandit Name:</div>
            <div className="col-6 text-capitalize">{singleData?.name}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Contact Number:</div>
            <div className="col-6">{singleData?.maskedContactNumber}</div>
          </div>
          <div className="row mb-2">
            <div className="col-6 fw-semibold">Address:</div>
            <div className="col-6 text-capitalize">{singleData?.address}</div>
          </div>
          {singleData?.paymentMode === "upi" ? (
            <>
                <div className="row mb-2">
                  <div className="col-6 fw-semibold">Payment Mode:</div>
                  <div className="col-6">UPI</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6 fw-semibold">UPI Number:</div>
                  <div className="col-6">{singleData?.upiNumber}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-6 fw-semibold">Account holder name:</div>
                  <div className="col-6 text-capitalize">{singleData?.upiHolderName}</div>
                </div>
            </>
          ) : ""}
           {singleData?.paymentMode === "bank_details" ? (
            <>
              <div className="row mb-2">
                  <div className="col-6 fw-semibold">Payment Mode:</div>
                  <div className="col-6">Bank Details</div>
                </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">A/C number:</div>
                <div className="col-6">{singleData?.accNumber}</div>
              </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">IFSC code:</div>
                <div className="col-6">{singleData?.ifscCode}</div>
              </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">Account holder name:</div>
                <div className="col-6 text-capitalize">{singleData?.accHolderName}</div>
              </div>
              <div className="row mb-2">
                <div className="col-6 fw-semibold">Bank name:</div>
                <div className="col-6">{singleData?.bankName}</div>
              </div>
            </>
           ):""}
         
        </Modal.Body>
        <Modal.Footer>
         
          <Button
            variant='secondary'
            className='cancle_btn'
            onClick={() => {
              setSingleDataModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        {/* <ToastContainer /> */}
      </div>
    </div>:
     <Notauthorized />
    }
   
    </>
  );
};

export default PujaPandit;
