import { useEffect, useState, useContext } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContextProvider';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import bimg from '../../src/imgs/banner_background.png';
import APIServicenew from '../utils/APIGeneralService';
import { getBaseURL, setCookies } from '../utils/Helper';
import Notauthorized from '../components/Notauthorized';

const PujaPlanSelectionMaster = () => {
  document.title = 'Puja Plan Selection Master';
  const location = useLocation();
  const  permissioncontext  = location.state?.keyFilter || {};
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get('astroUserId');
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [loading, setLoading] = useState();
  const [pujaPackage, setPujaPackage] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [titleHi, setTitleHi] = useState('');
  const [subtitleHi, setSubtitleHi] = useState('');
  const [planImage, setPlanImage] = useState('');
  const [price, setPrice] = useState('');
  const [members, setMembers] = useState('');
  const [globalPrice, setGlobalPrice] = useState('');
  const [isGlobal, setIsGlobal] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataCount, setAllDataCount] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [planImgSelect, setPlanImgSelect] = useState();
  const [sortOrder ,setSortOrder] = useState();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  useEffect(() => {
    fetchData();
    fetchPujaSelectionImgData();
  }, [offset]);

  async function fetchPujaSelectionImgData() {
    let response = await APIServices.get(`pujaPlanSelectionImg/getAll`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPlanImgSelect(apiData?.Listdata);
      }
    } else {
      if (data.status == 4) {
        logout();
      }
    }
  }

  async function fetchData() {
    setLoading(true);
    let response = await APIServices.get(`pujaPlanSelection/getAll/${offset}/${perPage}`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata);
        setAllDataCount(apiData.totalCount);
        setLoading(false);
      }
    } else {
      if (data.status == 4) {
        logout();
      }
    }
  }
  const handleShow = async item => {
    setShowModal(true);
    setSelectedId(item?._id);
    setPujaPackage(item?.pujaPackage);
    setTitle(item?.title);
    setSubtitle(item?.subtitle);
    setTitleHi(item?.titleHi);
    setSubtitleHi(item?.subtitleHi);
    setPlanImage(item?.planImage);
    setPrice(item?.price);
    setMembers(item?.members);
    setGlobalPrice(item?.globalPrice);
    setIsGlobal(item?.isGlobal);
    setPaymentLink(item?.paymentLink ?? "");
    setSortOrder(item?.sortOrder);
  };
  const handleClose = () => {
    setErrors('');
    setSelectedId('');
    setShowModal(false);
    setPujaPackage('');
    setTitle('');
    setSubtitle('');
    setTitleHi('');
    setSubtitleHi('');
    setPlanImage('');
    setPrice('');
    setMembers('');
    setGlobalPrice('');
    setIsGlobal('');
    setPaymentLink('');
    setSortOrder('');
  };

  const clearDta = () =>{
    setShowModal(false);
    setLoader(false);
    setPujaPackage('');
    setTitle('');
    setSubtitle('');
    setTitleHi('');
    setSubtitleHi('');
    setPlanImage('');
    setPrice('');
    setMembers('');
    setGlobalPrice('');
    setIsGlobal('');
    setPaymentLink('');
    setSortOrder('');
  }
  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!pujaPackage || pujaPackage === '') newErrors.pujaPackage = 'Please enter plan package.';
    if (!title || title === '') newErrors.title = 'Please enter title.';
    if (!titleHi || titleHi === '') newErrors.titleHi = 'Please enter title (hindi).';
    if (!subtitle || subtitle === '') newErrors.subtitle = 'Please enter sub title.';
    if (!subtitleHi || subtitleHi === '') newErrors.subtitleHi = 'Please enter sub title (hindi).';
    if (!planImage || planImage === '') newErrors.planImage = 'Please select plan image.';
    if (!price || price === '') newErrors.price = 'Please enter price.';
    if (!members || members === '') newErrors.members = 'Please enter members.';
    if (!globalPrice || globalPrice === '') newErrors.globalPrice = 'Please enter global price.';
    if (!isGlobal || isGlobal === '') newErrors.isGlobal = 'Please select show on global.';
    // if (!paymentLink || paymentLink === '') newErrors.paymentLink = 'Please enter payment link.';

    return newErrors;
  };
  const refetchPujaPackageDetails = async (id) => {
    if(id){

    let [pujaPackageDetailsRes] = await Promise.all([APIServices.get(`pujaPlanSelection/getDetailById/${id}`, headers)]);
    if (pujaPackageDetailsRes?.status === 200) {
      let apiData = pujaPackageDetailsRes?.data;
      if (apiData?.status) {
        handleShow(apiData?.data)
      }
    }
  }
  };

  async function handleCreate() {
    let payload = new FormData();
    payload.append('pujaPackage', pujaPackage);
    payload.append('title', title);
    payload.append('titleHi', titleHi);
    payload.append('subtitle', subtitle);
    payload.append('subtitleHi', subtitleHi);
    payload.append('planImage', planImage);
    payload.append('price', price);
    payload.append('members', members);
    payload.append('globalPrice', globalPrice);
    payload.append('isGlobal', isGlobal);
    payload.append('paymentLink', paymentLink);
    payload.append('user_id', astroUserId);
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let response = await APIServices.post(
        `pujaPlanSelection/add`,
        payload,
        { ...headers,  },
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          clearDta()
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false);
        }
      }
      setLoader(false);
    }
  }

  async function handleEdit() {
    let payload = new FormData();
    payload.append('pujaPackage', pujaPackage);
    payload.append('title', title);
    payload.append('titleHi', titleHi);
    payload.append('subtitle', subtitle);
    payload.append('subtitleHi', subtitleHi);
    payload.append('planImage', planImage);
    payload.append('price', price);
    payload.append('members', members);
    payload.append('globalPrice', globalPrice);
    payload.append('isGlobal', isGlobal);
    payload.append('paymentLink', paymentLink);
    payload.append('user_id', astroUserId);
    payload.append('sortOrder', sortOrder);

    const headers = { Authorization: `Bearer ${token}` };
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let response = await APIServices.put(`pujaPlanSelection/editOne/${selectedId}`, payload, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          clearDta()
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false);
        }
      }
      setLoader(false);
    }
  }

  const handleCloseDt = () => setShowDt(false);
  const handleShowDt = id => {
    setShowDt(true);
    setSelectedId(id);
  };
  const handledelete = async () => {
    let res = await APIServices.delete(`pujaPlanSelection/findByIdAndremove/${selectedId}`, headers);
    if (res?.status === 200) {
      setShowDt(false);
      setSelectedId();
      setOffset(0);
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  };

  function handleChange(e, field) {
    let key, value;
    key = e?.target?.name;

    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  return (
    <>{permissioncontext?.view ? 
    <>
      <div className='main aarti_section'>
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              <FaArrowLeft className='hd-cr ' style={{ marginRight: '15px' }} size={25} onClick={() => navigate(-1)} />
              Puja Plan Selector <small>({allDataCount})</small>
            </h4>

            <div className='_btn'>
              <Button
                className='by-btn as_btn me-0'
                variant='primary'
                disabled={!permissioncontext?.add}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Plan
              </Button>
              &nbsp;
              <Button
                className='by-btn as_btn'
                variant='primary'
                disabled={!permissioncontext.add}
                onClick={e => {
                  navigate(`/pujaPlanSelectionImgMaster`, {
                    state: { permissioncontext },
                  });
                }}
              >
                Plan Image Selector
              </Button>
            </div>
          </div>
        </div>
        <div className='aarti_inner pt-4'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <hr style={{ color: 'black' }} />
                    <div className='row'>
                      {allData?.map(item => (
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                          <div className='card mb-3 Reels_video' key={item?._id}>
                            <div className='card-header'>
                              <img src={item?.planImage ? item?.planImage : bimg} className='card-img-top' alt='img' />
                              <div className='icon'>
                                <FaEdit
                                  className={permissioncontext.edit ? 'me-2 hd-cr' : 'me-2 hd-cr disabled-btn'}
                                  size={20}
                                  onClick={e => {
                                    if (permissioncontext.edit) {
                                      refetchPujaPackageDetails(item?._id);
                                    }
                                  }}
                                />
                                {/* <FaTrash
                                  className={permissioncontext.delete ? 'dt-btn hd-cr ' : 'dt-btn hd-cr disabled-btn'}
                                  size={20}
                                  onClick={e => {
                                    if (permissioncontext.delete) {
                                      handleShowDt(item?._id);
                                    }
                                  }}
                                /> */}
                                <span class="badge badge-info sortOrderSpan">{item?.sortOrder}</span>
                              </div>
                            </div>
                            <div className='card-body '>
                              <h6 className='card-title'>{item?.pujaPackage}</h6>
                              <p className='font_14 card-title subtitle-b'>   INR Price  ₹ {item?.price}</p>
                              <p className='font_14 card-title subtitle-b'>Global Price  $ {item?.globalPrice}</p>
                              <p className='font_14 card-title subtitle-b'>Members {item?.members}</p>
                              <p
                                style={{ textAlign: 'center', fontSize: '15px' }}
                                className='description font-500 mb-2'
                              >
                                <span className='calicon'>
                                  <i className='ri-calendar-line'></i>
                                </span>
                                {new Date(item.updatedAt).toDateString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <div className='pagination'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next>'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(allDataCount / perPage)}
                    previousLabel='< Previous'
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={offset / perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        scrollable
        show={showModal}
        backdrop='static'
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title> {`${selectedId ? 'Edit' : 'Add'} Plan`} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {selectedId ? (
              <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
                <Form.Label className='required' column sm='2'>
                  Plan Package
                </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    type='text'
                    placeholder='Plan Package...'
                    name='pujaPackage'
                    defaultValue={pujaPackage}
                    isInvalid={!!errors?.pujaPackage}
                    className='form-control '
                    // readOnly
                    onChange={e => {
                      setPujaPackage(e.target.value);
                      handleChange(e);
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.pujaPackage}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            ) : (
              <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
                <Form.Label className='required' column sm='2'>
                  Plan Package
                </Form.Label>
                <Col sm='10'>
                  <Form.Control
                    type='text'
                    placeholder='Plan Package...'
                    name='pujaPackage'
                    defaultValue={pujaPackage}
                    isInvalid={!!errors?.pujaPackage}
                    className='form-control '
                    onChange={e => {
                      setPujaPackage(e.target.value);
                      handleChange(e);
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.pujaPackage}</Form.Control.Feedback>
                </Col>
              </Form.Group>
            )}

            <div className=' my-3'>
              <Row className='mb-3'>
                <Col sm={6}>
                  <Form.Group controlId='' as={Row}>
                    <Form.Label className='required' column sm='4'>
                      Title
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        value={title}
                        name='title'
                        isInvalid={!!errors?.title}
                        onChange={e => {setTitle(e.target.value);handleChange(e)}}
                        className='form-control'
                        placeholder='Title...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group controlId='' as={Row}>
                    <Form.Label className='required' column sm='4'>
                      Sub Title
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        name='subtitle'
                        isInvalid={!!errors?.subtitle}
                        onChange={e => {setSubtitle(e.target.value);handleChange(e)}}
                        value={subtitle}
                        className='form-control'
                        placeholder='Sub Title...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.subtitle}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm={6}>
                  <Form.Group controlId='' as={Row}>
                    <Form.Label className='required' column sm='4'>
                      Title (Hindi)
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        value={titleHi}
                        name='titleHi'
                        isInvalid={!!errors?.titleHi}
                        onChange={e => {setTitleHi(e.target.value);handleChange(e)}}
                        className='form-control'
                        placeholder='Title (Hindi)...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.titleHi}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group controlId='' as={Row}>
                    <Form.Label className='required' column sm='4'>
                      Sub Title (Hindi)
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        name='subtitleHi'
                        isInvalid={!!errors?.subtitleHi}
                        onChange={e => {setSubtitleHi(e.target.value);handleChange(e)}}
                        value={subtitleHi}
                        className='form-control'
                        placeholder='Sub Title (Hindi)...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.subtitleHi}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm={6}>
                  <Form.Group as={Row} className='mb-3' controlId=''>
                    <Form.Label className='required' column sm='4'>
                      Plan Image Select
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='text-capitalize st-in-bdr-clr'
                        name='planImage'
                        isInvalid={!!errors?.planImage}
                        onChange={e => {setPlanImage(e.target.value);handleChange(e)}}
                        value={planImage}
                      >
                        <option className='' value='Select'>
                          Select
                        </option>
                        {planImgSelect &&
                          planImgSelect.map((item, index) => {
                            return (
                              <>
                                <option className='text-capitalize' value={item._id}>
                                  {item.title}
                                </option>
                              </>
                            );
                          })}
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors?.planImage}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group as={Row} className='mb-3' controlId=''>
                    <Form.Label className='required' column sm='4'>
                      INR Price
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        isInvalid={!!errors?.price}
                        onChange={e => {
                          const value = e.target.value;
                          // Allow only numbers and decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            setPrice(value);
                            handleChange(e);
                          }
                        }}
                        onKeyPress={e => {
                          // Allow only numeric characters, backspace, and the decimal point
                          if (!/^\d$/.test(e.key) && e.key !== '.' && e.key !== 'Backspace') {
                            e.preventDefault();
                          }
                        }}
                        value={price}
                        name='price'
                        className='form-control'
                        placeholder='Price...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.price}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm={6}>
                  <Form.Group as={Row} className='mb-3' controlId=''>
                    <Form.Label className='required' column sm='4'>
                      Members
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        isInvalid={!!errors?.members}
                        onChange={e => {
                          const value = e.target.value;
                          // Allow only numbers and decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            setMembers(value);
                            handleChange(e);
                          }
                        }}
                        onKeyPress={e => {
                          // Allow only numeric characters
                          if (!/^\d$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={members}
                        name='members'
                        className='form-control'
                        placeholder='Members...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.members}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  {/* { isGlobalPrice === true ?  */}
                  <Form.Group as={Row} className='mb-3' controlId=''>
                    <Form.Label className='required' column sm='4'>
                      Global Price
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='text'
                        isInvalid={!!errors?.globalPrice}
                        onChange={e => {
                          const value = e.target.value;
                          // Allow only numbers and decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            setGlobalPrice(value);
                            handleChange(e);
                          }
                        }}
                        onKeyPress={e => {
                          // Allow only numeric characters, backspace, and the decimal point
                          if (!/^\d$/.test(e.key) && e.key !== '.' && e.key !== 'Backspace') {
                            e.preventDefault();
                          }
                        }}
                        value={globalPrice}
                        name='globalPrice'
                        className='form-control'
                        placeholder='Global Price...'
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.globalPrice}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  {/* :""} */}
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col sm={6}>
                  <Form.Group as={Row} className='mb-3' controlId=''>
                    <Form.Label className='' column sm='4'>
                      Payment Link
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Control
                        type='url'
                        // isInvalid={!!errors?.paymentLink}
                        onChange={e => {setPaymentLink(e.target.value);handleChange(e)}}
                        value={paymentLink}
                        name='paymentLink'
                        className='form-control'
                        placeholder='Payment Link...'
                      />
                      {/* <Form.Control.Feedback type="invalid">
                        {errors?.paymentLink}
                      </Form.Control.Feedback> */}
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group as={Row} className='mb-3' controlId=''>
                    <Form.Label className='required' column sm='4'>
                      Show on Global
                    </Form.Label>
                    <Col sm='8'>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='text-capitalize st-in-bdr-clr'
                        name='isGlobal'
                        isInvalid={!!errors?.isGlobal}
                        onChange={e => {setIsGlobal(e.target.value);handleChange(e)}}
                        value={isGlobal}
                      >
                        <option className='' value='Select'>
                          Select
                        </option>
                        <option className='text-capitalize' value='yes'>
                          Yes
                        </option>
                        <option className='text-capitalize' value='no'>
                          No
                        </option>
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors?.isGlobal}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {selectedId?<>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='' column sm='2'>
                Sort Order
              </Form.Label>
              <Col sm='10'>
                <Form.Control
                  type='number'
                  placeholder=''
                  name='sortOrder'
                  defaultValue={sortOrder}
                  className='form-control '
                  onChange={e => {
                    setSortOrder(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            </>:""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {loader ? (
            <Button variant='primary' className='as_btn'>
             Processing...
            </Button>
          ) : (
            <Button
              variant='primary'
              className='as_btn'
              onClick={e => `${selectedId ? handleEdit() : handleCreate(e)}`}
            >
              {`${selectedId ? 'Edit' : 'Add'}`}
            </Button>
          )}
          <Button
            variant='secondary'
            className='as_btn reset-btn'
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
    : <Notauthorized/>}</>
  );
};

export default PujaPlanSelectionMaster;
