import React, { createRef, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button, InputGroup, FormControl, Spinner, Table , Form  } from "react-bootstrap";
import Select from 'react-select';
import { Pencil, XOctagon ,XLg} from "react-bootstrap-icons";
import { Fa } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { useToasts } from 'react-toast-notifications';
import APIServicenew from "../utils/APIGeneralService";
import { getCookies } from "../utils/Helper";
import moment from 'moment';
import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

export const AddResponders = () => {
  document.title = 'Responder';
  const permissioncontext =usePermissionData("responders")

  const defaultState={
    name : '',
    email : '',
    contactNumber: '',
    password :'',
    campaigns : [],
    call:'no'
  };
  const { addToast } = useToasts();
  const [newData ,setNewData] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [responder, setResponders] = useState([]);
  const [state, setState] = useState(defaultState);
  const [validated, setValidated] = useState(false);
  const[loader,setLoader]=useState(false)
  const [errors, setErrors] = useState({});
  const[forid,setForid]=useState(false)
  const [allDataCount , setAllDataCount] = useState()
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL)
  const token = getCookies("accessToken")
  const headers = {
    "Authorization":`Bearer ${token}`
  }
  useEffect(() => {
    // get all the campaigns
    getCampaigns()
    getResponders()
    setLoader(false)
  }, []);

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
    
  
      if ( state?.name =="") 
      newErrors.name = "Please enter name.";

      if ( state.email === '' )
      newErrors.email = "Please enter email.";

      if(forid===false){
        if(isValidEmail(state.email) === false)
        newErrors.email = "Please enter valid email."
      }
      if (state.contactNumber == "")
      newErrors.contactNumber = "Please enter contact number .";

      else if (state.contactNumber.length > 10 || state.contactNumber.length < 10)
      newErrors.contactNumber = "Contact number cannot be less then 10 digits and more then 10 digits!";

      if(state.password === '' )
        newErrors.password = "Please enter password.";

      if(forid===false){
        if(isValidPassword(state.password) === false)
          newErrors.password = "Password must contain at least 8 characters, including one lowercase letter, including one uppercase letter and one digit.";
        }

        if ( state?.call ===""||state?.call ===undefined) 
      newErrors.call = "Please Select Yes/No.";
      

      return newErrors;
    }; 
 

  function isValidPassword(string) {
            var res = 
        string.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
        return (res !== null);
        };
        function isValidEmail(string) {
          var res = 
        string.match(/^[a-zA-Z0-9\s._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\s]{2,4}$/);
        return (res !== null);
  };
        



  const addResponder = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    const token = getCookies("accessToken")
    const headers = {
      "Authorization":`Bearer ${token}`
    }
    let campaignIds = [] 
    state.campaigns.filter(x => campaignIds.push(x.id))
    let payload =  {} ;
    payload.name = state.name;
    payload.email = state.email;
    payload.contactNumber = state.contactNumber;
    payload.password = state.password;
    payload.campaigns = campaignIds; 
    payload.call=state.call;
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
       setLoader(false)
      }else{
    if( newData?._id){
      setLoader(true)
    const res = await APIServices.put(`responders/update/${newData._id}`,  payload, headers );
    if (res && res.status === 200) {
      await getResponders()
      let apiData = res?.data;
      if (apiData?.status===true) {
        toast.success(apiData?.message);
        setLoader(false)
        setForid(false)
        setErrors({});
     
      }else{
        toast.error(apiData?.message);
        setLoader(false)
      }
      setNewData("")
      setState(defaultState)
  }setLoader(false)
    }else{
      setLoader(true)
    const res = await APIServices.post("responders/signUp",  payload, headers );
    if (res && res.status === 200) {
        await getResponders()
        let apiData = res?.data;
        if (apiData?.status===true) {
          toast.success(apiData?.message);
          setLoader(false)
          setState(defaultState)
          setErrors({});
        }else{
          toast.error(apiData?.message);
          setLoader(false)
        }
        setNewData("")
        
    }
    setLoader(false)
  }
}
  };

  const getResponders = async() => {
    const res = await APIServices.get("responders/list"  ,headers);
    if (res && res.status === 200) {
      let resData = res.data;
  
      if (resData && resData.status && resData.data && resData.data.length) {
          // const campTypes = res?.data?.data.map(ct => { return { value: ct.name, label: ct.campaignValue, id: ct._id } });
        // setCampaigns(campTypes)
        setResponders(resData.data)
        setAllDataCount(resData.total_count)
      }
    }
  }

  const getCampaigns = async() => {
    const res = await APIServices.get("campaign/getCampaign");
      if (res && res.status === 200) {
        let resData = res.data;
        if (resData && resData.status && resData.data && resData.data.length) {
            const campTypes = res?.data?.data.map(ct => { return { value: ct.name, label: ct.campaignValue, id: ct._id } });
          setCampaigns(campTypes)
        }
      }
  }

  const handleActiveClick = async (status, id) => {
    var datas = status === 1 ? 2 : 1;
    const formData = new FormData();
    formData.append('status', datas);
    await APIServices.put(`responders/updateStatus/${id}`, formData, headers)
      .then(res => {
       getResponders()
      })
      .catch(err => {
        console.log(err);
      });
  };


  const handleCallChange=(e)=>{
    setState({...state, call: e.target.value})
  }

  

  const handleInputChange = (e, label) => {
    if(label === 'camptype') setState((prevState) => ({...prevState, campaigns: e}))
  }
  function handleChange(e) { 
    let key, value;

    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  
  const renderName = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label class="required">Name</Form.Label>
          <Form.Control
            required
            name="name"
            type="text"
            isInvalid={!!errors?.name}
            value={state.name}
            placeholder="Name"
            onChange={(e) => {setState({...state, name: e.target.value}); handleChange(e)}}
          />
        <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }

  const renderMobile = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label class="required">Contact Number</Form.Label>
          <Form.Control
            required
            name="contactNumber"
            type="text"
            placeholder="Mobile"
            isInvalid={!!errors?.contactNumber}
            value={state.contactNumber}
            onChange={(e) => {setState({...state, contactNumber: e.target.value}); handleChange(e)}}
            onKeyPress={(evnt) => {
              // Allow only numeric characters
              if (!/^\d$/.test(evnt.key)) {
                evnt.preventDefault();
              }
            }}
          />
        <Form.Control.Feedback type='invalid'>{errors?.contactNumber}</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }

  const renderEmail = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label class="required">Email</Form.Label>
          <Form.Control
            required
            name="email"
            type="email"
            isInvalid={!!errors?.email}
            value={state.email}
            placeholder="Email"
            onChange={(e) => {setState({...state, email: e.target.value}); handleChange(e)}}
          />
        <Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }


  const renderPassword = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label class="required">Password</Form.Label>
          <Form.Control
            required
            label="Campaign"
            name="password"
            type="password"
            isInvalid={!!errors?.password}
            value={state.password}
            placeholder="Password"
            onChange={(e) => {setState({...state, password: e.target.value}); handleChange(e)}}
          />
        <Form.Control.Feedback type='invalid'>{errors?.password}</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }

  const renderCampaigns = () => {
    return (
      <>
      <Form.Group as={Col} size="md">
        <Form.Label>Campaign</Form.Label>
        < Select
          classNamePrefix="react-select"
          className="hd-cr"
          isMulti
          closeMenuOnSelect={true}
          placeholder="Campaign"
          value={state.campaigns}
          onChange={(e) => handleInputChange(e, 'camptype')}
          name="Campaign"
          options={campaigns}
        />
        </Form.Group>
      </>
    )
  }



  const renderCall = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label class="required">Allow Call</Form.Label>
          <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  isInvalid={!!errors?.call}
                  name ="call"
                  value={state?.call}
                  onChange={(e) => handleCallChange(e)}
                >
                  <option value="">Choose</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors?.call}
                  </Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }

  // const renderCampaignNames = (campaigns) => {
  //   let camps = []; 
  //   if(campaigns && campaigns.length > 0) campaigns.filter(x => camps.push(x.campaignValue));
  //   console.log("🚀 ~ renderCampaignNames ~ camps:", camps.toString())
  //   return camps.toString()
  // }

  const renderCampaignNames = (campaigns) => {
    if (!campaigns || campaigns.length === 0) return "";
  
    return campaigns.map((x, index) => `${index + 1}. ${x.campaignValue}`).join('\n');
  }

  return (
    <> 
    {
      permissioncontext.view?
      <div className="notifiction_area" style={{
        backgroundImage: 'url("/images/why_choose_us.jpg")'
          }}>
           <div className="gita_quote_heading">
             <div className="container d-flex align-items-center justify-content-between">
             <h4 className="hd-list-f left-q" >
  
          <Row>
          {newData?._id?<Col>
              <h5>Edit Responder
                {/* <span style={{marginLeft:"6px"}}>
              <XLg className="me-2"
              size={20}
              onClick={(e) => {
                setNewData("")
          setState(defaultState)
              }}
              />
  
              </span> */}
              </h5>
              
            </Col> :
  
            <Col>
              <h5>Responders <small>({allDataCount})</small></h5>
            </Col>}
          </Row>
              </h4>
             </div>
          </div>
          <div className="container-fluid my-3">
              <div className="notifiction_form bg-white mt-0 box-shadow br-6 p-20 ">
        
          <Form noValidate validated={validated} onSubmit={addResponder}>
          <Row className="mx-0">
            <Col className="my-2 col-12 col-md-4">
              {renderName()}
            </Col>
           
            <Col className="my-2 col-12 col-md-4">
              {renderMobile()}
            </Col>
            <Col className="my-2 col-12 col-md-4">
              {renderEmail()}
            </Col>
            {/* <Col className="my-2 col-12 col-md-4">
              {renderCall()}
            </Col> */}
          </Row>
          <Row>
          </Row>
          <Row className="mx-0">
          {newData?._id? "":
            <Col className="my-2">
              {renderPassword()}
            </Col>}
            <Col className="my-2">
              {renderCampaigns()}
            </Col>
          </Row>
          <Row>
          {newData?._id?<Col className=" ">{loader? <Button variant="primary" className="as_btn" >Processing...</Button>: 
          <>
          <Button  disabled={!permissioncontext.edit} variant="primary" className="as_btn"  type="submit">Submit </Button>
          <Button variant="primary" onClick={(e) => {
                setNewData("")
                setState(defaultState)
              }} className="as_btn cancle_btn" type="submit">Cancel </Button>
          
          </>
          }
            
            </Col> :
            <Col className="">{loader?<Button variant="primary" className="as_btn" >Processing...</Button>:<Button disabled={!permissioncontext.add} variant="primary" className="as_btn" type="submit">Submit </Button>}
              
            </Col>}
          </Row>
          </Form>
              </div>
          </div>
          <div className="table-responsive admin_grid_table responders">
                  <Table className="w-1920">
                      <thead>
                          <tr>
                              <th> No. </th>
                              <th> Name </th>
                              <th> Mobile </th>
                              <th> Email </th>
                              <th> Campaigns </th>
                              <th>Status</th>
                              <th>Action</th>
                              <th>created</th>
                              <th>updated</th>
                          </tr>
                      </thead>
                  {responder?.map((item ,i)=>
                      <tbody>
                              <tr key={item?._id}>
                              <td>{i+1}</td>
                              <td>{item?.name}</td>
                              <td>{item?.maskContactNumber}</td>
                              <td  className='text-lowercase'>{item?.maskEmail}</td>
                              {/* <td>{renderCampaignNames(item?.campaigns)}</td> */}
                              <td>{renderCampaignNames(item?.campaigns).split('\n').map((name, index) => (
                               <div key={index}>{name}</div>
                               ))} 
                              </td>
                              {/* <td>{item?.isCall}</td> */}
                              <td>{item?.status === 1 ?
                              <input
                              type='button'
                              className={!permissioncontext.edit?'_active disabled-btn':'_active'}
                              disabled={!permissioncontext.edit}
                              value='Active'
                              onClick={() => handleActiveClick(item.status,item._id )
                              }
                              />:<input
                              type='button'
                              className={!permissioncontext.edit?'_inactive disabled-btn':'_inactive'}
                              disabled={!permissioncontext.edit}
                              value='Inactive'
                              onClick={() => handleActiveClick(item.status,item._id )
                              }
                            />
                            }</td>
                              <td><Pencil
                                    className={permissioncontext.edit?"me-2":"me-2 disabled-btn"}
                                    size={20}
                                    onClick={(e) => {
                                      if(permissioncontext.edit){
                                      setState({name: item.name,
                                        email:item.email,
                                        contactNumber:item.contactNumber,
                                        campaigns:item.campaigns.map(ct => { return { value: ct.name, label: ct.campaignValue, id: ct._id } }),
                                        call:item.isCall
                                      })
                                      setNewData(item)
                                      setForid(true)
                                      setErrors({});}
                                    }}
                              /></td>
  
                             <td>{moment(item?.createdAt).format('DD MMM, YYYY hh:mm A')}</td>
                             <td>{moment(item?.updatedAt).format('DD MMM, YYYY hh:mm A')}</td>
                          </tr>
                          
                      </tbody>
                  )}
                  </Table>
              </div>
        </div>:
           <Notauthorized />
    }
   
      <ToastContainer />

    </>
  );
}



export default AddResponders;