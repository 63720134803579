import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row, Table, Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Pencil, XOctagon, XLg } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import Select from 'react-select';
import moment from 'moment';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

const Campaign = () => {
  document.title = 'Campaign';
  const permissioncontext =usePermissionData("campaign")
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [newData, setNewData] = useState();

  const defaultState = {
    name: '',
    campaignValue: '',
    templates: [],
    call: 'no',
    wcall: '',
  };

  const [state, setState] = useState(defaultState);
  const [templates, setTemplates] = useState([]);
  const [validated, setValidated] = useState(false);
  const [allDataCount, setAllDataCount] = useState();
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [loader, setLoader] = useState(false);

  async function handleCreate(event) {
    event.preventDefault();
    const form = event.currentTarget;
    // const formData = new FormData();
    let templateIds = [];
    state.templates.filter(x => templateIds.push(x.id));
    let formData = {
      name: state?.name,
      campaignValue: state?.campaignValue,
      templateIdArr: templateIds,
      call: state?.call,
      wAppTempEnable:state?.wcall
    };
    // formData.append("name", state?.name);
    // formData.append(
    //   "campaignValue",
    //   state?.campaignValue
    //   );
    //   formData.append(
    //     "templateIdArr",
    //     templateIds
    //     );
    //     formData.append("call", state?.call);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoader(true);
      if (newData?._id) {
        const res = await APIServices.put(`campaign/update/${newData._id}`, formData, headers);
        if (res && res.status === 200) {
          await fetchData();
          let apiData = res?.data;
          if (apiData?.status === true) {
            toast.success(apiData?.message);
            setState(defaultState);
            setNewData([]);
            setLoader(false);
            setValidated(false);
          } else if (apiData?.status === false) {
            toast.error(apiData?.message);
            setLoader(false);
          }
        }
      } else {
        let response = await APIServices.post(`campaign/addCampaign`, formData, headers);
        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            toast.success(apiData?.message);
            setState(defaultState);
            setNewData([]);
            setLoader(false);
            fetchData();
            setValidated(false);
          }
        }
      }
      setLoader(false);
    }
  }

  const fetchData = async () => {
    setLoading(true);
    let response = await APIServices.get(`campaign/getCampaign`, headers);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.data);
        setAllDataCount(apiData?.data.length);
        setLoading(false);
      }
    }
  };

  const fetchedAllTemplate = async () => {
    const res = await APIServices.get(`/whatsAppApi/wAppTemplateList`, headers);
    if (res && res.status === 200) {
      let resData = res?.data;
      if (resData && resData.status && resData.data && resData.data.length) {
        const templateTypes = res?.data?.data.map(ct => {
          if (ct.title !== "") {
          return { value: ct._id, label: ct.title, id: ct._id };
            
          }
        }).filter(Boolean);
        setTemplates(templateTypes);
      }
    }
  };

  const handleInputChange = (e, label) => {
    if (label === 'templateTypes') setState(prevState => ({ ...prevState, templates: e }));
  };

  useEffect(() => {
    fetchData();
    fetchedAllTemplate();
    setLoader(false);
  }, []);

  const rendertemplateNames = templates => {
    let camps = [];
    if (templates && templates.length > 0) templates.map(x => camps.push(x?.title));
    return camps.toString();
  };

  return (
    <>
    {
      permissioncontext.view?
<div
        className='notifiction_area'
        style={{
          backgroundImage: 'url("/images/why_choose_us.jpg")',
        }}
      >
        <div className='gita_quote_heading mb-3'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              <Row>
                {newData?._id ? (
                  <Col>
                    <h5>
                      Edit Campaign
                      {/* <span style={{ marginLeft: '6px' }}>
                        <XLg
                          className='me-2'
                          size={20}
                          onClick={e => {
                            setNewData([]);
                            setState(defaultState);
                            setValidated(false);
                          }}
                        />
                      </span> */}
                    </h5>
                  </Col>
                ) : (
                  <Col>
                    <h5>
                       Campaigns <small>({allDataCount})</small>
                    </h5>
                  </Col>
                )}
              </Row>
            </h4>
          </div>
        </div>

            <div className='container-fluid'>
              <div className='notifiction_form bg-white mt-0 box-shadow br-6 p-20'>
                <Form noValidate validated={validated} onSubmit={handleCreate}>
                  <Row className='mx-0'>
                    <Col md={6} className='my-2'>
                      <Form.Group controlId='validationCustom01'>
                        <Form.Label className='required'> Name</Form.Label>

                        <Form.Control
                          required
                          type='text'
                          className='form-control '
                          placeholder='Name'
                          onChange={e => {
                            setState({ ...state, name: e.target.value });
                          }}
                          value={state?.name}
                        />
                        <Form.Control.Feedback type='invalid'>Please enter name.</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col  md={6} className='my-2'>
                    {state?.campaignValue=='default'? <Form.Group controlId='validationCustom01'>
                        <Form.Label className='required'> Campaign Value</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          className='form-control '
                          placeholder='Campaign Value'
                          readOnly
                          onChange={e => {
                            setState({ ...state, campaignValue: e.target.value });
                          }}
                          value={state?.campaignValue}
                        />
                        <Form.Control.Feedback type='invalid'>Please enter Campaign Value.</Form.Control.Feedback>
                      </Form.Group>: <Form.Group as={Col} size='md' controlId='validationCustom01'>
                        <Form.Label className='required'> Campaign Value</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          className='form-control '
                          placeholder='Campaign Value'
                          onChange={e => {
                            setState({ ...state, campaignValue: e.target.value });
                          }}
                          value={state?.campaignValue}
                        />
                        <Form.Control.Feedback type='invalid'>Please enter Campaign Value.</Form.Control.Feedback>
                      </Form.Group>}
                    
                    </Col>
                    {/* <Col className='my-2'>
                      <Form.Group as={Col} size='md' controlId='validationCustom01'>
                        <Form.Label className='required'> Allow Call</Form.Label>
                        <Form.Select
                          aria-label='Default select example'
                          required
                          className='hd-cr'
                          onChange={e => {
                            setState({ ...state, call: e.target.value });
                          }}
                          value={state?.call}
                        >
                          <option value=''>Choose</option>
                          <option value='yes'>Yes</option>
                          <option value='no'>No</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          Please Select Yes/No.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col> */}

                    <Col  md={6} className='my-2'>
                    <Form.Group as={Col} size="md">
                      <Form.Label>Whatsapp Template</Form.Label>
                      <Select
                      classNamePrefix="react-select"
                        className='hd-cr'
                        isMulti
                        closeMenuOnSelect={true}
                        placeholder='Templates'
                        value={state.templates}
                        onChange={e => handleInputChange(e, 'templateTypes')}
                        name='Campaign'
                        options={templates}
                      />
                    </Form.Group>
                    </Col>
                    <Col  md={6} className='my-2'>
                      <Form.Group as={Col} size="md">
                        <Form.Label className='required'> Allow WhatsAppTemplete</Form.Label>
                        <Form.Select
                          aria-label='Default select example'
                          required
                          className='hd-cr'
                          onChange={e => {
                            setState({ ...state, wcall: e.target.value });
                          }}
                          value={state?.wcall}
                        >
                          <option value=''>Choose</option>
                          <option value='yes'>Yes</option>
                          <option value='no'>No</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          {/* {errors?.call} */}
                          Please Select Yes/No.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <div className='mt-2' style={{ alignItems: 'center' }}>
                      {newData?._id ? (
                        <>
                          {loader ? (
                            <Button variant='secondary' className='as_btn'>
                              Processing...
                            </Button>
                          ) : (
                            <>
                              <Button variant='secondary' disabled={!permissioncontext.edit} className='as_btn ms-1' type='submit'>
                                Submit
                              </Button>
                              <Button variant='secondary' onClick={e => {
                                      setNewData([]);
                                      setState(defaultState);
                                      setValidated(false);
                                  }} className='as_btn cancle_btn' type='submit'>
                                 Cancel
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {loader ? (
                            <Button variant='secondary' className='as_btn'>
                              Processing...
                            </Button>
                          ) : (
                            <Button disabled={!permissioncontext.add} variant='secondary' className='as_btn ms-1' type='submit'>
                              Submit
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
            <div className='table-responsive admin_grid_table campaign my-3'>
                  <Table className='w-1920'>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th> Name</th>
                        <th> Campaign value</th>
                        <th> WhatsApp template</th>
                        {/* <th>Allow Call</th> */}
                        <th>Allow WhatsApp Templete</th>
                        <th> Action</th>
                        <th> created</th>
                        <th>updated</th>
                      </tr>
                    </thead>
                    {data?.map((item, i) => (
                      <tbody>

                        <tr key={item?._id}>
                          <td>{i + 1}</td>
                          <td>{item?.name}</td>
                          <td>{item?.campaignValue}</td>
                          <td>{rendertemplateNames(item?.templateIdArr)}</td>
                          {/* <td>{item?.isCall}</td> */}
                          <td>{item?.wAppTempEnable}</td>
                          <td>
                            <Pencil
                              className={permissioncontext.edit?'me-2 cursur':'me-2 cursur disabled-btn'}
                              size={20}
                              
                              onClick={e => {
                                if(permissioncontext.edit){
                                setNewData(item);
                                setState({
                                  name: item.name,
                                  campaignValue: item?.campaignValue,
                                  templates: item.templateIdArr?.map(ct => {
                                    return { value: ct._id, label:ct.title, id: ct._id };
                                  }),
                                  call: item?.isCall,
                                  wcall: item?.wAppTempEnable
                                });
                              }
                              }}
                            />
                          </td>

                          <td>{moment(item?.createdAt).format('DD MMM, YYYY hh:mm A')}</td>
                          <td>{moment(item?.updatedAt).format('DD MMM, YYYY hh:mm A')}</td>

                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </div>

    </div>:
    <Notauthorized />
    }
      

      <ToastContainer />
    </>
  );
};

export default Campaign;
