import React, { useEffect, useState } from 'react';
import APIServicenew from '../utils/APIGeneralService';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { getCookies } from '../utils/Helper';
import { Button, Col, Row } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { useToasts } from 'react-toast-notifications';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

export default function Whatsapptemplate() {
  document.title = 'Whatsapp Template';
  const permissioncontext =usePermissionData("whatsapptemplate")
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState('');
  const token = getCookies('accessToken');
  const [alltemplate, setAllTemplate] = useState([]);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
const[phoneno,setPhoneNo]=useState()
const[testid,setTestId]=useState()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const { addToast } = useToasts();

  const columns = [
    {
      dataField: 'index',
      text: 'No',
      classes: 'blocked-column-width',
      formatter: (cell, row, rowIndex) => {
        for (var i = rowIndex; i <= rowIndex + 50; i++) {
          return skip + i + 1;
        }
      },
    },
    {
      dataField: 'title',
      text: 'Title  ',
    },
    {
      dataField: 'Name',
      text: 'Name  ',
      formatter: (cell, row, rowIndex) => {
      return row.Name.join(', ')
      },
    },
    {
      dataField: 'type',
      text: 'Type ',
    },

    {
      dataField: 'createdAt',
      text: 'Date',
    },
    {
      dataField: 'updatedAt',
      text: 'Modified Date',
    },
    {
      // dataField: 'PujaName',
      text: 'Action ',
      formatter: (cell, row) => (
        <Pencil
        className={permissioncontext.edit?"":"disabled-btn"}
          onClick={() => {
            if(permissioncontext.edit){
            navigate('/whatsapptemplatechange', { state: { row } });
          }}}
        />
      ),
    },
    {
      // dataField: 'PujaName',
      text: 'Test Template ',
      formatter: (cell, row) => (
        <Button
        onClick={(e) => {
          handleShow();
          setTestId(row?._id)
        }}
         className='as_btn'
        >Test</Button>
      ),
    },
  ];



  const validateInputs = () => {
    let isValid = false;

    const newErrors = {};
    if (!phoneno || phoneno === '') newErrors.phoneno = 'Please enter phone number.';
    if (phoneno?.length > 10 || phoneno?.length < 10) newErrors.phoneno = 'Please enter valid number.';


    return newErrors;
  };

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;

    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  const fetchedAllTemplate = async () => {
    const res = await APIServices.get(`/whatsAppApi/wAppTemplateList/${skip}/50`, headers);
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData.data];
        setAllTemplate(
          (newData = newData.map(item => {
            return {
              _id: item._id,
              title:item.title,
              Name: item.headlineArr,
              type: item.messageType,
              createdAt: moment(item.createdAt).format('DD MMM, YYYY hh:mm A'),
              updatedAt: moment(item.updatedAt).format('DD MMM, YYYY hh:mm A'),
              template: item?.template,
              messageForResponder: item?.messageForResponder,
            };
          }))
        );
        // item.headlineArr==""?<>NA</>:
        // item.messageType=="default_msg"?<>default message</>:
        setUsers(newData);
        setTotalChats(resData.total_count);
      }
    }
  };

  const handlePageChange = selectedPage => {
    let skipValue = selectedPage.selected * 50;
    setSkip(skipValue);

    fetchedAllTemplate({ skips: skipValue });
  };

  useEffect(() => {
    fetchedAllTemplate();
  }, []);


  const handleAdd = async e => {
   
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let payload = {
        mobileNumber:phoneno
      };
      const response = await APIServices.post(`/whatsAppApi/wAppTemplateTest/${testid}`, payload, headers);

      if (response?.data?.status === true) {
        handleClose();
        setTestId();
        setPhoneNo();
        setLoader(false);
        toast.success(response?.data.message);
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <>
    {
      permissioncontext.view?
      <>
      
      <div className='gita_quote_heading'>
      <div className='container d-flex align-items-center justify-content-between'>
        <h4 className='hd-list-f left-q'>
          Whatsapp Template <small>({totalChats})</small>
        </h4>
      </div>
    </div>
    <div className='chat_window'>
      <Row className='mlist shadow-none'>
        {/* <div class='chat_search_box'></div> */}
          <Row>
              <Col className='mb-2 d-flex flex-row-reverse'>
                  <Button className='as_btn' disabled={!permissioncontext.add} variant='primary'    onClick={() => {
                  navigate('/whatsapptemplatechange');
                  }}>
                        Add Whatsapp Template
                      </Button>
              </Col>
          </Row>
        </Row>

          <div className='overflow-scroll admin_grid_table border-remove whstapp-template'>
            <BootstrapTable keyField='index' data={users} columns={columns} />
          </div>

        {totalChats > 50 && (
          <div className='d-flex justify-content-center align-items-left w-100 p-0 mt-2 custom-pagination'>
            <ReactPaginate
              previousLabel='Previous'
              nextLabel='Next'
              breakLabel='...'
              forcePage={skip / 50}
              pageCount={totalChats < 50 ? 1 : Math.ceil(totalChats / 50)} // Total number of pages
              marginPagesDisplayed={2} // Number of pages to display at the start and end of the pagination
              pageRangeDisplayed={1} // Number of pages to display in the middle of the pagination
              onPageChange={handlePageChange} // Callback function when page is changed
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        )}
   

      <Modal centered show={show}  size='sm' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Test Template</Modal.Title>
      </Modal.Header>
      <Modal.Body  ><div className='container'><h5 className='font_16'>Enter phone number</h5>
      <input type='number'
      className='font_16 h-40 px-2 br-6 w-100 form-control'
                       maxLength={10}
                       minLength={10}
                       name="phoneno"
                      placeholder='Enter phone number'
                      value={phoneno}
                      isInvalid={!!errors.phoneno}
                      onChange={e => {
                        setPhoneNo(e.target.value);
                        handleChange(e);
                      }} 
                      /><div type='invalid' className='template-error' >{errors.phoneno}</div> </div> </Modal.Body>
      <Modal.Footer >
      <Button variant="primary"  className='as_btn px-3' onClick={handleAdd}  >
      {/* onClick={handleAdd} */}
          Yes
        </Button>
        <Button variant="secondary"  className='as_btn cancle_btn' onClick={handleClose}>
          Cancel
        </Button>
        
      </Modal.Footer>
    </Modal>
    </div> </>:
   <Notauthorized />
      

    }
      
    </>
  );
}
