import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Dropdown, Form, ToggleButton, ButtonGroup, Spinner, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import APIServicenew from '../utils/APIGeneralService';
import { formateDate, getCookies, setCookies } from '../utils/Helper';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Papa from 'papaparse';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

export default function Callhistory() {
  document.title = 'Call History';
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState('');
  const token = getCookies('accessToken');
  const [pageIndex, setPageIndex] = useState(0);
  const [campaignsaa, setCampaignsaa] = useState([]);
  const [responderaa, setResponderaa] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [inputState, setInputState] = useState({
    startdate: '',
    enddate: '',
  });

  const [mankibat, setMankibat] = useState([]);
  const selectedCampign = useRef(null);
  const selectedResponder = useRef(null);
  const selectedCallstatus = useRef(null);

  const handleInputChange = e => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    setInputState(prevState => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const handleReset = () => {
    setInputState({
      startdate: '',
      enddate: '',
    });
    selectedCallstatus.current = 'all';
    selectedCampign.current = 'all';
    selectedResponder.current = 'all';
    setSkip(0);
  };

  useEffect(() => {
    let campignId = selectedCampign.current ? (selectedCampign.current === 'all' ? '' : selectedCampign.current) : '';
    let responderId = selectedResponder.current
      ? selectedResponder.current === 'all'
        ? ''
        : selectedResponder.current
      : '';
    let callStatus = selectedCallstatus.current?.status
      ? selectedCallstatus.current?.status === 'all'
        ? ''
        : selectedCallstatus.current?.status
      : '';
    // setSkip(0);
    let startDate = inputState.startdate ? inputState.startdate : '';
    let endDate = inputState.enddate ? inputState.enddate : '';

    fetchedAllUsers({ skips: 0, endDate, startDate, campignId, responderId, callStatus });
  }, [inputState]);

  const handleStartDate = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  };
  const handleEndDate = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  };

  const columns = [
    {
      dataField: 'index',
      text: 'No',
      classes: 'blocked-column-width',
      formatter: (cell, row, rowIndex) => {
        for (var i = rowIndex; i <= rowIndex + 50; i++) {
          return skip + i + 1;
        }
      },
    },
    {
      dataField: 'campaignName',
      text: 'Campaign Name',
    },

    {
      dataField: 'responderName',
      text: 'Responder Name',
      
    },
    {
      dataField: 'responderNumber',
      text: 'Responder Number',
      formatter: (cell, row) => {
        return (
          <>
            <span>
              91{row.responderNumber}
            </span>
          </>
        );
      },
    },
    {
      dataField: 'userNumber',
      text: 'Customer Number',
    },
    {
      dataField: 'callStatus',
      text: 'Call Status',
    },
    {
      dataField: 'callCount',
      text: 'Call attempt',
    },
    {
      dataField: 'duration',
      text: 'Duration',
    },
    {
      dataField: 'createdAt',
      text: 'Date',
    },
    {
      dataField: 'updatedAt',
      text: 'Modified Date',
    },
  ];

  const fetchedAllUsers = async ({ skips = 0, campignId = '', responderId = '', callStatus = '' }) => {
    let startdate = inputState.startdate;
    let enddate = inputState.enddate;
    const res = await APIServices.get(
      `/responders/callList/${skips}/50?campaignId=${campignId}&responderId=${responderId}&callStatus=${callStatus}&startDate=${startdate}&endDate=${enddate}`,
      headers
    );
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData?.data];
        setMankibat(
          (newData = newData.map(item => {
            return {
              _id: item._id,
              campaignName: item.campaignName,
              userNumber: item.userNumber,
              responderName: item.responderName,
              responderNumber: item.responderNumber,
              callCount: item.callCount,
              duration: `${item.duration} min`,
              callStatus:
                item.callStatus == 0
                  ? 'Pending'
                  : item.callStatus == 1
                  ? 'Initiated'
                  : item.callStatus == 2
                  ? 'Completed'
                  : 'Failed',
              createdAt: moment(item.createdAt).format('lll'),
              updatedAt: moment(item.updatedAt).format('lll'),
            };
          }))
        );
        setUsers(newData);
        setTotalChats(resData.total_count);
      }
    }
  };

  const handlePageChange = selectedPage => {
    let skipValue = selectedPage.selected * 50;
    console.log('skipValue', skipValue);
    setSkip(skipValue);
    let campignId = selectedCampign.current ? (selectedCampign.current === 'all' ? '' : selectedCampign.current) : '';
    let responderId = selectedResponder.current
      ? selectedResponder.current === 'all'
        ? ''
        : selectedResponder.current
      : '';
    let callStatus = selectedCallstatus.current?.status
      ? selectedCallstatus.current?.status === 'all'
        ? ''
        : selectedCallstatus.current?.status
      : '';
    let startDate = inputState.startdate ? inputState.startdate : '';
    let endDate = inputState.enddate ? inputState.enddate : '';
    fetchedAllUsers({ skips: skipValue, endDate, startDate, campignId, responderId, callStatus });
  };

  const fetchedCampaigns = async () => {
    const res = await APIServices.get('campaign/getCampaign', headers);
    if (res && res.status === 200) {
      setCampaignsaa(res?.data?.data);
    }
  };
  const fetchedResponder = async () => {
    const res = await APIServices.get('responders/list', headers);
    if (res && res.status === 200) {
      setResponderaa(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchedAllUsers({ skips: 0 });
    fetchedCampaigns();
    fetchedResponder();
  }, []);

  const handleCampignChange = e => {
    selectedCampign.current = e.target.value;
    setCookies('skip', 0);
    // setSkip(0);
    handlingCombineFiltering({
      selectedResponderId: selectedResponder?.current,
      selectedCampignId: e.target.value,
      selectedCallStatus: selectedCallstatus?.current?.status,
      startDate: inputState.startdate ? inputState.startdate : '',
      endDate: inputState.enddate ? inputState.enddate : '',
    });
  };

  const handleResponderChange = e => {
    selectedResponder.current = e.target.value;
    setCookies('skip', 0);
    // setSkip(0);
    handlingCombineFiltering({
      selectedResponderId: e.target.value,
      selectedCampignId: selectedCampign?.current,
      selectedCallStatus: selectedCallstatus?.current?.status,
      startDate: inputState.startdate ? inputState.startdate : '',
      endDate: inputState.enddate ? inputState.enddate : '',
    });
  };

  const handleCallStatus = async e => {
    let source, status;
    switch (e.target.value) {
      case 'all':
        status = '';

        break;
      case '0':
        status = '0';
        break;
      case '1':
        status = '1';
        break;
      case '2':
        status = '2';
        break;
      case '3':
        status = '3';
        break;
    }
    selectedCallstatus.current = { status };
    setCookies('skip', 0);
    // setSkip(0)
    handlingCombineFiltering({
      selectedResponderId: selectedResponder?.current,
      selectedCampignId: selectedCampign?.current,
      selectedCallStatus: e.target.value,
      startDate: inputState.startdate ? inputState.startdate : '',
      endDate: inputState.enddate ? inputState.enddate : '',
    });
  };

  const handlingCombineFiltering = ({ selectedCampignId, selectedResponderId, selectedCallStatus }) => {
    let campignId = selectedCampignId ? (selectedCampignId === 'all' ? '' : selectedCampignId) : '';
    let responderId = selectedResponderId ? (selectedResponderId === 'all' ? '' : selectedResponderId) : '';
    let callStatus = selectedCallStatus ? (selectedCallStatus === 'all' ? '' : selectedCallStatus) : '';
    setSkip(0);
    let startDate = inputState.startdate ? inputState.startdate : '';
    let endDate = inputState.enddate ? inputState.enddate : '';

    fetchedAllUsers({ skips: skip, endDate, startDate, campignId, responderId, callStatus });
  };

  return (
    <>
      <div className='gita_quote_heading'>
        <div className='container d-flex align-items-center justify-content-between'>
          <h4 className='hd-list-f left-q'>
            {' '}
            WhatsApp Call History <small>({totalChats})</small>{' '}
          </h4>
        </div>
      </div>
      <div className='chat_window'>
        <Row className='mlist'>
          <div className='chat_search_box'>
            {/*<div className="chat-search-field">
          <span className="chat-bot-search-icon"><i className="fa fa-search"></i></span>
          <input type="text" className="form-control form-input w-50" placeholder="Search anything..." onChange={handleSearch} />
    </div> */}
            <Row>
              <Col md={2}>
                <Form.Label>Campign</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleCampignChange}
                  value={selectedCampign.current}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  {campaignsaa.map((item, index) => {
                    return (
                      <>
                        <option className='text-capitalize' value={item._id}>
                          {item.campaignValue}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Label>Responder</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleResponderChange}
                  value={selectedResponder.current}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  {responderaa.map((item, index) => {
                    return (
                      <>
                        <option className='text-capitalize' value={item._id}>
                          {item.name}
                        </option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Label>Call Status</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleCallStatus}
                  value={
                    selectedCallstatus?.current === 'all'
                      ? selectedCallstatus?.current
                      : selectedCallstatus?.current?.status
                  }
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='0'>
                    Pending
                  </option>
                  <option className='text-capitalize' value='1'>
                    Initiated
                  </option>
                  <option className='text-capitalize' value='2'>
                    Completed
                  </option>
                  <option className='text-capitalize' value='3'>
                    Failed
                  </option>
                </Form.Select>
              </Col>
              <Col md={2}>
                <Form.Label>Start Date</Form.Label>
                {/* </Col>
        <Col md={2}> */}
                {/* <input
            className='form-ch form-control'
            name='startdate'
            type='date'
            onChange={e => handleInputChange(e)}
            value={inputState.startdate}
          /> */}
                <DatePicker
                  placeholderText={'Select a starting date'}
                  className='form-control form-ch fil-date-pick '
                  name='startdate'
                  selected={inputState.startdate ? new Date(inputState.startdate) : null}
                  onChange={date => {
                    handleStartDate(date);
                    // setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>
              <Col md={2}>
                <Form.Label>End Date</Form.Label>
                {/* </Col>
        <Col md={2}> */}
                <DatePicker
                  placeholderText={'Select a ending date'}
                  className='form-control form-ch fil-date-pick '
                  name='enddate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleEndDate(date);
                    // setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col>
              <Col md={2} className='mt-4 mb-1'>
                <Button className='as_btn ' variant='primary' onClick={handleReset}>
                  Reset
                </Button>
              </Col>
            </Row>
          </div>
          <BootstrapTable keyField='index' data={users} columns={columns} />
          {totalChats > 50 && (
            <div className='d-flex justify-content-center align-items-left w-100 p-0 mt-2 custom-pagination'>
              <ReactPaginate
                previousLabel='Previous'
                nextLabel='Next'
                breakLabel='...'
                forcePage={skip / 50}
                pageCount={totalChats < 50 ? 1 : Math.ceil(totalChats / 50)} // Total number of pages
                marginPagesDisplayed={2} // Number of pages to display at the start and end of the pagination
                pageRangeDisplayed={1} // Number of pages to display in the middle of the pagination
                onPageChange={handlePageChange} // Callback function when page is changed
                containerClassName='pagination'
                activeClassName='active'
              />
            </div>
          )}
        </Row>
      </div>
    </>
  );
}
